<template>
  <div class="main-container">
    <h1 class="main-heading">{{"Promo Codes"}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ "Total Limit" }}</div>
          <div class="cell">{{ "Use Count" }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.ACTIVE_UNTIL') }}</div>
          <button @click="$router.push({ name: 'PromoCodesAdd' })" class="success" >{{ $t('ADD') }}</button>
        </div>
        <div class="table-row" v-for="(promoCode, index) in promoCodesArr" v-bind:key="promoCode.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ promoCode.id }}</div>
          <div class="cell">{{ promoCode.total_limit == -1 ? "Limitless" : promoCode.total_limit }}</div>
          <div class="cell">{{ promoCode.use_count }}</div>
          <div class="cell">{{ moment(promoCode.expire_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell action-cell">
            <button @click="$router.push({ name: 'PromoCodesAdd', query: { id: promoCode.id } })" class="info" >{{ $t('EDIT') }}</button>
            <button @click="() => deletePromotion(promoCode.id)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
  name: "PromoCodes",
  setup() {
    const store = useStore();
    const promoCodesArr = ref([])

    watch(() => store.getters['promoCodes/getPromoCodes'], value => {
      promoCodesArr.value = value
    }, { immediate: true })

    const deletePromotion = id => {
      store.dispatch('promoCodes/deletePromoCode', { id })
    }
    return {
      promoCodesArr,
      moment,
      deletePromotion
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}

button, a {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  flex-grow: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.action-cell {
  display: flex;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>