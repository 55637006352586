export default {
    REWARD_TYPES: [
        {
            name: '7_DAY_LOGIN',
            id: 'login'
        },
        {
            name: 'DAILY_LOGIN',
            id: 'daily-login'
        },
        {
            name: 'FIRST_PURCHASE',
            id: 'first-purchase'
        }
    ]
}