import getAxios from './axios'

export default {
    getUser: ({ key, value }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/user/get', { key, value }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_USER'))
            })
        })
    },
    updateUser: (doc_id, update_body) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/user/edit',{ doc_id, update_body }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_UPDATE_USER'))
            })
        })
    }
}