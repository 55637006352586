export default {
    MAIL_TYPES: [
        {
            text: 'PLAIN',
            value: 0
        },
        {
            text: 'REWARDED',
            value: 1
        }
    ],
    REWARD_TYPES: [
        {
            text: 'GOLD',
            value: 0
        },
        {
            text: 'GEM',
            value: 1
        },
        {
            text: 'CHEST',
            value: 2
        },
        {
            text: 'UNIT',
            value: 3
        },
        {
            text: 'HERO',
            value: 4
        },
        {
            text: 'EMOJI',
            value: 6
        }
    ],
    CARD_REWARD_TYPES: [
        {
            text: 'RANDOM',
            value: 0
        },
        {
            text: 'SPECIFIC',
            value: 1
        }
    ],
    CARD_RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    CONDITION_TYPES: [
        {
            text: "ALL",
            value: 0
        },
        {
            text: "USER_ID_BASED",
            value: 1
        },
        {
            text: "LAST_LOGIN_BASED",
            value: 2
        },
        {
            text: "TROPHY_BASED",
            value: 3
        },
        {
            text: "CREATE_DATE_BASED",
            value: 4
        }
    ],
    CONDITION_OPERATORS: [
        [],
        [],
        [
            {
                text: 'GREATER_OR_EQUAL_TO_DAYS',
                value: ">="
            },
            {
                text: "LESS_THAN_DAYS",
                value: "<"
            }
        ],
        [
            {
                text: 'GREATER_OR_EQUAL_TO',
                value: ">="
            },
            {
                text: "LESS_THAN",
                value: "<"
            }
        ],
        [
            {
                text: 'GREATER_OR_EQUAL_TO_DAYS',
                value: ">="
            },
            {
                text: "LESS_THAN_DAYS",
                value: "<"
            }
        ]
    ]
}