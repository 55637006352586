<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('NEWS')}}</h1>
    <div class="content-container">
      <div class="col">
        <div class="form-container">
            <p>{{ "Language" }}</p>
            <select v-model="selectedLanguage" @change="languageChanged($event)">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
        <div class="form-container">
          <p>{{ $t(`NEWS_VALUES.TITLE`) }}</p>
          <input v-model="currentNews.title"/>
        </div>
        <div class="form-container">
          <p>{{ "Localized Title" }}</p>
          <input v-model="currentNews.localizables.title[selectedLanguage]"/>
        </div>
        <div class="form-container">
          <p>{{ $t(`NEWS_VALUES.CREATION_DATE`) }}</p>
          <DatePicker
              v-model="currentNews.creation_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="filter-sort">
          <input type="checkbox" id="checkbox" v-model="expiresInOneMonth">
          <label for="checkbox">{{ "Expire after one month" }}</label>
        </div>
        <div v-if="!expiresInOneMonth" class="form-container">
          <p>{{ $t(`NEWS_VALUES.EXPIRE_DATE`) }}</p>
          <DatePicker
              v-model="currentNews.expiration_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="expiration_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container">
          <p>{{ $t(`NEWS_VALUES.NEWS_TYPE`) }}</p>
          <select v-model.number="currentNews.type">
            <option v-for="option in newsConstants.NEWS_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('NEWS_VALUES.' + option.text) }}</option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`NEWS_VALUES.BANNER_ASSET`) }}</p>
          <select v-model="currentNews.banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div id="#content-editor">

        </div>
      </div>
      <div class="col">
        <h2>{{ $t(`NEWS_VALUES.ACTION_BUTTONS`) }}</h2>
        <div class="action-buttons-table" v-if="currentNews.action_buttons.length > 0">
          <div class="row header-row">
            <p>{{ $t(`NEWS_VALUES.TEXT`) }}</p>
            <p>{{ $t(`NEWS_VALUES.LINK`) }}</p>
            <p>{{ $t('NEWS_VALUES.ACTION_BUTTON_TYPE') }}</p>
            <p>{{ $t('ACTIONS') }}</p>
          </div>
          <div v-for="(actionButton, index) in currentNews.action_buttons" v-bind:key="index" class="row">
              <input v-model="currentNews.action_buttons[index].text"/>
              <input v-model="currentNews.action_buttons[index].link"/>
              <select v-model.number="currentNews.action_buttons[index].type">
                <option v-for="option in newsConstants.ACTION_BUTTON_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('NEWS_VALUES.' + option.text) }}</option>
              </select>
            <button @click="currentNews.action_buttons.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
        <button @click="addActionButton" class="success">{{ $t('NEWS_VALUES.ADD_ACTION_BUTTON') }}</button>
      </div>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
      <template v-if="$route.query.id">
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </template>

    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Quill from "quill";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';

import '@/lib/quillEmoji/customBlot';
import newsSchema from "@/schemas/news";

import newsConstants from "@/constants/newsConstants";
import 'v-calendar/dist/style.css';

import validator from '@/util/validator'
import { newsValidationSchema } from "@/schemas/validator/validatorSchemas";
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "NewsAdd",
  setup() {
    const store = useStore();
    const news = computed(() => store.getters['news/getNews'])
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })
    const currentNews = reactive({...newsSchema()})
    const route = useRoute();
    const router = useRouter();

    const previousLanguage = ref('en')
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    const expiresInOneMonth = ref(false);

    let quill;
    onMounted(() => {
      if (route.query.id && news.value.length > 0) {
        const editingNews = JSON.parse(JSON.stringify(news.value.find(x => x.id === route.query.id)))
        Object.keys(editingNews).forEach(key => {
          currentNews[key] = editingNews[key]
        })
      }
      if (!currentNews.localizables) {
        currentNews.localizables = { title: {}, content: {}, html_content: {} }
      }
      var options = {
        placeholder: 'Compose a news...',
        theme: 'snow',
        modules: {
          toolbar: [
            [{'color': [] }],
            ['bold',
            'italic',
            'underline',
            'strike',
            ],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'header': [1, 2, false] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
          ],
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true
        }
      };
      quill = new Quill(document.getElementById('#content-editor'), options)
      quill.setContents(currentNews.content)
    })

    watch(() => store.getters['news/getNews'], news => {
      if (route.query.id) {
        const editingNews = JSON.parse(JSON.stringify(news.find(x => x.id === route.query.id)))
        Object.keys(editingNews).forEach(key => {
          currentNews[key] = editingNews[key]
        })
        quill.setContents(currentNews.content)
      }
    })

    const addActionButton = () => {
      currentNews.action_buttons.push({})
    }

    const languageChanged = (event) => {
      currentNews.localizables.content[previousLanguage.value] = quill.getContents();
      currentNews.localizables.html_content[previousLanguage.value] = quill.root.innerHTML;

      previousLanguage.value = event.target.value;

      quill.setContents(currentNews.localizables.content[selectedLanguage.value])
    }

    const checkFields = (data) => {
      const result = validator.validate(data, newsValidationSchema)
      return result.success;
    }

    const submit = () => {
      currentNews.localizables.content[selectedLanguage.value] = JSON.parse(JSON.stringify(quill.getContents()))
      currentNews.localizables.html_content[selectedLanguage.value] = quill.root.innerHTML;
      currentNews.content = currentNews.localizables.content['en']
      currentNews.html_content = currentNews.localizables.html_content['en']

      if(!currentNews.banner_asset) delete currentNews.banner_asset;
      if (expiresInOneMonth.value) {
        currentNews.expiration_date = currentNews.creation_date + 28 * 24 * 60 * 60 * 1000
      }
      console.log(currentNews);
      
      const isValid = checkFields(currentNews);
      if (!isValid)
        return;

      if (route.query.id) {
        store.dispatch('news/updateNews', {id: route.query.id, data: currentNews})
      } else {
        store.dispatch('news/addNews', {data: currentNews}).then(res => {
          if(res) {
            router.push({ name: 'NewsAdd', query: { id: res.id }})
          }
        })
      }
    }

    const exportTo = environment => {
      store.dispatch('news/exportNews', { id: route.query.id, data: currentNews, environment })
    }


    const env = process.env.ENV;

    return {
      currentNews,
      submit,
      exportTo,
      env,
      newsConstants,
      addActionButton,
      assetList,
      expiresInOneMonth,
      selectedLanguage,
      languages,
      languageChanged
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}

.filter-sort {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/*#emoji-palette >>> .ap, .ql-emojiblot >>> .ap {*/
/*  background-image: none;*/
/*  text-indent: 0;*/
/*  !* It's also possible to adjust spacing. To make selecting emojis easier *!*/
/*  !* width: 25px; *!*/
/*  !* height: 25px; *!*/
/*}*/
</style>