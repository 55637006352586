<template>
  <div class="left-panel" :class="!isLoggedIn ? 'do-not-display' : ''">
    <a v-for="(item, index) in items" v-bind:key="index"
       class="left-panel-item" :class="activeTab === leftPanelConstants[item][0] ? 'active' : ''"
       @click="$router.push({ name: leftPanelConstants[item][1] })">{{$t(leftPanelConstants[item][2])}}
    </a>
  </div>
</template>

<script>
import { computed, watch,  ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { checkUserPermissionForPage } from "@/util/permissionHelper";
import { leftPanelConstants } from "@/constants/leftPanelConstants";

export default {
  name: "LeftPanel",
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters['auth/getIsLoggedIn'])
    const route = useRoute()
    const claims = computed(() => store.getters['auth/getPermissionClaims']);
    const activeTab = computed(() => route.path.split('/')[1])
    console.log(claims.value)
    const items = ref([]);
    watch(() => store.getters['auth/getPermissionClaims'], (claims) => {
      items.value = Object.keys(leftPanelConstants).filter(x => checkUserPermissionForPage(leftPanelConstants[x][0], claims)).sort();
    })


    return {
      isLoggedIn,
      activeTab,
      claims,
      checkUserPermissionForPage,
      items,
      leftPanelConstants
    }
  }
}
</script>

<style scoped>
.left-panel {
  background-color: #f8f9fa!important;
  position: fixed;
  z-index: 1019;
  left: 0;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 46px;
  width: 15vw;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  overflow-y: scroll;
}

.left-panel-item {
  width: 100%;
  cursor: pointer;
  padding: 7.5px 20px;
  box-sizing: border-box;
  text-align: start;
}

.left-panel.do-not-display {
  display: none;
}

.left-panel-item.active {
  background-color: lightgray;
}

</style>
