import { createWebHistory, createRouter } from "vue-router";

import store from "../store"

const Login = () => import("@/pages/LoginPage")
const ServerConfig = () => import("@/pages/config")
const Spawner = () => import("@/pages/spawners")
const Units = () => import("@/pages/units")
const UnitAdd = () => import("@/pages/units/Unit")
const UnitsNext = () => import("@/pages/unitsNext")
const UnitNextAdd = () => import("@/pages/unitsNext/Unit")
const Heroes = () => import("@/pages/heroes")
const HeroAdd = () => import("@/pages/heroes/Hero")
const Bosses = () => import("@/pages/bosses")
const BossAdd = () => import("@/pages/bosses/Boss")
const User = () => import("@/pages/users")
const News = () => import("@/pages/news")
const NewsAdd = () => import("@/pages/news/News")
const Mails = () => import("@/pages/mails")
const MailAdd = () => import("@/pages/mails/Mail")
const Shop = () => import("@/pages/shop")
const Promotions = () => import("@/pages/promotions")
const SpecialOffers = () => import("@/pages/specialOffers")
const PromotionAdd = () => import("@/pages/promotions/Promotion")
const SpecialOfferAdd = () => import("@/pages/specialOffers/SpecialOffer")
const Logs = () => import("@/pages/logs")
const GameLogs = () => import("@/pages/gameLogs")
const Purchases = () => import("@/pages/purchases")
const Monitor = () => import("@/pages/monitor/Monitor")
const BotDecks = () => import("@/pages/botDecks")
const BotDeckAdd = () => import("@/pages/botDecks/BotDeck")
const MirrorDecks = () => import("@/pages/mirrorDecks")
const MirrorDeckAdd = () => import("@/pages/mirrorDecks/MirrorDeck")
const GameModeDecks = () => import("@/pages/gameModeDecks")
const GameModeDecksAdd = () => import("@/pages/gameModeDecks/GameModeDeck")
const Wiki = () => import("@/pages/wiki")
const WikiAdd = () => import("@/pages/wiki/Wiki")
const Rewards = () => import("@/pages/rewards")
const SevenDaysLoginReward = () => import("@/pages/rewards/SevenDaysLogin")
const DailyLoginReward = () => import("@/pages/rewards/DailyLogin")
const FirstPurchaseReward = () => import("@/pages/rewards/FirstPurchase")
const TipsTricks = () => import("@/pages/tips")
const Assets = () => import("@/pages/assets")
const Seasons = () => import("@/pages/seasons")
const SeasonUpdate = () => import("@/pages/seasons/Season")
const SeasonContent = () => import("@/pages/seasons/SeasonContent")
const TrophyLeaderboard = () => import("@/pages/leaderboard/TrophyLeaderboard")
const MockUsers = () => import("@/pages/mockUsers")
const MockUsersAdd = () => import("@/pages/mockUsers/MockUser")
const TrophyRoad = () => import("@/pages/trophy/Trophy")
const Events = () => import("@/pages/events")
const EventsAdd = () => import("@/pages/events/Event")
const PanelAdmin = () => import("@/pages/panelAdmin")
const PromoCodes = () => import("@/pages/promoCodes")
const PromoCodesAdd = () => import("@/pages/promoCodes/PromoCode")
const Localization = () => import("@/pages/localization")

const routes = [
    {
      path: "/",
      redirect: { name: 'Login' }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/localization",
        name: "Localization",
        component: Localization,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/config",
        name: "ServerConfig",
        component: ServerConfig,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units",
        name: "Units",
        component: Units,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units/add",
        name: "UnitAdd",
        component: UnitAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units-next",
        name: "UnitsNext",
        component: UnitsNext,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/units-next/add",
        name: "UnitNextAdd",
        component: UnitNextAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/heroes",
        name: "Heroes",
        component: Heroes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/heroes/add",
        name: "HeroAdd",
        component: HeroAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bosses",
        name: "Bosses",
        component: Bosses,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bosses/add",
        name: "BossAdd",
        component: BossAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/users",
        name: "User",
        component: User,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/spawners",
        name: "Spawner",
        component: Spawner,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/news",
        name: "News",
        component: News,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/news/add",
        name: "NewsAdd",
        component: NewsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mails",
        name: "Mails",
        component: Mails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mails/add",
        name: "MailAdd",
        component: MailAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/shop",
        name: "Shop",
        component: Shop,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promo-codes",
        name: "PromoCodes",
        component: PromoCodes,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promo-codes/add",
        name: "PromoCodesAdd",
        component: PromoCodesAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promotions",
        name: "Promotions",
        component: Promotions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/promotions/add",
        name: "PromotionAdd",
        component: PromotionAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/special-offers",
        name: "SpecialOffers",
        component: SpecialOffers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/special-offers/add",
        name: "SpecialOfferAdd",
        component: SpecialOfferAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/logs",
        name: "Logs",
        component: Logs,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-logs",
        name: "GameLogs",
        component: GameLogs,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/purchases",
        name: "Purchases",
        component: Purchases,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/monitor",
        name: "Monitor",
        component: Monitor,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bot-decks",
        name: "BotDecks",
        component: BotDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/bot-decks/add",
        name: "BotDeckAdd",
        component: BotDeckAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mirror-decks",
        name: "MirrorDecks",
        component: MirrorDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mirror-decks/add",
        name: "MirrorDeckAdd",
        component: MirrorDeckAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-mode-decks",
        name: "GameModeDecks",
        component: GameModeDecks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/game-mode-decks/add",
        name: "GameModeDeckAdd",
        component: GameModeDecksAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/wiki",
        name: "Wiki",
        component: Wiki,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/wiki/add",
        name: "WikiAdd",
        component: WikiAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards",
        name: "Rewards",
        component: Rewards,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/login",
        name: "SevenDaysLoginReward",
        component: SevenDaysLoginReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/daily-login",
        name: "DailyLoginReward",
        component: DailyLoginReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/rewards/first-purchase",
        name: "FirstPurchaseReward",
        component: FirstPurchaseReward,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/tips-tricks",
        name: "TipsTricks",
        component: TipsTricks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/assets",
        name: "Assets",
        component: Assets,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/seasons",
        name: "Seasons",
        component: Seasons,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/seasons/update",
        name: "SeasonUpdate",
        component: SeasonUpdate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/season-content",
        name: "SeasonContent",
        component: SeasonContent,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/leaderboard-trophy",
        name: "TrophyLeaderboard",
        component: TrophyLeaderboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mock-users",
        name: "MockUsers",
        component: MockUsers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/mock-users/add",
        name: "MockUsersAdd",
        component: MockUsersAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/trophy-road",
        name: "TrophyRoad",
        component: TrophyRoad,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events",
        name: "Events",
        component: Events,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/events/add",
        name: "EventsAdd",
        component: EventsAdd,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/panel-admin",
        name: "PanelAdmin",
        component: PanelAdmin,
        meta: {
            requiresAuth: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

let loadTimer = null;

router.beforeEach((to, from, next) => {

    if(store.getters['auth/getLoginLoading']) {
        loadTimer = setInterval(() => {
            console.log('Timer tick');
            const loginLoading = store.getters['auth/getLoginLoading'];
            console.log('Login loading: ', loginLoading);
            if(!loginLoading) {
                clearInterval(loadTimer);
                loadTimer = null;
                if(to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/getIsLoggedIn']) {
                    next({ name: 'Login' })
                } else {
                    next()
                }
            }
        }, 500)
    } else {
        if(to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/getIsLoggedIn']) {
            next({ name: 'Login' })
        } else {
            next()
        }
    }

})

export default router;