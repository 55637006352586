<template>
<div class="main-container">
    <button @click="openAwsDashboard" class="info">{{ "Aws Dashboard" }}</button>
    <button @click="openDatadogDashboard" class="info">{{ "Datadog Dashboard" }}</button>
    <div class="dashboard">
      <div></div>
      <iframe src="https://app.datadoghq.com/graph/embed?token=a84a2f10d61ec967f13a23bc0c3ef78df6e3b98b0067ffc4c05e55dc9694e3ca&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <div></div>
    </div>
    <div class="dashboard">
      <iframe src="https://app.datadoghq.com/graph/embed?token=cf95bfdceaab20e4704d19d964d281f5c7c8362a90fe85f83b483dbca575de71&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <iframe src="https://app.datadoghq.com/graph/embed?token=26d7b7ecb1168a48adf8f08277466981df64c1df58b9f962186abb87532ca620&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <iframe src="https://app.datadoghq.com/graph/embed?token=21ad4e7ffff45751a357b02de967998e58aed1e3132054ef838190510820e716&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <iframe src="https://app.datadoghq.com/graph/embed?token=3010bf1621e2781ad2e498bac389c4edca5e88e53002df4a7cbe094bd5ff2701&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <iframe src="https://app.datadoghq.com/graph/embed?token=05cc439025ff8948b7f7af8cce20f8e58dbeea457e412724599d335ed4c48cec&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
      <iframe src="https://app.datadoghq.com/graph/embed?token=abfbc4806eb5eb812001cd4770bb39a9f46a0f88efac43e565f0ab7dd6a02e31&height=200&width=400&legend=true" width="400" height="250" frameborder="0"></iframe>
    </div>
  </div>
  
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";

export default {
  name: "Monitor",
  setup() {

    const cols = [
      {
        field: "user_id",
        text: 'USER_ID'
      },
      {
        field: "region",
        text: 'REGION'
      },
      {
        field: "trophy",
        text: "TROPHY",
        type: 'number'
      }
    ]

    const gameliftLink = "https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#dashboards:name=Gamelift;expand=true;start=PT12H";
    const datadogLing = "https://app.datadoghq.com/dashboard/ses-xzs-mya/gamelift?from_ts=1659369410102&to_ts=1659373010102&live=true";

    const isCoopTab = ref(false);
    const setIsCoop = isCoop => {
      isCoopTab.value = isCoop
    }

    const sortBy = reactive({
      field: 'region',
      isDesc: true
    })

    const changeSortField = field => {
      if(field === sortBy.field) {
        sortBy.isDesc = !sortBy.isDesc
      } else {
        sortBy.field = field
        sortBy.isDesc = true
      }
    }

    const filters = reactive({
      trophy: {
        max: 400
      }
    });

    const getFilters = () => {
      const filterFuncs = [];
      console.log('Here')
      Object.keys(filters).forEach(key => {
        const col = cols.find(x => x.field === key);
        if(col.type === 'number') {
          if(filters[key].min) {
            filterFuncs.push(data => data[key] >= filters[key].min)
          }
          if(filters[key].max) {
            filterFuncs.push(data => data[key] <= filters[key].max)
          }
        } else {
          filterFuncs.push(data => data[key].includes(filters[key]))
        }
      });
      console.log(filterFuncs)
      return row => {
        let isValid = true;
        filterFuncs.forEach(func => {
          if(!func(row)) {
            isValid = false;
          }
        })
        console.log(isValid)
        return isValid;
      }

    }

    const openDatadogDashboard = () => {
      window.open(datadogLing, '_blank').focus();
    }

    const openAwsDashboard = () => {
      window.open(gameliftLink, '_blank').focus();
    }


    const initFilters = () => {
      cols.forEach(col => {
        if(col.type === 'number') {
          filters[col.field] = {
            min: undefined,
            max: undefined
          }
        }
      })
    }

    onMounted(() => {
      initFilters()
    })

    const pvpData = ref([]);
    const coopData = ref([]);

    const coopRows = computed(() => [ ...coopData.value.filter(getFilters())].sort((a, b) => {
      if ( a[sortBy.field] < b[sortBy.field] ){
        return sortBy.isDesc ? 1 : -1;
      }
      if (  a[sortBy.field] > b[sortBy.field] ){
        return sortBy.isDesc ? -1 : 1;
      }
      return 0;
    }))

    const pvpRows = computed(() => [ ...pvpData.value.filter(getFilters())].sort((a, b) => {
      if ( a[sortBy.field] < b[sortBy.field] ){
        return sortBy.isDesc ? 1 : -1;
      }
      if (  a[sortBy.field] > b[sortBy.field] ){
        return sortBy.isDesc ? -1 : 1;
      }
      return 0;
    }))

    let loc = window.location, newUri;
    if (loc.protocol === "https:") {
      newUri = "wss:";
    } else {
      newUri = "ws:";
    }
    newUri += "//" + loc.host;
    newUri += "/realtime/matchmaking";
    console.log(newUri);
    const webSocket = new WebSocket(newUri);
    webSocket.onmessage = event => {
      console.log(event.data)
      pvpData.value = [3,4,5]
      const data = JSON.parse(event.data);
      pvpData.value = Object.values(data.pvp)
      coopData.value = Object.values(data.coop)
    }

    return {
      coopRows,
      pvpRows,
      changeSortField,
      pvpData,
      coopData,
      isCoopTab,
      setIsCoop,
      cols,
      filters,
      openAwsDashboard,
      openDatadogDashboard
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-navigation {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 0 20px;
  box-sizing: border-box;
}

.tab-button {
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px 15px;
  font-weight: bold;
  margin: 0 10px;
}

.tab-button.active {
  background-color: palegreen;
}
.tab-content {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}

.table {
  width: 100%;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.cell {
  border: 1px solid black;
}

.cell.cell-search {
  background-color: lightgrey;
}

.cell.cell-header {
  border: 2px solid black;
  font-weight: bold;
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.dashboard {
  padding: 10px;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.dashboard-card {
  border-radius: 0 20px;
  border: 3px solid black;
  padding: 10px 20px;
  margin: 15px 0;
  background-color: rgba(0, 0, 255, 0.03);
}

.dashboard-card .card-title {
  font-size: 0.9rem;
  font-weight: 500;
}

.dashboard-card .card-value {
  font-size: 1.2rem;
  font-weight: 900;
}
</style>