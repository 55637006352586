const { default: languagesTemp } = require("../constants/languagesTemp");
const csv = require('csvtojson');


const buildExportCsv = (fileName, localizables, objects) => {
    console.log(localizables)
    console.log(objects);
    const localizableFields = Object.keys(localizables);
    const languages = languagesTemp.languages;


    let result = "object ID,field,default value";

    languages.forEach(language => {
        result += `,${language.code}`
    });

    objects.forEach(element => {
        localizableFields.forEach(field => {
            result += `\n${element.id},${field},"${element[field]}"`
            languages.forEach(language => {
                if (element.localizables && element.localizables[field] && element.localizables[field][language.code])
                    result += `,"${element.localizables[field][language.code]}"`
                else
                    result += ","
            })
        })
    });

    console.log(result);

    downloadBlob(result, fileName + '.csv', 'text/csv;charset=utf-8;')
}


async function csvToJson(csvs) {
    const lines = csvs.split('\n');
    
    let headers = lines.splice(0, 1)[0].split(",");

    const result = await csv({
        output: "json",
        noheader: false
      }).fromString(csvs)
        .preFileLine((fileLine, idx) => {
          //Convert csv header row to lowercase before parse csv file to json
          if (idx === 0) { return fileLine.toLowerCase() }
          return fileLine;
        })
        .then((result) => {
          return result;
        });

    return {
        headers: headers,
        result: result
    }
}

function downloadBlob(content, filename, contentType) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }

module.exports = {
    buildExportCsv,
    csvToJson
}