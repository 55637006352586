import serverConfigService from "@/services/serverConfig"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    serverConfig: null
})

// getters
const getters = {
    getServerConfig: ({ serverConfig }) => {
        return serverConfig
    }
}

// actions
const actions = {
    updateServerConfig({ commit }, serverConfig) {
        serverConfigService.setServerConfig(serverConfig).then(() => {
            commit('setServerConfig', serverConfig)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_CONFIG'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_CONFIG'),
                type: 'error'
            })
        })
    },
    exportServerConfig({ commit }, { serverConfig, environment }) {
        serverConfigService.exportServerConfig(serverConfig, environment).then(() => {
            commit('setServerConfig', serverConfig)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_CONFIG'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_CONFIG'),
                type: 'error'
            })
        })
    },
    getServerConfig({ commit }) {
        serverConfigService.getServerConfig().then(serverConfig => {
            commit('setServerConfig', serverConfig)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_CONFIG'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setServerConfig(state, serverConfig) {
        state.serverConfig = serverConfig
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}