<template>
  <fieldset style="flex-grow: 1;">
    <legend>{{$t('LOGIN_HEADING')}}</legend>
    <h3 v-if="loginError" style="color: red;">{{ $t(`ERRORS.${loginError}`) }}</h3>
    <input type="text" :placeholder="$t('USERNAME_FIELD')" v-model="credentials.username">
    <input type="password" :placeholder="$t('PASSWORD_FIELD')" v-model="credentials.password">
    <button @click="login">{{$t('LOGIN_ACTION_BUTTON')}}</button>
    <a class="password-button" @click="togglePasswordModal">{{$t('FORGOT_PASSWORD')}}</a>
    <div class="password-modal" :class="forgotPasswordModalOpen ? 'active' : ''">
      <div class="password-modal-overlay" @click="togglePasswordModal" />
      <div class="password-modal-container">
        <fieldset class="popup-fieldset">
          <legend>{{ $t('FORGOT_PASSWORD') }}</legend>
          <div class="form-item">
            <span>{{ $t('EMAIL') + ':'}}</span>
            <input type="text" v-model="credentials.forgot_password" />
          </div>
          <button @click="sendPasswordResetMail">{{$t('SEND_PASSWORD_RESET_MAIL')}}</button>
        </fieldset>

      </div>
    </div>
  </fieldset>
</template>

<script>
import { reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: 'LoginPage',
  setup() {
    const store = useStore();
    const credentials = reactive({
      username: '',
      password: '',
      forgot_password: ''
    })

    const forgotPasswordModalOpen = ref(false);
    const isLoggedIn = computed(() => store.getters['auth/getIsLoggedIn'])
    const email = computed(() => store.getters['auth/getEmail'])
    const loginError = computed(() => store.getters['auth/getLoginError'])
    const router = useRouter()

    watch(() => store.getters['auth/getIsLoggedIn'], val => {
      if(val) {
        router.push({ name: 'Units' })
      }
    }, { immediate: true })

    const togglePasswordModal = () => forgotPasswordModalOpen.value = !forgotPasswordModalOpen.value;

    const sendPasswordResetMail = () => {
      store.dispatch('auth/sendPasswordResetEmail', credentials.forgot_password);
    }

    return {
      credentials,
      isLoggedIn,
      email,
      loginError,
      login: () => store.dispatch('auth/login', credentials),
      sendPasswordResetMail,
      togglePasswordModal,
      forgotPasswordModalOpen
    }
  }
}
</script>

<style scoped>
input, button {
  display: block;
  margin: 20px auto;
}

fieldset {
  margin: 20px;
  margin-right: calc(15vw + 20px);
}

legend {
  font-weight: bold;
}

fieldset.popup-fieldset {
  margin: 0;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
}

.password-modal {
  height: 100vh;
  width: 100vw;
  display: none;
  align-items: center;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 999999;
}

.password-modal.active {
  display: flex;
}

.form-item {
  display: flex;
  align-items: center;
}

.form-item span {
  width: 23%;
  text-align: start;
  font-weight: bold;
  margin-right: 2%;
}

.form-item input {
  width: 75%;
}

.password-modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
}

.password-modal-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 390px;
  background-color: white;
  border-radius: 25px;
  z-index: 1;
}

.password-button {
  cursor: pointer;
  text-decoration: underline;
  color: #0066cc;
}
</style>