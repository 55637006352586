<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('BOT_DECKS')}}</h1>

    <div class="flex">
      <input type="text" v-model="search" placeholder="Search Bot Decks..." class="flex-items" />

      <select v-model = "deckType" class="flex-items2">
        <option value=1>PVP</option>
        <option value=0>COOP</option>
        <option value="none">Select Deck Type</option>
      </select>

      <select v-model = "deckStatus" class="flex-items2">
        <option value=1>ENABLED</option>
        <option value=0>DISABLED</option>
        <option value="none">Select Deck Status</option>
      </select>

      <div>
        <label>Min Trophy</label>
        <input type="number" v-model="minTrophy" placeholder="Min Trophy" />
      </div>

      <div>
        <label>Max Trophy</label>
        <input type="number" v-model="maxTrophy" placeholder="Max Trophy" />
      </div>

      <div>
        <multiselect v-model="unitValue" :options="unitOptions" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Units" label="name" track-by="name" :preselect-first="false">
          <template v-slot:selection="{ values, isOpen }" ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} units selected</span></template>
        </multiselect>

      </div>

      <div>
        <multiselect v-model="heroValue" :options="heroOptions" :multiple="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Hero" label="name" track-by="name" :preselect-first="false">
          <template v-slot:selection="{ values, isOpen }" ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} hero selected</span></template>
        </multiselect>

      </div>

      <button @click="() => searchBotDeck(search.toLowerCase(), deckType, deckStatus, minTrophy, maxTrophy, unitValue, heroValue)">Search</button>
      <button @click="() => resetFilters(deckType, deckStatus)">Reset Filters</button>

    </div>



    <div>
      <h4>Filtered Bot Decks: {{botDecksArrDisplay.length}}</h4>
    </div>

    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell clickable" @click="changeSortField('name', 0)">{{ $t('NAME') }}</div>
          <div class="cell clickable" @click="changeSortField('status', 1)">{{ $t('BOT_DECK_VALUES.STATUS') }}</div>
          <div class="cell clickable" @click="changeSortField('min_trophy', 1)">{{ $t('BOT_DECK_VALUES.MIN_TROPHY') }}</div>
          <div class="cell clickable" @click="changeSortField('max_trophy', 1)">{{ $t('BOT_DECK_VALUES.MAX_TROPHY') }}</div>
          <div class="cell clickable" @click="changeSortField('is_pvp', 1)">{{ $t('BOT_DECK_VALUES.DECK_TYPE') }}</div>
          <div class="cell">{{ 'Hero' }}</div>
          <div class="cell">{{ 'Unit 1' }}</div>
          <div class="cell">{{ 'Unit 2' }}</div>
          <div class="cell">{{ 'Unit 3' }}</div>
          <div class="cell">{{ 'Unit 4' }}</div>
          <div class="cell">{{ 'Unit 5' }}</div>

          <div class="cell"><a href="/bot-decks/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(botDeck, index) in botDecksArrDisplay" v-bind:key="botDeck.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ botDeck.name }}</div>
          <div class="cell">{{ botDeckConstants.STATUS.find(x => x.value === botDeck.status) ? botDeckConstants.STATUS.find(x => x.value === botDeck.status).text : 'UNINITIALIZED' }}</div>
          <div class="cell">{{ botDeck.min_trophy }}</div>
          <div class="cell">{{ botDeck.max_trophy }}</div>
          <div class="cell">{{ botDeck.is_pvp ? 'PVP' : 'COOP' }}</div>
          <div class="cell">{{ getHeroText(botDeck.hero) }}</div>
          <div class="cell">{{ getUnitText(botDeck.units[0]) }}</div>
          <div class="cell">{{ getUnitText(botDeck.units[1]) }}</div>
          <div class="cell">{{ getUnitText(botDeck.units[2]) }}</div>
          <div class="cell">{{ getUnitText(botDeck.units[3]) }}</div>
          <div class="cell">{{ getUnitText(botDeck.units[4]) }}</div>

          <div class="cell">
            <button @click="() => copyBotDeck(botDeck)" class="info"><i class="fas fa-copy"></i></button>
            <a :href="'/bot-decks/add?id=' + botDeck.id" class="info"><i class="fas fa-edit"></i></a>
            <button @click="() => deleteBotDeck(botDeck.id, botDeck.index)" class="error"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import Multiselect from 'vue-multiselect'

import botDeckConstants from "@/constants/botDeckConstants";

export default {
  name: "BotDecks",
  components: {
    Multiselect
  },
  setup: function () {
    const store = useStore();
    const units = computed(() => store.getters['units/getUnits']);
    const heroes = computed(() => store.getters['heroes/getHeroes']);

    const botDecksArr =  ([])
    const sortField = ref('name');
    watch(() => store.getters['botDecks/getBotDecks'], value => {
      botDecksArr.value = [...value].sort((a, b) => ('' + a[sortField.value]).localeCompare(b[sortField.value]))
    }, {immediate: true})

    const deleteBotDeck = (id, index) => {
      // console.log(id, index)
      store.dispatch('botDecks/deleteBotDeck', {id, index})
    }

    const numberSort = (a, b) => a[sortField.value] - b[sortField.value]
    const stringSort = (a, b) => ('' + a[sortField.value]).localeCompare(b[sortField.value])

    const changeSortField = (field, type) => {
      sortField.value = field;
      botDecksArrDisplay.value = botDecksArrDisplay.value.sort(type === 0 ? stringSort : numberSort)
    }

    const copyBotDeck = botDeck => {
      store.dispatch('botDecks/addBotDeck', {data: {...botDeck, name: botDeck.name + '_copy', id: undefined}})
    }

    const getHeroText = hero => {
      const selectedHero = heroes.value.find(x => x.id === hero.id);
      return selectedHero.name + '/' + botDeckConstants.RARITY_MAP[selectedHero.rarity] + '/+' + hero.level;
    }

    const getUnitText = unit => {
      const selectedUnit = units.value.find(x => x.id === unit.id);
      if (!selectedUnit) console.log(unit)
      return selectedUnit.name + '/' + botDeckConstants.RARITY_MAP[selectedUnit.rarity] + '/+' + unit.level;
    }

    const search = ref('');
    const minTrophy = ref(0);
    const maxTrophy = ref(0);
    const deckStatus = ref('none');
    const deckType = ref('none');
    const unitValue = ref([]);
    const heroValue = ref([]);
    let botDecksArrDisplay = ref(JSON.parse(JSON.stringify(botDecksArr.value)));

    const searchBotDeck = (search, deckType, deckStatus, minTrophy, maxTrophy, unitValue, heroValue) => {

      let result = JSON.parse(JSON.stringify(botDecksArr.value));
      result = result.filter(botDeck => {return botDeck.name.toLowerCase().includes(search)});
      result = result.filter(botDeck => {
        if (deckType === "none") {
          return true;
        } else if (deckType === "1" && botDeck.is_pvp === true) {
          return true;
        } else if (deckType === "0" && botDeck.is_pvp === false) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter(botDeck => {
        if (deckStatus === "none") {
          return true;
        } else if (deckStatus === "1" && botDeck.status === 1) {
          return true;
        } else if (deckStatus === "0" && botDeck.status === 0) {
          return true;
        } else {
          return false;
        }
      });
      result = result.filter(botDeck => {return botDeck.min_trophy >= minTrophy});
      result = result.filter(botDeck => {return maxTrophy === 0 ? true :
                                              botDeck.max_trophy === 0 ? false :
                                              botDeck.max_trophy <= maxTrophy});


      result = result.filter(botDeck => {
        if (unitValue.length === 0) {
          return true;
        } else {
            let units = []
            for (let i = 0; i < 5; i++) {
              units.push(botDeck.units[i].id)
            }
            for (let i = 0; i < unitValue.length; i++) {
              if (!units.includes(unitValue[i].id)) {
                return false;
              }
            }
            return true;
        }

      });

      result = result.filter(botDeck => {
        if (!heroValue){
          return true;
        }
        if ('id' in heroValue) {
          if (heroValue.id === botDeck.hero.id) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      });





      botDecksArrDisplay.value = result;
    }
    const resetFilters = () => {
      botDecksArrDisplay.value = JSON.parse(JSON.stringify(botDecksArr.value));
      search.value = '';
      minTrophy.value = 0;
      maxTrophy.value = 0;
      deckType.value = 'none';
      deckStatus.value = 'none';
      unitValue.value = [];
      heroValue.value = [];

    }

    return {
      botDecksArr,
      deleteBotDeck,
      botDeckConstants,
      changeSortField,
      copyBotDeck,
      getUnitText,
      getHeroText,
      searchBotDeck,
      resetFilters,
      search,
      minTrophy,
      maxTrophy,
      deckType,
      deckStatus,
      unitValue,
      heroValue,
      botDecksArrDisplay,
      unitOptions: units.value.map(unit => {return {id: unit.id, name: unit.name}}),
      heroOptions: heroes.value.map(hero => {return {id: hero.id, name: hero.name}})
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.6fr 0.6fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}
.flex {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-around;
  align-items: center;
}
.flex-items {
  width: 150px;
  height:30px;
  margin: 5px;
  text-align: center;
}
.flex-items2 {
  width: 150px;
  height:35px;
  margin: 5px;
  text-align: center;
}
label, input {
  width: 150px;
  height:30px;
  margin: 5px;}


button, a {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.clickable {
  cursor: pointer;
}
</style>