export const leftPanelConstants = {
    assets: ['assets', 'Assets','ASSETS'],
    units: ['units', 'Units', 'UNITS_RANDOM'],
    heroes: ['heroes', 'Heroes', 'HEROES'],
    bosses: ['bosses', 'Bosses', 'BOSSES'],
    spawners: ['spawners', 'Spawner', 'SPAWNERS'],
    config: ['config', 'ServerConfig', 'CONFIG'],
    users: ['users', 'User', 'USERS'],
    news: ['news', 'News', 'NEWS'],
    mails: ['mails', 'Mails', 'MAILS'],
    shop: ['shop', 'Shop', 'SHOP'],
    promo_codes: ['promo-codes', 'PromoCodes', 'Promo Codes'], //promo-codes
    promotions: ['promotions', 'Promotions', 'PROMOTIONS'],
    special_offers: ['special-offers', 'SpecialOffers', 'SPECIAL_OFFERS'], //special-offers
    logs: ['logs', 'Logs', 'LOGS'],
    game_logs: ['game-logs', 'GameLogs', 'Game Logs'], //game-logs
    purchases: ['purchases', 'Purchases', 'Purchases'],
    monitoring: ['monitor', 'Monitor', 'MONITOR'],
    bot_decks: ['bot-decks', 'BotDecks', 'BOT_DECKS'], //bot-decks
    mirror_decks: ['mirror-decks', 'MirrorDecks', 'MIRROR_DECKS'], //mirror-decks
    game_mode_decks: ['game-mode-decks', 'GameModeDecks', 'GAME_MODE_DECKS'], //game-mode-decks
    wiki: ['wiki', 'Wiki', 'WIKI'],
    rewards: ['rewards', 'Rewards', 'REWARDS'],
    tips_tricks: ['tips-tricks', 'TipsTricks', 'TIPS_TRICKS'], //tips-tricks
    seasons: ['seasons', 'Seasons', 'SEASONS'],
    season_content: ['season-content', 'SeasonContent', 'SEASON_CONTENT'], //season-content
    leaderboard_trophy: ['leaderboard-trophy', 'TrophyLeaderboard', 'TROPHY_LEADERBOARD'], //leaderboard-trophy
    mock_users: ['mock-users', 'MockUsers', 'MOCK_USERS'], //mock-users
    trophy_road: ['trophy-road', 'TrophyRoad', 'TROPHY_ROAD'], //trophy-road
    events: ['events', 'Events', 'EVENTS'],
    panel_admin: ['panel-admin', 'PanelAdmin', 'PANEL_ADMIN'], //panel-admin
    localization: ['localization', 'Localization', 'LOCALIZATION']
};

