<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('TIPS_TRICKS')}}</h1>
    <div class="content-container">
      <div class="col">
        <div class="content-row" v-for="(tip, index) in tips" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''">
          <textarea v-model="tips[index]" rows="4" />
          <button class="error" @click="tips.splice(index, 1)">{{ $t('REMOVE') }}</button>
        </div>
        <button @click="() => addItem()">{{ $t('ADD') }}</button>
      </div>
      <div class="col col-sticky">
        <div class="buttons-section" style="position: sticky;">
          <button class="success" @click="submit">Save</button>
          <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
          <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "Tips",
  setup() {
    const store = useStore();
    const tips = ref([])

    watch(() => store.getters['resources/getResources'], value => {
      if(value && Array.isArray(value.tips)) {
        tips.value = JSON.parse(JSON.stringify(value.tips));
      }
    }, { immediate: true })

    const addItem = () => {
      tips.value.push("")
    }

    const submit = () => {
      store.dispatch('resources/updateResourceFields', { tips: tips.value })
    }

    const exportTo = environment => {
      store.dispatch('resources/exportResourceFields', { updateBody: { tips: tips.value }, environment })
    }

    const env = process.env.ENV;


    return {
      tips,
      addItem,
      submit,
      exportTo,
      env
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.col {
  padding: 25px 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.col-sticky {
  border: none;
  position: sticky;
  top: 0;
}

.col-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
}

.content-row.header-row {
  font-weight: bold;
}

.content-row.alternate {
  background-color: lightgray;
}

.row-title {
  width: 80%;
  text-align: start;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.buttons-section button {
  font-size: 1.25rem;
  margin: 5px 0;
}

.col-full-width {
  width: 100%;
  box-sizing: border-box;
}
.table {
  width: 100%;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>