import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

import authService from "@/services/auth"
import store from '@/store'
import { getAvailableDispatches } from '@/util/permissionHelper'

// initial state
const state = () => ({
    loginError: false,
    errorText: '',
    loginLoading: true,
    isLoggedIn: false,
    user: null,
    appConfig: {},
    claims: []
})

// getters
const getters = {
    getEmail: ({ user }) => {
        return (!user || !user.email) ? '' : user.email
    },
    getUser: ({ user }) => {
        return user
    },
    getAppConfig: ({ appConfig }) => {
        return appConfig
    },
    getIsLoggedIn: ({ isLoggedIn }) => {
        return isLoggedIn
    },
    getLoginLoading: ({ loginLoading }) => {
        return loginLoading
    },
    getLoginError: ({ loginError, errorText}) => {
        return loginError && errorText
    },
    getPermissionClaims: ({ claims }) => {
        return claims
    }
}

// actions
const actions = {
    login ({ commit }, credentials) {
        commit('setLoginLoading', true)
        commit('setLoginError', false)
        authService.login(credentials).then( result => {
            commit('setUser', result)
        }).catch(() => {
            commit('removeUser')
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.LOGIN'),
                type: 'error'
            })
        }).finally(() => {
            commit('setLoginLoading', false)
        })
    },
    logout ({ commit }) {
        authService.logout().then(() => {
            commit('removeUser')
        })
    },
    sendPasswordResetEmail (state, email) {
        authService.sendPasswordResetEmail(email).then(() => {
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.SEND_RESET_EMAIL'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.SEND_RESET_EMAIL'),
                type: 'error'
            })
        })
    },
    getAppConfig ({ commit }) {
        authService.getAppConfig().then( result => {
            commit('setAppConfig', result)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.APP_CONFIG'),
                type: 'error'
            })
        })
    },
    setUser({ commit }, user) {
        commit('setUser', user)
        commit('setLoginLoading', false)
        notify({
            title: i18n.global.t('SUCCESS'),
            text: i18n.global.t('NOTIF.SUCCESS.LOGIN'),
            type: 'success'
        })
    },
    setClaims({ commit }, claims) {
        commit('setClaims', claims);
    },
    removeUser({ commit }) {
        commit('setLoginLoading', false)
        commit('removeUser')
    }
}

// mutations
const mutations = {
    setLoginLoading(state, value) {
        state.loginLoading = value
    },
    setUser(state, user) {
        state.user = user
        state.isLoggedIn = !!user
        // store.dispatch('serverConfig/getServerConfig')
        // store.dispatch('units/getUnits')
        // store.dispatch('unitsNext/getUnits')
        // store.dispatch('heroes/getHeroes')
        // store.dispatch('bosses/getBosses')
        // store.dispatch('spawners/getSpawner')
        // store.dispatch('news/getNews')
        // store.dispatch('mails/getMails')
        // store.dispatch('shop/getShopContent')
        // store.dispatch('promotions/getPromotions')
        // store.dispatch('botDecks/getBotDecks')
        // store.dispatch('mirrorDecks/getMirrorDecks')
        // store.dispatch('gameModeDecks/getGameModeDecks')
        // store.dispatch('wiki/getWiki')
        // store.dispatch('chests/getChests')
        // store.dispatch('rewards/getRewards')
        // store.dispatch('auth/getAppConfig')
        // store.dispatch('resources/getResources')
        // store.dispatch('assets/getAssets')
        // store.dispatch('seasons/getSeasons')
        // store.dispatch('seasons/getSeasonPassContent')
        // store.dispatch('leaderboard/getTrophyLeaderboard')
        // store.dispatch('mockUsers/getMockUsers')
        // store.dispatch('road/getTrophyRoad')
        // store.dispatch('events/getEvents')
        // store.dispatch('gameModeDecks/getGameModeDecks')
        // store.dispatch('panelAdmin/getPanelUsers')


    },
    setClaims(state, claims) {
        state.claims = claims;
        store.dispatch('auth/getAppConfig')
        console.log(getAvailableDispatches(claims))
        getAvailableDispatches(claims).forEach(dispatchStr => store.dispatch(dispatchStr));
    },
    setAppConfig(state, appConfig) {
        state.appConfig = appConfig
    },
    removeUser(state) {
        state.user = null
        state.isLoggedIn = false
    },
    setLoginError(state, value) {
        if(value) {
            state.errorText = value
        }
        state.loginError = !!value

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}