import mailService from "@/services/mail"
import store from "@/store"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    mails: []
})

// getters
const getters = {
    getMails: ({ mails }) => {
        return mails
    }
}

// actions
const actions = {
    getMails({ commit }) {
        mailService.getMails().then(mails => {
            commit('setMails', mails)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_MAILS'),
                type: 'error'
            })
        })
    },
    updateMail(state, { id, data }) {
        mailService.setMail(id,data).then(() => {
            store.dispatch('mails/getMails')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_MAIL'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_MAIL'),
                type: 'error'
            })
        })
    },
    exportMail(state, { id, data, environment }) {
        mailService.exportMail(id,data, environment).then(() => {
            store.dispatch('mails/getMails')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_MAIL'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_MAIL'),
                type: 'error'
            })
        })
    },
    async addMail(state, { data }) {
        return await mailService.addMail(data).then(res => {
            store.dispatch('mails/getMails')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_MAIL'),
                type: 'success'
            })
            console.log(res)
            return res
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_MAIL'),
                type: 'error'
            })
            return null
        })
    },
    deleteMail(state, { id }) {
        mailService.deleteMail(id).then(() => {
            store.dispatch('mails/getMails')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_MAIL'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_MAIL'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setMails(state, mails) {
        state.mails = mails
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}