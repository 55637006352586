export default {
    RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    UNIT_TYPE: [
        {
            text: 'DAMAGE',
            value: 0
        },
        {
            text: 'MERGE',
            value: 1
        },
        {
            text: 'TRAP',
            value: 2
        },
        {
            text: 'INCOME',
            value: 3
        },
        {
            text: 'DEBUFF',
            value: 4
        },
        {
            text: 'BUFF',
            value: 5
        }
    ],
    AURA: [
        {
            text: 'NONE',
            value: 0
        },
        {
            text: 'SELF',
            value: 1
        },
        {
            text: 'ADJACENT',
            value: 2
        },
        {
            text: 'HORIZONTAL',
            value: 3
        },
        {
            text: 'VERTICAL',
            value: 4
        },
        {
            text: 'HORIZONTAL/VERTICAL',
            value: 5
        },
        {
            text: 'BOARD_WIDE',
            value: 6
        },
        {
            text: 'BAND_OF_BROTHERS',
            value: 7
        }
    ],
    GAME_STATUS: [
        {
            text: 'DISABLED',
            value: 0
        },
        {
            text: 'ENABLED',
            value: 1
        }
    ],
    ACTION_TYPES: {
        base_action: [
            {
                text: 'NONE',
                value: 0
            },
            {
                text: 'ATTACK',
                value: 1
            },
            {
                text: 'TRAP',
                value: 2
            },
            {
                text: 'NO_ATTACK',
                value: 3
            }
        ],
        effect1: [
            {
                text: 'NONE',
                value: 0
            },
            {
                text: 'BAND_OF_BROTHERS',
                value: 1
            },
            {
                text: 'CHAIN',
                value: 2
            },
            {
                text: 'POISON',
                value: 3
            },
            {
                text: 'CYCLOPS_DAMAGE_BUFF',
                value: 4
            },
            {
                text: 'SPLASH',
                value: 5
            },
            {
                text: 'LOWER_ENEMY_UNIT_RANK',
                value: 6
            },
            {
                text: 'ENHANCED_TRAP',
                value: 7
            }
            ,
            {
                text: 'INSTANT_KILL_BUFF',
                value: 8
            },
            {
                text: 'ICE',
                value: 9
            },
            {
                text: 'MANA_GENERATION',
                value: 10
            },
            {
                text: 'DESTROY_FRIENDLY_CARD',
                value: 11
            },
            {
                text: 'RAGE_DAMAGE_BUFF',
                value: 12
            }
        ],
        effect2: [
            {
                text: 'NONE',
                value: 0
            },
            {
                text: 'BAND_OF_BROTHERS',
                value: 1
            },
            {
                text: 'CHAIN',
                value: 2
            },
            {
                text: 'POISON',
                value: 3
            },
            {
                text: 'CYCLOPS_DAMAGE_BUFF',
                value: 4
            },
            {
                text: 'SPLASH',
                value: 5
            },
            {
                text: 'LOWER_ENEMY_UNIT_RANK',
                value: 6
            },
            {
                text: 'ENHANCED_TRAP',
                value: 7
            }
            ,
            {
                text: 'INSTANT_KILL_BUFF',
                value: 8
            },
            {
                text: 'ICE',
                value: 9
            },
            {
                text: 'MANA_GENERATION',
                value: 10
            },
            {
                text: 'DESTROY_FRIENDLY_CARD',
                value: 11
            },
            {
                text: 'RAGE_DAMAGE_BUFF',
                value: 12
            }
        ]
    },
    TARGETING: [
        {
            text: 'NONE',
            value: 0
        },
        {
            text: 'FIRST',
            value: 1
        },
        {
            text: 'STRONGEST',
            value: 2
        },
        {
            text: 'CLOSEST',
            value: 3
        },
        {
            text: 'RANDOM',
            value: 4
        },
        {
            text: 'UNAFFECTED_POISON_FIRST',
            value: 5
        },
        {
            text: 'UNAFFECTED_ICE_FIRST',
            value: 6
        },
        {
            text: 'MANA_STEAL_FIRST',
            value: 7
        },
        {
            text: 'UNAFFECTED_POISON_RANDOM',
            value: 8
        },
        {
            text: 'MANA_STEAL_RANDOM',
            value: 9
        },
        {
            text: 'LOWEST_STUN_STACK',
            value: 10
        },
        {
            text: "NOT_INCREASED_DAMAGE_TAKEN_FIRST",
            value: 11
        }
    ],
    VALUE_TYPE: [
        {
            text: 'NONE',
            value: 0
        },
        {
            text: 'NUMBER',
            value: 1
        },
        {
            text: 'PERCENTAGE',
            value: 2
        }
    ],
    upgrade_types: [
        {
            text: 'UNAFFECTED',
            value: 3
        },
        {
            text: 'NUMBER',
            value: 0
        },
        {
            text: 'PERCENTAGE',
            value: 1
        }
    ],
    merge_types: [
        {
            text: 'UNAFFECTED',
            value: 3
        },
        {
            text: 'NUMBER',
            value: 0
        },
        {
            text: 'PERCENTAGE',
            value: 1
        },
        {
            text: 'X-FACTOR',
            value: 2
        }
    ],
    powerup_types: [
        {
            text: 'UNAFFECTED',
            value: 3
        },
        {
            text: 'NUMBER',
            value: 0
        },
        {
            text: 'PERCENTAGE',
            value: 1
        },
        {
            text: 'X-FACTOR',
            value: 2
        }
    ],
    ICONS: [
        {
            text: 'DAMAGE',
            value: 0
        },
        {
            text: 'ATTACK_SPEED',
            value: 1
        },
        {
            text: 'TIMED_ATTRIBUTE',
            value: 2
        },
        {
            text: 'BUFF_ATTRIBUTE',
            value: 3
        }
    ],
    FIELD_VALUES: [
        {
            text: "SINGLE_VALUE",
            value: "single_value"
        },
        {
            text: "MULTIPLE_VALUE",
            value: "multiple_values"
        },
        {
            text: "INTERVAL",
            value: "interval"
        },
        {
            text: "DURATION",
            value: "duration"
        },
        {
            text: "PROBABILITY",
            value: "probability"
        },
        {
            text: "RADIUS",
            value: "radius"
        }
    ],
    ANIMATION_TYPE: [
        {
            text: "ATTACK_ONLY",
            value: 0
        },
        {
            text: "IDLE_ONLY",
            value: 1
        },
        {
            text: "MIXED",
            value: 2
        }
    ]

}