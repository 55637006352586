<template>
    <div class="table">
        <custom-table server-endpoint="api/game-logs/get" @open-popup="openLogPopup" :page-size="20" :columns="columns" initial-sort-field="log_time" />
        <log-popup :popup-open="logPopupOpen" :content="{ ...popupContent }" @popup-close="logPopupOpen = false" />
    </div>
</template>

<script>
import Table from "@/components/TableResponsive";
import LogPopup from "@/components/LogPopup"    ;
import { ref } from '@vue/reactivity';

export default {
  name: "index",
  components: {
    "custom-table": Table,
    "log-popup": LogPopup
  },
  setup() {
      
        const logPopupOpen = ref(false);
        const popupContent = ref({})

        const openLogPopup = content => {
            popupContent.value = content;
            logPopupOpen.value = true;
        }

        const columns = [
        {
            text: "Log Level",
            field: "log_level",
            type: "number",
            filterable: true
        },
        {
            text: "Log Type",
            field: "log_type",
            type: "number",
            filterable: true
        },
        {
            text: "Module ID",
            field: "module_id",
            filterable: true
        },
        {
            text: "User ID",
            field: "user_id",
            filterable: true
        },
        {
            text: "Log Time",
            field: "log_time",
            filterable: true,
            type: 'date'
        },
        {
            text: "Version",
            field: "version",
            filterable: true
        },
        {
            text: "Operating System",
            field: "operating_system",
            filterable: true,
        }
    ];
    return {
      columns,
      logPopupOpen,
      openLogPopup,
      popupContent
    }
  }
}
</script>

<style scoped>
.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
</style>
