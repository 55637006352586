<template>
  <div class="main-container">
    <div class="heading">
      <span class="main-heading">{{$t('UNIT')}}</span>
      <span>PVP</span>
      <label class="switch">
        <input type="checkbox" v-model="coopSwitch">
        <span class="slider round"></span>
      </label>
      <span>COOP</span>
    </div>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div style="top: 0; left: 0; z-index: 2; pointer-events: all; background-color: rgba(248, 249, 250, 0.5); position: absolute; width: 100%; height: 100%;" :style="{ display: coopSwitch ? 'block': 'none'}" />
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.NAME`) }}</p>
            <input v-model="currentUnit.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DESC`) }}</p>
            <textarea v-model="currentUnit.desc"/>
          </div>
          <div class="form-container">
            <select v-model="selectedLanguage">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
            <p>{{ 'Localized Name' }}</p>
            <input v-model="currentUnit.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ "Localized Description" }}</p>
            <textarea v-model="currentUnit.localizables.desc[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.NOTES`) }}</p>
            <textarea v-model="currentUnit.notes"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.COLOR_CODE`) }}</p>
            <input v-model="currentUnit.color"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.TROPHY_LEVEL`) }}</p>
            <input type="number" v-model.number="currentUnit.trophy_level"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.TARGETING_INFO`) }}</p>
            <input v-model="currentUnit.targeting_info" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.RARITY`) }}</p>
            <select v-model.number="currentUnit.rarity">
              <option v-for="rarity in unitConstants.RARITY" v-bind:key="rarity.value" :value="rarity.value">{{ $t('UNIT_VALUES.' + rarity.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.UNIT_TYPE`) }}</p>
            <select v-model.number="currentUnit.unit_type">
              <option v-for="unitType in unitConstants.UNIT_TYPE" v-bind:key="unitType.value" :value="unitType.value">{{ $t('UNIT_VALUES.' + unitType.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.AURA`) }}</p>
            <select v-model.number="currentUnit.aura">
              <option v-for="aura in unitConstants.AURA" v-bind:key="aura.value" :value="aura.value">{{ $t('UNIT_VALUES.' + aura.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INDEX`) }}</p>
            <input v-model.number="currentUnit.index"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.ANIMATION_TYPE`) }}</p>
            <select v-model.number="currentUnit.animation_type">
              <option v-for="gameStatus in unitConstants.ANIMATION_TYPE" v-bind:key="gameStatus.value" :value="gameStatus.value">{{ $t('UNIT_VALUES.' + gameStatus.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.GAME_STATUS`) }}</p>
            <select v-model.number="currentUnit.status">
              <option v-for="gameStatus in unitConstants.GAME_STATUS" v-bind:key="gameStatus.value" :value="gameStatus.value">{{ $t('UNIT_VALUES.' + gameStatus.text) }}</option>
            </select>
          </div>
        </div>
        <template v-if="!coopSwitch">
        <div class="col" v-for="actionName in ['base_action', 'effect1', 'effect2']" v-bind:key="actionName">
          <div class="col-title">{{ $t(`UNIT_VALUES.${actionName}`) }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.NAME`) }}</p>
            <input v-model="currentUnit[actionName].name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.ACTION_TYPE`) }}</p>
            <select v-model.number="currentUnit[actionName].type">
              <option v-for="option in unitConstants.ACTION_TYPES[actionName]" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.TARGETING`) }}</p>
            <select v-model.number="currentUnit[actionName].targeting">
              <option v-for="option in unitConstants.TARGETING" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="col-subtitle">{{ $t(`UNIT_VALUES.${actionName}`) + ' Values' }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit[actionName].single_value_type">
              <option v-for="option in unitConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit[actionName].single_value" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit[actionName].multiple_value_type">
              <option v-for="option in unitConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentUnit[actionName].multiple_value_type !== 0">
            <div class="form-container" v-for="(multipleValue, multipleValueIndex) in (currentUnit[actionName].multiple_values || [])" v-bind:key="multipleValueIndex">
              <p>{{ $t('MULTIPLE_VALUE') + ' ' + (multipleValueIndex + 1) }}<button class="error" @click="currentUnit[actionName].multiple_values.splice(multipleValueIndex, 1)">{{ $t('REMOVE')}}</button></p>
              <input type="number" v-model.number="currentUnit[actionName].multiple_values[multipleValueIndex]" />
            </div>
            <button @click="addMultipleValue(actionName)" class="success">{{ $t('ADD_MULTIPLE_VALUE') }}</button>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INTERVAL`) }}</p>
            <input type="number" v-model.number="currentUnit[actionName].interval"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DURATION`) }}</p>
            <input type="number" v-model.number="currentUnit[actionName].duration"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.PROBABILITY`) }}</p>
            <input type="number" v-model.number="currentUnit[actionName].probability"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.RADIUS`) }}</p>
            <input type="number" v-model.number="currentUnit[actionName].radius"/>
          </div>
        </div>
        </template>
        <template v-else>
        <div class="col" v-for="actionName in ['base_action', 'effect1', 'effect2']" v-bind:key="actionName">
          <div class="col-title">{{ $t(`UNIT_VALUES.${actionName}`) }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.NAME`) }}</p>
            <input v-model="currentUnit.coop[actionName].name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.ACTION_TYPE`) }}</p>
            <select v-model.number="currentUnit.coop[actionName].type">
              <option v-for="option in unitConstants.ACTION_TYPES[actionName]" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.TARGETING`) }}</p>
            <select v-model.number="currentUnit.coop[actionName].targeting">
              <option v-for="option in unitConstants.TARGETING" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="col-subtitle">{{ $t(`UNIT_VALUES.${actionName}`) + ' Values' }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.coop[actionName].single_value_type">
              <option v-for="option in unitConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.coop[actionName].single_value" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.coop[actionName].multiple_value_type">
              <option v-for="option in unitConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentUnit.coop[actionName].multiple_value_type !== 0">
            <div class="form-container" v-for="(multipleValue, multipleValueIndex) in (currentUnit[actionName].multiple_values || [])" v-bind:key="multipleValueIndex">
              <p>{{ $t('MULTIPLE_VALUE') + ' ' + (multipleValueIndex + 1) }}<button class="error" @click="currentUnit[actionName].multiple_values.splice(multipleValueIndex, 1)">{{ $t('REMOVE')}}</button></p>
              <input type="number" v-model.number="currentUnit.coop[actionName].multiple_values[multipleValueIndex]" />
            </div>
            <button @click="addMultipleValue(actionName)" class="success">{{ $t('ADD_MULTIPLE_VALUE') }}</button>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INTERVAL`) }}</p>
            <input type="number" v-model.number="currentUnit.coop[actionName].interval"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DURATION`) }}</p>
            <input type="number" v-model.number="currentUnit.coop[actionName].duration"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.PROBABILITY`) }}</p>
            <input type="number" v-model.number="currentUnit.coop[actionName].probability"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.RADIUS`) }}</p>
            <input type="number" v-model.number="currentUnit.coop[actionName].radius"/>
          </div>
        </div>
        </template>
      </div>
      <div class="second-section">
        <template v-if="!coopSwitch">
        <div class="col" v-for="sectionType in ['upgrade', 'powerup', 'merge']" v-bind:key="sectionType">
          <div class="col-title">{{ $t('UNIT_VALUES.' + sectionType) }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.base_action') }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.base_action[sectionType +'_single_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.base_action[sectionType +'_single_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.base_action[sectionType +'_multiple_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.base_action[sectionType +'_multiple_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
            <select v-model.number="currentUnit.base_action[sectionType +'_probability_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.base_action[sectionType +'_probability_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
            <select v-model.number="currentUnit.base_action[sectionType +'_interval_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.base_action[sectionType +'_interval_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
            <select v-model.number="currentUnit.base_action[sectionType +'_duration_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.base_action[sectionType +'_duration_value']" />
          </div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.effect1') }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect1[sectionType +'_single_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect1[sectionType +'_single_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect1[sectionType +'_multiple_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect1[sectionType +'_multiple_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect1[sectionType +'_probability_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect1[sectionType +'_probability_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect1[sectionType +'_interval_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect1[sectionType +'_interval_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect1[sectionType +'_duration_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect1[sectionType +'_duration_value']" />
          </div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.effect2') }}</div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect2[sectionType +'_single_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect2[sectionType +'_single_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect2[sectionType +'_multiple_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect2[sectionType +'_multiple_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect2[sectionType +'_probability_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect2[sectionType +'_probability_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect2[sectionType +'_interval_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect2[sectionType +'_interval_value']" />
          </div>
          <div class="form-container">
            <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
            <select v-model.number="currentUnit.effect2[sectionType +'_duration_value_type']">
              <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentUnit.effect2[sectionType +'_duration_value']" />
          </div>
        </div>
        </template>
        <template v-else>
          <div class="col" v-for="sectionType in ['upgrade', 'powerup', 'merge']" v-bind:key="sectionType">
            <div class="col-title">{{ $t('UNIT_VALUES.' + sectionType) }}</div>
            <div class="col-subtitle">{{ $t('UNIT_VALUES.base_action') }}</div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.base_action[sectionType +'_single_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.base_action[sectionType +'_single_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.base_action[sectionType +'_multiple_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.base_action[sectionType +'_multiple_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.base_action[sectionType +'_probability_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.base_action[sectionType +'_probability_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.base_action[sectionType +'_interval_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.base_action[sectionType +'_interval_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.base_action[sectionType +'_duration_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.base_action[sectionType +'_duration_value']" />
            </div>
            <div class="col-subtitle">{{ $t('UNIT_VALUES.effect1') }}</div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect1[sectionType +'_single_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect1[sectionType +'_single_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect1[sectionType +'_multiple_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect1[sectionType +'_multiple_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect1[sectionType +'_probability_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect1[sectionType +'_probability_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect1[sectionType +'_interval_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect1[sectionType +'_interval_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect1[sectionType +'_duration_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect1[sectionType +'_duration_value']" />
            </div>
            <div class="col-subtitle">{{ $t('UNIT_VALUES.effect2') }}</div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.SINGLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect2[sectionType +'_single_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect2[sectionType +'_single_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.MULTIPLE_VALUE_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect2[sectionType +'_multiple_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect2[sectionType +'_multiple_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.PROBABILITY_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect2[sectionType +'_probability_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect2[sectionType +'_probability_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.INTERVAL_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect2[sectionType +'_interval_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect2[sectionType +'_interval_value']" />
            </div>
            <div class="form-container">
              <p>{{ $t(`UNIT_VALUES.DURATION_TYPE`) }}</p>
              <select v-model.number="currentUnit.coop.effect2[sectionType +'_duration_value_type']">
                <option v-for="option in unitConstants[sectionType + '_types']" v-bind:key="option.value" :value="option.value">{{ $t('UNIT_VALUES.' + option.text) }}</option>
              </select>
              <input type="number" v-model.number="currentUnit.coop.effect2[sectionType +'_duration_value']" />
            </div>
          </div>
        </template>
      </div>
      <div class="col">
        <div class="col-title">{{ $t('UNIT_VALUES.INFO_LIST') }}</div>
        <div class="third-section">
          <div class="col-subtitle">{{ $t('UNIT_VALUES.NAME') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.CATEGORY') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.FIELD_VALUE') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.ICON') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.IS_XFACTOR_INCREASE') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.IS_BASE_DAMAGE') }}</div>
          <div class="col-subtitle">{{ $t('UNIT_VALUES.IS_PROPORTIONAL_DAMAGE') }}</div>

          <div class="col-subtitle">{{ $t('UNIT_VALUES.ACTIONS') }}</div>
        </div>
        <div class="third-section" v-for="(info, index) in currentUnit.info_fields || []" v-bind:key="index">
          <input v-model="currentUnit.info_fields[index].name" />
          <select v-model.number="currentUnit.info_fields[index].category">
            <option v-for="category in ['base_action', 'effect1', 'effect2']" v-bind:key="category" :value="category">{{ $t('UNIT_VALUES.' + category) }}</option>
          </select>
          <select v-model.number="currentUnit.info_fields[index].fieldValue">
            <option v-for="fieldValue in unitConstants.FIELD_VALUES" v-bind:key="fieldValue.value" :value="fieldValue.value">{{ $t('UNIT_VALUES.' + fieldValue.text) }}</option>
          </select>
          <select v-model.number="currentUnit.info_fields[index].icon">
            <option v-for="icon in unitConstants.ICONS" v-bind:key="icon.value" :value="icon.value">{{ $t('UNIT_VALUES.' + icon.text) }}</option>
          </select>
          <input type="checkbox" v-model="currentUnit.info_fields[index].is_xfactor_increase" />
          <input type="checkbox" v-model="currentUnit.info_fields[index].is_base_damage" />
          <input type="checkbox" v-model="currentUnit.info_fields[index].is_proportional_damage" />

          <div><button @click="currentUnit.info_fields.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button></div>
        </div>
        <div class="col">
          <button @click="addInfoField" class="success">{{ $t('UNIT_VALUES.ADD_INFO_FIELD') }}</button>
        </div>
      </div>
      </div>
      <div class="col">
        <div class="col-title">{{ "Promotion Assets" }}</div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.SHOP_BANNER_ASSET`) }}</p>
          <select v-model="currentUnit.shop_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.POPUP_BANNER_ASSET`) }}</p>
          <select v-model="currentUnit.popup_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`PROMOTION_VALUES.MINI_BANNER_ASSET`) }}</p>
          <select v-model="currentUnit.mini_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </div>
    </div>
</template>

<script>
import { computed, reactive, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import unitConstants from "@/constants/unitConstants";
import unitSchema from "@/schemas/unit";
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "Unit",
  setup() {
    const store = useStore();
    const units = computed(() => store.getters['units/getUnits'])
    const coopSwitch = ref(false)
    const currentUnit = reactive({ ...unitSchema })
    const route = useRoute();
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    onMounted(() => {
      if(route.query.id && units.value.length > 0) {
        const editingUnit = JSON.parse(JSON.stringify(units.value.find(x => x.id === route.query.id)))
        Object.keys(editingUnit).forEach(key => {
          currentUnit[key] = editingUnit[key]
        })
      }
    })

    watch(() => store.getters['units/getUnits'], units => {
      if(route.query.id && units.length > 0) {
        const editingUnit = JSON.parse(JSON.stringify(units.find(x => x.id === route.query.id)))
        Object.keys(editingUnit).forEach(key => {
          currentUnit[key] = editingUnit[key]
        })
        if (!currentUnit.localizables || !currentUnit.localizables.name || !currentUnit.localizables.desc) {
          currentUnit.localizables = { desc: {}, name: {} }
        }
      }
    })

    const removeMultipleValue = (actionName, index) => {
      const newArr = []
      currentUnit[actionName].multiple_values.forEach((val, i) => {
        if(i !== index) {
          newArr.push(val)
        }
      })
      currentUnit[actionName].multiple_values = newArr
    }

    const addMultipleValue = actionName => {
      const newArr = [ ...(currentUnit[actionName].multiple_values || [])]
      newArr.push(0)
      currentUnit[actionName].multiple_values = newArr
    }

    const addInfoField = () => {
      const newArray = []
      if(currentUnit.info_fields) {
        currentUnit.info_fields.forEach(field => newArray.push(field))
      }
      newArray.push({
        name: '',
        category: 0,
        fieldValue: 'single_value',
        icon: 0,
        is_xfactor_increase: false
      })
      currentUnit.info_fields = newArray
    }

    const prepareInfoList = () => {
      currentUnit.info_fields = currentUnit.info_fields.map(field => {
        let fieldAffix;
        if(field.fieldValue === 'multiple_values') {
          fieldAffix = 'multiple_value'
        } else if(field.fieldValue === 'single_value') {
          fieldAffix = 'single_value'
        } else {
          fieldAffix = field.fieldValue + '_value'
        }
        return {
          specVal: currentUnit[field.category][field.fieldValue],
          specValType: currentUnit[field.category][field.fieldValue + '_type'],
          upgradeVal: currentUnit[field.category]['upgrade_' + fieldAffix],
          upgradeValType: currentUnit[field.category]['upgrade_' + fieldAffix + '_type'],
          powerUpVal: currentUnit[field.category]['powerup_' + fieldAffix],
          powerUpValType: currentUnit[field.category]['powerup_' + fieldAffix + '_type'],
          mergeValType: currentUnit[field.category]['merge_' + fieldAffix + '_type'],
          mergeVal: currentUnit[field.category]['merge_' + fieldAffix],
          is_xfactor_increase: field.is_xfactor_increase || false,
          is_base_damage: field.is_base_damage || false,
          is_proportional_damage: field.is_proportional_damage || false,
          name: field.name,
          category: field.category,
          fieldValue: field.fieldValue,
          icon: field.icon
        }
      })
    }

    const submit = () => {
      prepareInfoList()
      if(route.query.id) {
        store.dispatch('units/updateUnit', { id: route.query.id, data: currentUnit })
      } else {
        store.dispatch('units/addUnit', { data: currentUnit })
      }
    }

    const exportTo = environment => {
      prepareInfoList()
      store.dispatch('units/exportUnit', { id: route.query.id, data: currentUnit, environment })
    }

    const env = process.env.ENV;

    return {
      unitConstants,
      currentUnit,
      removeMultipleValue,
      addMultipleValue,
      addInfoField,
      submit,
      coopSwitch,
      exportTo,
      env,
      assetList,
      languages,
      selectedLanguage
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.heading {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.heading span {
  padding: 0 10px;
}

.main-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 20px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
  align-items: center;
}
p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
  align-self: center;
}

.col {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.col.disabled {
  pointer-events: none;
  background-color: #cccccc;
}

</style>