<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{$t('UNITS')}}</h1>
      <button @click="localizationCSV_Export" class="success">{{ $t('EXPORT CSV') }}</button>
    </div>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('INDEX') }}</div>
          <div class="cell">{{ $t('NAME') }}</div>
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ $t('DESCRIPTION') }}</div>
          <div class="cell">{{ $t('UNIT_VALUES.UNIT_TYPE') }} </div>
          <div class="cell">{{ $t('UNIT_VALUES.TARGETING') }} </div>
          <div class="cell"><a href="/units/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(unit, index) in unitsArr" v-bind:key="unit.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ unit.index }}</div>
          <div class="cell">{{ unit.name }}</div>
          <div class="cell">{{ unit.id }}</div>
          <div class="cell">{{ unit.desc }}</div>
          <div class="cell">{{ $t('UNIT_VALUES.' + (unitConstants.UNIT_TYPE.find(x => x.value === unit.unit_type) || {}).text) }}</div>
          <div class="cell">{{ $t('UNIT_VALUES.' +(unitConstants.TARGETING.find(x => x.value === unit.base_action.targeting) || {}).text) }}</div>
          <div class="cell">
            <a :href="'/units/add?id=' + unit.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deleteUnit(unit.id, unit.index)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";

import unitSchema from "@/schemas/unit";
import csvUtil from "@/util/csvBuilder"
import unitConstants from "@/constants/unitConstants";

export default {
  name: "Units",
  setup() {
    const store = useStore();
    const unitsArr = ref([])
    watch(() => store.getters['units/getUnits'], value => {
      unitsArr.value = [ ...value ].sort((a,b) => a.index - b.index)
    }, { immediate: true })

    const deleteUnit = (id, index) => {
      store.dispatch('units/deleteUnit', { id, index })
    }

    const localizationCSV_Export = () => {
      csvUtil.buildExportCsv("units", unitSchema.localizables, unitsArr.value.filter(x => x.status === 1))
    }

    return {
      unitsArr,
      unitConstants,
      deleteUnit,
      localizationCSV_Export
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 4fr 14fr 2fr 2fr 1fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  display: grid;
  padding: 0 10px;
  align-items: center;
}


button, a {
  text-decoration: none;
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>