import gameModeDecksService from "@/services/gameModeDeck"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    gameModeDecks: []
})

// getters
const getters = {
    getGameModeDecks: ({ gameModeDecks }) => {
        return gameModeDecks
    }
}

// actions
const actions = {
    getGameModeDecks({ commit }) {
        gameModeDecksService.getGameModeDecks().then(gameModeDecks => {
            commit('setGameModeDecks', gameModeDecks)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_GAME_MODE_DECKS'),
                type: 'error'
            })
        })
    },
    updateGameModeDeck(state, { id, data }) {
        gameModeDecksService.setGameModeDeck(id,data).then(() => {
            store.dispatch('gameModeDecks/getGameModeDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_GAME_MODE_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_GAME_MODE_DECK'),
                type: 'error'
            })
        })
    },
    exportGameModeDeck(state, { id, data, environment }) {
        gameModeDecksService.exportGameModeDeck(id,data, environment).then(() => {
            store.dispatch('gameModeDecks/getGameModeDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_GAME_MODE_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_GAME_MODE_DECK'),
                type: 'error'
            })
        })
    },
    addGameModeDeck(state, { data }) {
        gameModeDecksService.addGameModeDeck(data).then(() => {
            store.dispatch('gameModeDecks/getGameModeDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_GAME_MODE_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_GAME_MODE_DECK'),
                type: 'error'
            })
        })
    },
    deleteGameModeDeck(state, { id, index }) {
        gameModeDecksService.deleteGameModeDeck({ id, index }).then(() => {
            store.dispatch('gameModeDecks/getGameModeDecks')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_GAME_MODE_DECK'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_GAME_MODE_DECK'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setGameModeDecks(state, gameModeDecks) {
        state.gameModeDecks = gameModeDecks
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}