import userService from "@/services/user"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({

    user: null
})

// getters
const getters = {
    getUser: ({ user }) => {
        return user
    }
}

// actions
const actions = {
    getUser ({ commit }, body) {
        userService.getUser(body).then(result => {
            commit('setUser', result)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_USER'),
                type: 'error'
            })
        })
    },
    removeUser({ commit }) {
        commit('removeUser')
    },
    updateUser(state, { id, body }) {
        userService.updateUser(id, body).then(() => {
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_USER'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_USER'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    removeUser(state) {
        state.user = null
        state.isLoggedIn = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}