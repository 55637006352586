import getAxios from './axios'

export default {
    getGameModeDecks: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/game-mode-deck/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_GAME_MODE_DECKS'))
            })
        })
    },
    setGameModeDeck: (id, data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/game-mode-deck/edit', { doc_id: id, data }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_GAME_MODE_DECK'))
            })
        })
    },
    exportGameModeDeck: (id, data, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/game-mode-deck/export', { doc_id: id, data, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_GAME_MODE_DECK'))
            })
        })
    },
    deleteGameModeDeck: ({ id, index }) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/game-mode-deck/delete', { doc_id: id, index}).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_GAME_MODE_DECK'))
            })
        })
    },
    addGameModeDeck: data => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/game-mode-deck/add', data).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_GAME_MODE_DECK'))
            })
        })
    },

}