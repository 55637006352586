<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('CONFIG')}}</h1>
    <div class="content-container">
      <div class="content-row">
        <div class="content-container sub-container">
          <div class="content-row" v-for="subKey in configConstants.osSpecificValues" v-bind:key="subKey">
            <div class="row-title"> {{ $t(`DB_VALUES.ios.${subKey}`) }}</div>
            <input v-model="configObj.ios[subKey]" :type="configSchema[subKey]" v-if="subKey !== 'maintenance_date'" />
            <DatePicker
                v-if="subKey === 'maintenance_date'"
                v-model="configObj.ios[subKey]"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="date3"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
            <button
                v-if="subKey === 'maintenance_date'"
                type="button"
                class="error"
                :disabled="configObj.ios[subKey] === 0"
                @click="configObj.ios[subKey] = 0"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div class="content-row">
        <div class="content-container sub-container">
          <div class="content-row" v-for="subKey in configConstants.osSpecificValues" v-bind:key="subKey">
            <div class="row-title"> {{ $t(`DB_VALUES.android.${subKey}`) }}</div>
            <input v-model="configObj.android[subKey]" :type="configSchema[subKey]" v-if="subKey !== 'maintenance_date'" />
            <DatePicker
                v-if="subKey === 'maintenance_date'"
                v-model="configObj.android[subKey]"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm'
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="date4"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
            <button
                v-if="subKey === 'maintenance_date'"
                type="button"
                class="error"
                :disabled="configObj.android[subKey] === 0"
                @click="configObj.android[subKey] = 0"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div class="content-row" v-for="(key, index) in Object.keys(configObj).filter(x => !x.startsWith('_') && x !== 'ios' && x !== 'android' && x !== 'id').sort()" v-bind:key="key" :class="index % 2 === 0 ? 'alternate' : ''">
          <div class="row-title">{{ $t(`DB_VALUES.${key}`) }}</div>
          <input v-model="configObj[key]" :type="configSchema[key]" v-if="key !== 'maintenance_date'" />
          <DatePicker
              v-if="key === 'maintenance_date'"
              v-model="configObj[key]"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm'
              }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
          <button
              v-if="key === 'maintenance_date'"
              type="button"
              class="error"
              :disabled="configObj[key] === 0"
              @click="configObj[key] = 0"
          >
            Clear
          </button>
      </div>
    </div>
    <button class="success" @click="submit">Save</button>
    <button v-if="false" class="info" @click="() => exportTo('staging')">Export to Staging</button>
    <button v-if="false" class="info" @click="() => exportTo('prod')">Export to Production</button>

  </div>
</template>

<script>
import { watch, computed, ref } from "vue";
import { useStore } from "vuex";
import 'v-calendar/dist/style.css';

import configSchema from "../../schemas/config";
import configConstants from "@/constants/configConstants";

export default {
  name: "ServerConfig",
  setup() {
    const store = useStore();
    store.dispatch('serverConfig/getServerConfig')
    const serverConfig = computed(() => store.getters['serverConfig/getServerConfig'])
    const configObj = ref({})
    watch(() => serverConfig, value => {
      configObj.value = { ...value.value }
    }, { immediate: true })

    const submit = () => {
      const setObj = {}
      Object.keys(configObj.value).forEach(key => {
        if(configSchema[key] === 'number') {
          setObj[key] = parseInt(configObj.value[key])
        } else {
          setObj[key] = configObj.value[key]
        }
      })
      store.dispatch('serverConfig/updateServerConfig', setObj)

    }

    const exportTo = environment => {
      const setObj = {}
      Object.keys(configObj.value).forEach(key => {
        if(configSchema[key] === 'number') {
          setObj[key] = parseInt(configObj.value[key])
        } else {
          setObj[key] = configObj.value[key]
        }
      })
      store.dispatch('serverConfig/exportServerConfig', { serverConfig: setObj, environment })
    }

    const env = process.env.ENV;

    return {
      configObj,
      configSchema,
      submit,
      exportTo,
      env,
      configConstants
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.row-title {
  width: 40%;
  text-align: start;
}

.content-container.sub-container {
  border: 1px solid black;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>