export default {
    languages: [
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'pt',
            name: 'Porteguese'
        }
    ],
    localizableObjects: [
        "units",
        "heroes",
        "bosses",
        "promotions",
        "special-offers",
        "shop"
    ]
}