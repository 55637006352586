import shopService from "@/services/shop"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    shopContent: null
})

// getters
const getters = {
    getShopContent: ({ shopContent }) => {
        return shopContent
    }
}

// actions
const actions = {
    updateShopContent({ commit }, shopContent) {
        return shopService.setShopContent(shopContent).then(() => {
            commit('setShopContent', shopContent)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_SHOP_CONTENT'),
                type: 'success'
            })
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_SHOP_CONTENT'),
                type: 'error'
            })
            return false
        })
    },
    exportShopContent({ commit }, { shopContent, environment }) {
        shopService.exportShopContent(shopContent, environment).then(() => {
            commit('setShopContent', shopContent)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_SHOP_CONTENT'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_SHOP_CONTENT'),
                type: 'error'
            })
        })
    },
    getShopContent({ commit }) {
        shopService.getShopContent().then(shopContent => {
            commit('setShopContent', shopContent)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_SHOP_CONTENT'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setShopContent(state, shopContent) {
        state.shopContent = shopContent
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}