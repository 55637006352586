<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('PANEL_USERS')}}</h1>
    <div class="content-container">
      <template v-if="panelUsersArr">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('UID') }}</div>
          <div class="cell">{{ $t('EMAIL') }}</div>
          <div class="cell">{{ $t('IS_ADMIN') }}</div>
          <div class="cell">{{ $t('PERMISSIONS') }}</div>
          <div class="cell action-cell">
            <input v-model="newUserEmail" placeholder="email" />
            <a class="success" @click="addUser">{{ $t('ADD_USER') }}</a>
          </div>
        </div>
        <div class="table-row" v-for="(panelUser, index) in panelUsersArr" v-bind:key="panelUser.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ panelUser.uid }}</div>
          <div class="cell">{{ panelUser.email }}</div>
          <div class="cell" v-if="editingIndex !== index">{{ (panelUser.customClaims || {}).panelAdmin ? 'TRUE' : 'FALSE' }}</div>
          <div class="cell" v-else><input type="checkbox" @change="changeUserAdmin" :checked="selectedPermissions.includes('panelAdmin')" /></div>
          <div class="cell">
            <multiselect
                :disabled="selectedPermissions.includes('panelAdmin')"
                v-if="editingIndex === index"
                v-model="selectedPermissions"
                mode="tags"
                :close-on-select="false"
                :options="selectedPermissions.includes('panelAdmin') ? ['panelAdmin'] : panelAdminConstants.PERMISSION_TYPES"
            ></multiselect>
            <multiselect
                disabled="true"
                v-else
                :model-value="Object.keys(panelUser.customClaims || {}).filter(key => panelUser.customClaims[key])"
                mode="tags"
                :options="panelAdminConstants.PERMISSION_TYPES"
            ></multiselect>
          </div>
          <div class="cell action-cell">
            <template v-if="editingIndex !== index" >
              <button :disabled="apiCallWaiting" @click="() => startPanelUserEdit(index)" class="info"><i class="fas fa-edit"></i></button>
              <button @click="() => deletePanelUser(index)" class="error" :disabled="apiCallWaiting"><i class="fas fa-times"></i></button>

            </template>
            <template v-else>
              <button @click="() => savePanelUser(index)" class="success" :disabled="apiCallWaiting"><i class="fas fa-check"></i></button>

            </template>
          </div>
        </div>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from '@vueform/multiselect'
import "@vueform/multiselect/themes/default.css";

import panelAdminConstants from "@/constants/panelAdminConstants";

export default {
  name: "PanelAdmin",
  components: {
    "multiselect": Multiselect
  },
  setup() {
    const store = useStore();
    const panelUsersArr = computed(() => store.getters['panelAdmin/getPanelUsers']);
    const editingIndex = ref(-1);
    const editingUser = ref(panelAdminConstants.PERMISSION_TYPES.reduce((a, v) => ({ ...a, [v]: false}), {}));
    let selectedPermissions = ref([]);

    const startPanelUserEdit = index => {
      editingIndex.value = index;
      if(panelUsersArr.value[index].customClaims.panelAdmin) {
        selectedPermissions.value = ['panelAdmin'];
        return;
      }
      panelAdminConstants.PERMISSION_TYPES.forEach(key => {
        if(panelUsersArr.value[index].customClaims[key]) {
          selectedPermissions.value.push(key);
        }
      })
    }

    const resetEditingMode = () => {
      editingIndex.value = -1;
      selectedPermissions.value = [];
      editingUser.value = panelAdminConstants.PERMISSION_TYPES.reduce((a, v) => ({ ...a, [v]: false}), {});
    }

    const apiCallWaiting = ref(false);

    const savePanelUser = index => {
      console.log(index);
      const permissions = selectedPermissions.value.reduce((acc, val) => {
        return { ...acc, [val]: true }
      }, {});
      apiCallWaiting.value = true;
      store.dispatch('panelAdmin/updatePanelUserPermissions', { uid: panelUsersArr.value[editingIndex.value].uid, permissions }).finally(() => {
        resetEditingMode();
        apiCallWaiting.value = false;
      })
    }

    const newUserEmail = ref("");
    const addUser = () => {
      apiCallWaiting.value = true;
      store.dispatch('panelAdmin/createPanelUser', { email: newUserEmail.value }).finally(() => {
        newUserEmail.value = "";
        apiCallWaiting.value = false;
      })
    }

    const changeUserAdmin = e => {
      console.log(e.target.checked)
      if(e.target.checked) {
        selectedPermissions.value = ['panelAdmin'];
      } else {
        selectedPermissions.value = [];
      }
    }

    const deletePanelUser = index => {
      console.log(index, editingUser.value);
      apiCallWaiting.value = true;
      store.dispatch('panelAdmin/deletePanelUser', panelUsersArr.value[index].uid).finally(() => {
        resetEditingMode();
        apiCallWaiting.value = false;
      })
    }

    return {
      panelUsersArr,
      startPanelUserEdit,
      editingIndex,
      savePanelUser,
      resetEditingMode,
      editingUser,
      apiCallWaiting,
      newUserEmail,
      addUser,
      deletePanelUser,
      selectedPermissions,
      panelAdminConstants,
      changeUserAdmin
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 4fr 3fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  display: grid;
  padding: 0 10px;
  align-items: center;
}

.cell.action-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}


button, a {
  text-decoration: none;
  display: inline;
  color: black;
  font-weight: 400;
  font-size: 0.9rem;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>