export default {
    "id": undefined,
    "name": undefined,
    "desc": undefined,
    "localizables": {
        desc: {},
        name: {}
    },
    "notes": undefined,
    "color": undefined,
    "rarity": undefined,
    "unit_type": undefined,
    "trophy_level": undefined,
    "targeting": undefined,
    "targeting_info": undefined,
    "aura": undefined,
    "index": undefined,
    "animation_type": undefined,
    "info_fields": [],
    "base_action": {
        "name": undefined,
        "type": undefined,
        "single_value_type": undefined,
        "single_value": undefined,
        "multiple_value_type": undefined,
        "multiple_values": undefined,
        "interval": undefined,
        "duration": undefined,
        "probability": undefined,
        "radius": undefined,
        "powerup_single_value_type": undefined,
        "powerup_single_value": undefined,
        "powerup_multiple_value_type": undefined,
        "powerup_multiple_value": undefined,
        "powerup_interval_value_type": undefined,
        "powerup_interval_value": undefined,
        "powerup_duration_value_type": undefined,
        "powerup_duration_value": undefined,
        "powerup_probability_value_type": undefined,
        "powerup_probability_value": undefined,

        "upgrade_single_value_type": undefined,
        "upgrade_single_value": undefined,
        "upgrade_multiple_value_type": undefined,
        "upgrade_multiple_value": undefined,
        "upgrade_interval_value_type": undefined,
        "upgrade_interval_value": undefined,
        "upgrade_duration_value_type": undefined,
        "upgrade_duration_value": undefined,
        "upgrade_probability_value_type": undefined,
        "upgrade_probability_value": undefined,

        "merge_single_value_type": undefined,
        "merge_single_value": undefined,
        "merge_multiple_value_type": undefined,
        "merge_multiple_value": undefined,
        "merge_interval_value_type": undefined,
        "merge_interval_value": undefined,
        "merge_duration_value_type": undefined,
        "merge_duration_value": undefined,
        "merge_probability_value_type": undefined,
        "merge_probability_value": undefined,
    },
    "effect1": {
        "name": undefined,
        "type": undefined,
        "single_value_type": undefined,
        "single_value": undefined,
        "multiple_value_type": undefined,
        "multiple_values": undefined,
        "interval": undefined,
        "duration": undefined,
        "probability": undefined,
        "radius": undefined,
        "powerup_single_value_type": undefined,
        "powerup_single_value": undefined,
        "powerup_multiple_value_type": undefined,
        "powerup_multiple_value": undefined,
        "powerup_interval_value_type": undefined,
        "powerup_interval_value": undefined,
        "powerup_duration_value_type": undefined,
        "powerup_duration_value": undefined,
        "powerup_probability_value_type": undefined,
        "powerup_probability_value": undefined,

        "upgrade_single_value_type": undefined,
        "upgrade_single_value": undefined,
        "upgrade_multiple_value_type": undefined,
        "upgrade_multiple_value": undefined,
        "upgrade_interval_value_type": undefined,
        "upgrade_interval_value": undefined,
        "upgrade_duration_value_type": undefined,
        "upgrade_duration_value": undefined,
        "upgrade_probability_value_type": undefined,
        "upgrade_probability_value": undefined,

        "merge_single_value_type": undefined,
        "merge_single_value": undefined,
        "merge_multiple_value_type": undefined,
        "merge_multiple_value": undefined,
        "merge_interval_value_type": undefined,
        "merge_interval_value": undefined,
        "merge_duration_value_type": undefined,
        "merge_duration_value": undefined,
        "merge_probability_value_type": undefined,
        "merge_probability_value": undefined,
    },
    "effect2": {
        "name": undefined,
        "type": undefined,
        "single_value_type": undefined,
        "single_value": undefined,
        "multiple_value_type": undefined,
        "multiple_values": undefined,
        "interval": undefined,
        "duration": undefined,
        "probability": undefined,
        "radius": undefined,
        "powerup_single_value_type": undefined,
        "powerup_single_value": undefined,
        "powerup_multiple_value_type": undefined,
        "powerup_multiple_value": undefined,
        "powerup_interval_value_type": undefined,
        "powerup_interval_value": undefined,
        "powerup_duration_value_type": undefined,
        "powerup_duration_value": undefined,
        "powerup_probability_value_type": undefined,
        "powerup_probability_value": undefined,

        "upgrade_single_value_type": undefined,
        "upgrade_single_value": undefined,
        "upgrade_multiple_value_type": undefined,
        "upgrade_multiple_value": undefined,
        "upgrade_interval_value_type": undefined,
        "upgrade_interval_value": undefined,
        "upgrade_duration_value_type": undefined,
        "upgrade_duration_value": undefined,
        "upgrade_probability_value_type": undefined,
        "upgrade_probability_value": undefined,

        "merge_single_value_type": undefined,
        "merge_single_value": undefined,
        "merge_multiple_value_type": undefined,
        "merge_multiple_value": undefined,
        "merge_interval_value_type": undefined,
        "merge_interval_value": undefined,
        "merge_duration_value_type": undefined,
        "merge_duration_value": undefined,
        "merge_probability_value_type": undefined,
        "merge_probability_value": undefined,
    },
    "coop": {
        "base_action": {
            "name": undefined,
            "type": undefined,
            "single_value_type": undefined,
            "single_value": undefined,
            "multiple_value_type": undefined,
            "multiple_values": undefined,
            "interval": undefined,
            "duration": undefined,
            "probability": undefined,
            "radius": undefined,
            "powerup_single_value_type": undefined,
            "powerup_single_value": undefined,
            "powerup_multiple_value_type": undefined,
            "powerup_multiple_value": undefined,
            "powerup_interval_value_type": undefined,
            "powerup_interval_value": undefined,
            "powerup_duration_value_type": undefined,
            "powerup_duration_value": undefined,
            "powerup_probability_value_type": undefined,
            "powerup_probability_value": undefined,

            "upgrade_single_value_type": undefined,
            "upgrade_single_value": undefined,
            "upgrade_multiple_value_type": undefined,
            "upgrade_multiple_value": undefined,
            "upgrade_interval_value_type": undefined,
            "upgrade_interval_value": undefined,
            "upgrade_duration_value_type": undefined,
            "upgrade_duration_value": undefined,
            "upgrade_probability_value_type": undefined,
            "upgrade_probability_value": undefined,

            "merge_single_value_type": undefined,
            "merge_single_value": undefined,
            "merge_multiple_value_type": undefined,
            "merge_multiple_value": undefined,
            "merge_interval_value_type": undefined,
            "merge_interval_value": undefined,
            "merge_duration_value_type": undefined,
            "merge_duration_value": undefined,
            "merge_probability_value_type": undefined,
            "merge_probability_value": undefined,
        },
        "effect1": {
            "name": undefined,
            "type": undefined,
            "single_value_type": undefined,
            "single_value": undefined,
            "multiple_value_type": undefined,
            "multiple_values": undefined,
            "interval": undefined,
            "duration": undefined,
            "probability": undefined,
            "radius": undefined,
            "powerup_single_value_type": undefined,
            "powerup_single_value": undefined,
            "powerup_multiple_value_type": undefined,
            "powerup_multiple_value": undefined,
            "powerup_interval_value_type": undefined,
            "powerup_interval_value": undefined,
            "powerup_duration_value_type": undefined,
            "powerup_duration_value": undefined,
            "powerup_probability_value_type": undefined,
            "powerup_probability_value": undefined,

            "upgrade_single_value_type": undefined,
            "upgrade_single_value": undefined,
            "upgrade_multiple_value_type": undefined,
            "upgrade_multiple_value": undefined,
            "upgrade_interval_value_type": undefined,
            "upgrade_interval_value": undefined,
            "upgrade_duration_value_type": undefined,
            "upgrade_duration_value": undefined,
            "upgrade_probability_value_type": undefined,
            "upgrade_probability_value": undefined,

            "merge_single_value_type": undefined,
            "merge_single_value": undefined,
            "merge_multiple_value_type": undefined,
            "merge_multiple_value": undefined,
            "merge_interval_value_type": undefined,
            "merge_interval_value": undefined,
            "merge_duration_value_type": undefined,
            "merge_duration_value": undefined,
            "merge_probability_value_type": undefined,
            "merge_probability_value": undefined,
        },
        "effect2": {
            "name": undefined,
            "type": undefined,
            "single_value_type": undefined,
            "single_value": undefined,
            "multiple_value_type": undefined,
            "multiple_values": undefined,
            "interval": undefined,
            "duration": undefined,
            "probability": undefined,
            "radius": undefined,
            "powerup_single_value_type": undefined,
            "powerup_single_value": undefined,
            "powerup_multiple_value_type": undefined,
            "powerup_multiple_value": undefined,
            "powerup_interval_value_type": undefined,
            "powerup_interval_value": undefined,
            "powerup_duration_value_type": undefined,
            "powerup_duration_value": undefined,
            "powerup_probability_value_type": undefined,
            "powerup_probability_value": undefined,

            "upgrade_single_value_type": undefined,
            "upgrade_single_value": undefined,
            "upgrade_multiple_value_type": undefined,
            "upgrade_multiple_value": undefined,
            "upgrade_interval_value_type": undefined,
            "upgrade_interval_value": undefined,
            "upgrade_duration_value_type": undefined,
            "upgrade_duration_value": undefined,
            "upgrade_probability_value_type": undefined,
            "upgrade_probability_value": undefined,

            "merge_single_value_type": undefined,
            "merge_single_value": undefined,
            "merge_multiple_value_type": undefined,
            "merge_multiple_value": undefined,
            "merge_interval_value_type": undefined,
            "merge_interval_value": undefined,
            "merge_duration_value_type": undefined,
            "merge_duration_value": undefined,
            "merge_probability_value_type": undefined,
            "merge_probability_value": undefined,
        }
    }
}