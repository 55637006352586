<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{$t('SPECIAL_OFFERS')}}</h1>
      <button @click="localizationCSV_Export" class="success">{{ $t('EXPORT CSV') }}</button>
    </div>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ $t('TITLE') }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.EXPIRES_IN') }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.PRICE') }}</div>
          <div class="cell action-cell"><a href="/special-offers/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(promotion, index) in promotionsArr" v-bind:key="promotion.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ promotion.id }}</div>
          <div class="cell">{{ promotion.title }}</div>
          <div class="cell">{{ promotion.expires_in / (24*60*60*1000) }}</div>
          <div class="cell">{{ promotion.price }}</div>
          <div class="cell action-cell">
            <a :href="'/special-offers/add?id=' + promotion.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deletePromotion(promotion.id)" class="error" :disabled="promotion.is_active">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import specialOfferSchema from "@/schemas/specialOffer";
import csvUtil from "@/util/csvBuilder"

export default {
  name: "SpecialOffers",
  setup() {
    const store = useStore();
    const promotionsArr = ref([])
    watch(() => store.getters['promotions/getPromotions'], value => {
      promotionsArr.value = [ ...value ].filter(x => x.type === 1)
    }, { immediate: true })

    const localizationCSV_Export = () => {
      csvUtil.buildExportCsv("special_offers", specialOfferSchema().localizables, promotionsArr.value)
    }

    const deletePromotion = id => {
      store.dispatch('promotions/deletePromotion', { id })
    }
    return {
      promotionsArr,
      moment,
      deletePromotion,
      localizationCSV_Export
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
  text-align: start;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}

button, a {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  flex-grow: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.action-cell {
  display: flex;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>