<template>
  <div class="image-gallery">
    <template v-if="assetList">
      <div v-for="asset in assetList" v-bind:key="asset.doc_ref" class="image-thumbnail-container" @click="openPopup(asset)">
<!--        <image-with-cookie class="image-thumbnail" :cookie="cookie" :url="asset.url" />-->
        <img class="image-thumbnail" :src="asset.url + cdnQuery" />
        <p class="thumbnail-title">{{ asset.doc_ref }}</p>
      </div>
    </template>
  </div>
  <div v-if="popupAsset" class="popup-wrapper" :class="popupActive ? 'active' : ''">
    <div class="popup-overlay"  @click="closePopup"></div>
    <div class="popup-container">
      <h2>{{ popupAsset.doc_ref }}</h2>
<!--      <image-with-cookie class="popup-image" :cookie="cookie" :url="popupAsset.url" />-->
      <img class="popup-image" :src="popupAsset.url + cdnQuery" />

      <div class="popup-controls">
        <button v-if="!confirmationActive" class="error" @click="toggleConfirmation">{{ $t("REMOVE" )}}</button>
        <div v-else class="popup-confirmation">
          <span class="confirmation-warning">Are you sure to delete this asset?</span>
          <button class="success" @click="toggleConfirmation">No, don't delete</button>
          <button class="error" @click="deleteAsset">Yes, delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "AssetGallery",
  props: [ "assetList", "cdnQuery" ],

  setup() {
    const store = useStore();
    const popupActive = ref(false);
    const popupAsset = ref(null);

    const openPopup = asset => {
      popupAsset.value = asset;
      popupActive.value = true;
    }

    const closePopup = () => {
      popupActive.value = false;
      confirmationActive.value = false;
    }

    const confirmationActive = ref(false);
    const toggleConfirmation = () => confirmationActive.value = !confirmationActive.value;

    const deleteAsset = () => {
      //TODO: Delete activeAsset
      store.dispatch("assets/deleteAsset", popupAsset.value)
      closePopup()
    }



    return {
      popupActive,
      popupAsset,
      openPopup,
      closePopup,
      confirmationActive,
      toggleConfirmation,
      deleteAsset
    }

  }
}
</script>

<style scoped>
  .image-gallery {
    width: 100%;
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .image-thumbnail-container {
    margin: 5px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid black;
    overflow: hidden;
    cursor: pointer;
  }

  .image-thumbnail {
    height: 70px;
  }

  .thumbnail-title {
    height: 20px;
    margin: 0;
  }

  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
  }

  .popup-wrapper.active {
    display: flex;
  }

  .popup-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .popup-container {
    z-index: 1;
    width: 40%;
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-image {
    width: 100%;
  }

  button.error {
    background-color: rgba(255, 0, 0, 0.15);
  }

  button.success {
    background-color: rgba(0, 255, 0, 0.15);
  }

  button.info {
    background-color: rgba(0, 0, 255, 0.15);
  }

  button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

</style>