export default {
    REWARD_TYPES: [
        {
            text: 'GOLD',
            value: 0
        },
        {
            text: 'GEM',
            value: 1
        },
        {
            text: 'TOKEN',
            value: 2
        },
        {
            text: 'CHEST',
            value: 3
        },
        {
            text: 'HERO',
            value: 4
        },
        {
            text: 'UNIT',
            value: 5
        },
        {
            text: 'RANDOM_UNIT',
            value: 6
        }
    ],
    CARD_REWARD_TYPES: [
        {
            text: 'RANDOM',
            value: 0
        },
        {
            text: 'SPECIFIC',
            value: 1
        }
    ],
    CARD_OWNERSHIP_TYPES: [
        {
            text: "BOTH",
            value: 0
        },
        {
            text: "OWNED",
            value: 1
        },
        {
            text: "NOT_OWNED",
            value: 2
        }
    ],
    CARD_RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    CONDITION_TYPES: [
        {
            text: "TROPHY_BASED",
            value: 0
        }
    ],
    CONDITION_OPERATORS: [
        [
            {
                text: 'GREATER_OR_EQUAL_TO',
                value: ">="
            },
            {
                text: 'GREATER_THAN',
                value: ">"
            },
            {
                text: 'EQUAL_TO',
                value: '=='
            },
            {
                text: "LESS_THAN",
                value: "<"
            },
            {
                text: "LESS_THAN_OR_EQUAL_TO",
                value: "<="
            }
        ]
    ],
    REWARD_CLAIM_TYPES: [
        {
            text: 'IMMEDIATE',
            value: 0
        },
        {
            text: 'DAILY',
            value: 1
        }
    ],
    REWARD_DAY_OPTIONS: [
        {
            text: 'IMMEDIATE (0 days)',
            value: 0
        },
        {
            text: '1 days',
            value: 1
        },
        {
            text: '2 days',
            value: 2
        },
        {
            text: '3 days',
            value: 3
        },
        {
            text: '4 days',
            value: 4
        },
        {
            text: '5 days',
            value: 5
        }
    ]
}