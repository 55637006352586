export default {
    PERMISSION_TYPES: [
        "assets",
        "units",
        "heroes",
        "bosses",
        "spawner",
        "config",
        "users",
        "news",
        "mails",
        "shop",
        "promotions",
        "specialOffers",
        "logs",
        "monitoring",
        "botDecks",
        "mirrorDecks",
        "gameModeDecks",
        "wiki",
        "rewards",
        "tips",
        "season",
        "seasonContent",
        "leaderboard",
        "mockUsers",
        "trophyRoad",
        "events",
        "localization",
        "gameLogs",
        "promoCodes"
    ]
}