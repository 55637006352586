<template>
  <div class="table">
    <div class="row row-header">
      <div v-for="column in columns" v-bind:key="column.field" class="cell cell-header" :style="{ width: (100 / columns.length) + '%', maxWidth: (100 / columns.length) + '%'}" @click="changeSortField(column.field)">
          {{ $t(column.text) + (sortField === column.field ? ' ' + (isDesc ? '&#8595;' : '&#8593;') : '') }}
      </div>
    </div>
    <div class="row row-search">
      <div v-for="column in columns" v-bind:key="column.field" class="cell" :style="{ width: (100 / columns.length) + '%', maxWidth: (100 / columns.length) + '%'}" >
        <template v-if="column.filterable">
          <input v-if="column.type === 'number'" v-model.number="filters[column.field]" type="number" placeholder="Type to search field" />
          <input v-else v-model="filters[column.field]" placeholder="Type to search field" />
        </template>
        <span v-else>Column is not filterable</span>
      </div>
    </div>
    <div v-for="row in rows" class="row" v-bind:key="row">
      <div v-for="column in columns" v-bind:key="column.key + row" class="cell" :style="{ width: (100 / columns.length) + '%', maxWidth: (100 / columns.length) + '%'}">
        {{ column.type === 'date' ? moment(row[column.field]).format('DD/MM/YYYY HH:mm') : row[column.field] }}
      </div>
    </div>
    <div class="pagination">
      <button class="pagination-navigation-button" @click="prevPage" :disabled="pageIndex === 0">
          {{ "Prev" }}
      </button>
      <div class="pagination-cursor">
        {{ pageIndex + 1 }}
      </div>
      <button class="pagination-navigation-button" @click="nextPage" :disabled="rows.length < pageSize">
          {{ "Next" }}
      </button>
      <button class="pagination-navigation-button" @click="applySearch">
        {{ "Apply Filters" }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useI18n } from 'vue-i18n'

export default {
  name: "Table",
  props: [
      "columns",
      "serverEndpoint",
      "pageSize",
      "initialSortField"
  ],
  setup(props) {
    const { t } = useI18n();
    const pageIndex = ref(0);
    const rows = ref([]);
    const filters = reactive({});
    const sortField = ref(props.initialSortField);
    const isDesc = ref(true);
    let anchorPoint = 0;

    onMounted(() => {
      getRows()
    })

    const prepareFilters = () => {
      Object.keys(filters).forEach(key => {
        if(filters[key] === '' || filters[key] === null) {
          delete filters[key]
        }
      })
    }

    const getRows = async (isForward, changedSortField) => {
      prepareFilters()
      const newRows = await axios.post(props.serverEndpoint, {
        order_by: sortField.value,
        anchor_point: anchorPoint,
        page_length: props.pageSize,
        is_forward: isForward,
        is_desc: isDesc.value,
        filters: filters
      }).then(res => {
        console.log(res)
        return res.data
      })
      if(newRows.length > 0 && (changedSortField || rows.value.length === 0 || newRows[0].id !== rows.value[0].id)) {
        rows.value = newRows;
        pageIndex.value = Math.max(0, pageIndex.value + (isForward ? 1 : -1));
        if(isForward) {
          anchorPoint = rows.value[rows.value.length - 1].id
        } else {
          anchorPoint = rows.value[0].id
        }
      } else if(newRows.length === 0) {
        notify({
          title: t('ERROR'),
          text: t('NOTIF.ERROR.NO_RESULTS_FOUND'),
          type: 'error'
        })
      }

    }

    const nextPage = () => {
        getRows(true);
    }

    const prevPage = () => {
      getRows(false);
    }

    const changeSortField = field => {
      if(sortField.value === field) {
        isDesc.value = !isDesc.value;
      } else {
        isDesc.value = false;
      }
      anchorPoint = undefined;
      sortField.value = field;
      pageIndex.value = 0;
      getRows(false, true);
    }

    const applySearch = () => {
      pageIndex.value = 0;
      anchorPoint = undefined;
      sortField.value = props.initialSortField;
      getRows(false, true)
    }

    return {
      pageIndex,
      rows,
      filters,
      nextPage,
      prevPage,
      changeSortField,
      applySearch,
      sortField,
      isDesc,
      moment
    }
  }

}
</script>

<style scoped>
.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 50px;
  overflow: hidden;
}
.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border: 2px solid black;
}

.cell {
  border: 1px solid black;
  padding: 5px 0;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 15px;
}

.pagination-navigation-button {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid gray;
  cursor: pointer;
  margin-right: 5px;
}

.pagination-navigation-button:disabled, .pagination-navigation-button[disabled] {
  cursor: default;
}

.pagination-cursor {
  padding: 5px 10px;
  font-weight: 600;
}

.row.row-search {
  background-color: rgba(0,0,0,0.1);
}

.row input {
  font-size: 0.85rem!important;
  width: 95%;
}
</style>