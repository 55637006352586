<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('HERO')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.NAME`) }}</p>
            <input v-model="currentHero.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.DESC`) }}</p>
            <textarea v-model="currentHero.description"/>
          </div>
          <div class="form-container">
            <select v-model="selectedLanguage">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Localized Name" }}</p>
            <input v-model="currentHero.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ "Localized Description" }}</p>
            <textarea v-model="currentHero.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.COOLDOWN`) }}</p>
            <textarea v-model.number="currentHero.cooldown"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.TROPHY_LEVEL`) }}</p>
            <input type="number" v-model.number="currentHero.trophy_level"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.RARITY`) }}</p>
            <select v-model.number="currentHero.rarity">
              <option v-for="rarity in heroConstants.RARITY" v-bind:key="rarity.value" :value="rarity.value">{{ $t('HERO_VALUES.' + rarity.text) }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.INDEX`) }}</p>
            <input v-model.number="currentHero.index"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.GAME_STATUS`) }}</p>
            <select v-model.number="currentHero.status">
              <option v-for="gameStatus in heroConstants.GAME_STATUS" v-bind:key="gameStatus.value" :value="gameStatus.value">{{ $t('HERO_VALUES.' + gameStatus.text) }}</option>
            </select>
          </div>
        </div>
        <div class="col" v-for="actionName in ['effect1', 'effect2']" v-bind:key="actionName">
          <div class="col-title">{{ $t(`HERO_VALUES.${actionName}`) }}</div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.NAME`) }}</p>
            <input v-model="currentHero[actionName].name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.DURATION`) }}</p>
            <input type="number" v-model.number="currentHero[actionName].duration"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.VALUE`) }}</p>
            <select v-model.number="currentHero[actionName].single_value_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero[actionName].value" />
          </div>

        </div>
      </div>
      <div class="second-section">
        <div class="col">
          <div class="col-title">{{ $t('HERO_VALUES.UPGRADE') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.BASE_ATTRIBUTES') }}</div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.COOLDOWN_UPGRADE`) }}</p>
            <select v-model.number="currentHero.upgrade_cooldown_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero.upgrade_cooldown" />
          </div>
        </div>
        <div class="col">
          <div class="col-title">{{ $t('HERO_VALUES.UPGRADE') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.EFFECT_1') }}</div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.DURATION_UPGRADE`) }}</p>
            <select v-model.number="currentHero.effect1.duration_upgrade_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero.effect1.duration_upgrade" />
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.VALUE_UPGRADE`) }}</p>
            <select v-model.number="currentHero.effect1.value_upgrade_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero.effect1.value_upgrade" />
          </div>
        </div>
        <div class="col">
          <div class="col-title">{{ $t('HERO_VALUES.UPGRADE') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.EFFECT_2') }}</div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.DURATION_UPGRADE`) }}</p>
            <select v-model.number="currentHero.effect2.duration_upgrade_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero.effect2.duration_upgrade" />
          </div>
          <div class="form-container">
            <p>{{ $t(`HERO_VALUES.VALUE_UPGRADE`) }}</p>
            <select v-model.number="currentHero.effect2.value_upgrade_type">
              <option v-for="option in heroConstants.VALUE_TYPE" v-bind:key="option.value" :value="option.value">{{ $t('HERO_VALUES.' + option.text) }}</option>
            </select>
            <input type="number" v-model.number="currentHero.effect2.value_upgrade" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-title">{{ $t('HERO_VALUES.INFO_LIST') }}</div>
        <div class="third-section">
          <div class="col-subtitle">{{ $t('HERO_VALUES.NAME') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.CATEGORY') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.FIELD_VALUE') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.ICON') }}</div>
          <div class="col-subtitle">{{ $t('HERO_VALUES.ACTIONS') }}</div>
        </div>
        <div class="third-section" v-for="(info, index) in currentHero.info_fields || []" v-bind:key="index">
          <input v-model="currentHero.info_fields[index].name" />
          <select v-model.number="currentHero.info_fields[index].category">
            <option v-for="category in ['effect1', 'effect2']" v-bind:key="category" :value="category">{{ $t('HERO_VALUES.' + category) }}</option>
          </select>
          <select v-model.number="currentHero.info_fields[index].fieldValue">
            <option v-for="fieldValue in heroConstants.FIELD_VALUES" v-bind:key="fieldValue.value" :value="fieldValue.value">{{ $t('HERO_VALUES.' + fieldValue.text) }}</option>
          </select>
          <select v-model.number="currentHero.info_fields[index].icon">
            <option v-for="icon in heroConstants.ICONS" v-bind:key="icon.value" :value="icon.value">{{ $t('HERO_VALUES.' + icon.text) }}</option>
          </select>
          <div><button @click="currentHero.info_fields.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button></div>
        </div>
        <div class="col">
          <button @click="addInfoField" class="success">{{ $t('HERO_VALUES.ADD_INFO_FIELD') }}</button>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import languageConstants from "@/constants/languagesTemp";
import heroConstants from "@/constants/heroConstants";
import heroSchema from "@/schemas/hero";

export default {
  name: "Hero",

  
  setup() {
    const store = useStore();
    const heroes = computed(() => store.getters['heroes/getHeroes'])
    const currentHero = reactive({ ...heroSchema })
    const route = useRoute();
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    onMounted(() => {
      if(route.query.id && heroes.value.length > 0) {
        const editingHero = JSON.parse(JSON.stringify(heroes.value.find(x => x.id === route.query.id)))
        Object.keys(editingHero).forEach(key => {
          currentHero[key] = editingHero[key]
        })
      }
    })

    watch(() => store.getters['heroes/getHeroes'], heroes => {
      if(route.query.id && heroes.length > 0) {
        const editingHero = JSON.parse(JSON.stringify(heroes.find(x => x.id === route.query.id)))
        Object.keys(editingHero).forEach(key => {
          currentHero[key] = editingHero[key]
        })
        if (!currentHero.localizables || !currentHero.localizables.name || !currentHero.localizables.description) {
          currentHero.localizables = { description: {}, name: {} }
        }
      }
    })

    const removeMultipleValue = (actionName, index) => {
      const newArr = []
      currentHero[actionName].multiple_values.forEach((val, i) => {
        if(i !== index) {
          newArr.push(val)
        }
      })
      currentHero[actionName].multiple_values = newArr
    }

    const addMultipleValue = actionName => {
      const newArr = [ ...(currentHero[actionName].multiple_values || [])]
      newArr.push(0)
      currentHero[actionName].multiple_values = newArr
    }

    const addInfoField = () => {
      const newArray = []
      if(currentHero.info_fields) {
        currentHero.info_fields.forEach(field => newArray.push(field))
      }
      newArray.push({
        name: '',
        category: 0,
        fieldValue: 'single_value',
        icon: 0
      })
      currentHero.info_fields = newArray
    }

    const prepareInfoList = () => {
      currentHero.info_fields = currentHero.info_fields.map(field => {
        return {
          specVal: currentHero[field.category][field.fieldValue],
          specValType: currentHero[field.category][field.fieldValue + '_type'],
          upgradeVal: currentHero[field.category][field.fieldValue + '_upgrade'],
          upgradeValType: currentHero[field.category][field.fieldValue + '_upgrade_type'],
          name: field.name,
          category: field.category,
          fieldValue: field.fieldValue,
          icon: field.icon
        }
      })
    }

    const submit = () => {
      prepareInfoList()
      if(route.query.id) {
        store.dispatch('heroes/updateHero', { id: route.query.id, data: currentHero })
      } else {
        store.dispatch('heroes/addHero', { data: currentHero })
      }
    }

    const exportTo = environment => {
      prepareInfoList()
        store.dispatch('heroes/exportHero', { id: route.query.id, data: currentHero, environment })
    }

    const env = process.env.ENV;

    return {
      heroConstants,
      currentHero,
      removeMultipleValue,
      addMultipleValue,
      addInfoField,
      submit,
      exportTo,
      env,
      selectedLanguage,
      languages
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
}
p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

</style>