export default {
    seasonShowFields: [
        "activation_date",
        "expire_date",
        "id",
        "name",
        "season_number",
        "main_menu_banner_asset",
        "inside_banner_asset",
        "level_cap",
        "is_leaderboard_active"
    ],
    seasonUpdateFields: [
        "name",
        "season_number",
        "main_menu_banner_asset",
        "inside_banner_asset",
        "activation_date",
        "expire_date",
        "level_cap",
        "is_leaderboard_active"
    ],
    SEASON_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: 6,
            text: "EMOJI"
        },
        {
            value: 10,
            text: "UNIT"
        },
        {
            value: 11,
            text: "HERO"
        },
    ],
    RARITIES: [
        {
            value: 0,
            text: 'COMMON'
        },
        {
            value: 1,
            text: 'RARE'
        },
        {
            value: 2,
            text: 'EPIC'
        },
        {
            value: 3,
            text: 'LEGENDARY'
        },
    ],
}