export default {
    EVENT_TYPES: [
        {
            value: 0,
            text: "MIRROR_MATCH"
        },
        {
            value: 1,
            text: "GAME_MODE"
        }
    ],
    GAME_MODE_MODIFIER_TYPES: [
        {
            value: 0,
            text: "REGULAR"
        },
        {
            value: 1,
            text: "DOUBLE_MANA"
        },
        {
            value: 2,
            text: "SUDDEN_DEATH"
        },
        {
            value: 3,
            text: "MONSTER_RUSH"
        },
    ],
    EVENT_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: 3,
            text: "UNIT"
        }
    ],
    EVENT_LEADERBOARD_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: 6,
            text: "EMOJI"
        }
    ]
}