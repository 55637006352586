export default {
    ARENAS: [
        {
            text: 'Arena 0',
            value: -1
        },
        {
            text: 'Arena 1',
            value: 0
        },
        {
            text: 'Arena 2',
            value: 1
        },
        {
            text: 'Arena 3',
            value: 2
        },
        {
            text: 'Arena 4',
            value: 3
        },
        {
            text: 'Arena 5',
            value: 4
        },
        {
            text: 'Arena 6',
            value: 5
        },
        {
            text: 'League',
            value: 6
        },
    ],
    BOT_LEVEL: [
        {
            text: 'BEGINNER',
            value: 0
        },
        {
            text: 'PRE_INTER',
            value: 1
        },
        {
            text: 'REGULAR',
            value: 3
        },
        {
            text: 'ADVANCED',
            value: 4
        },
        {
            text: 'VETERAN',
            value: 2
        },
        {
            text: 'HARD_BEGINNER',
            value: 5
        }
    ],
    RARITY_MAP: {
        0: 'Common',
        1: 'Rare',
        2: 'Epic',
        3: 'Legendary'
    },
    STATUS: [
        {
            text: "DISABLED",
            value: 0
        },
        {
            text: "ENABLED",
            value: 1
        }
    ]
}