<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('BOSS')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.NAME`) }}</p>
            <input v-model="currentBoss.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.DESC`) }}</p>
            <textarea v-model="currentBoss.description"/>
          </div>
          <div>
            <select v-model="selectedLanguage">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Localized Name" }}</p>
            <input v-model="currentBoss.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ "Localized Description" }}</p>
            <textarea v-model="currentBoss.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.INDEX`) }}</p>
            <input v-model.number="currentBoss.index"/>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import bossSchema from "@/schemas/boss";
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "Boss",
  setup() {
    const store = useStore();
    const bosses = computed(() => store.getters['bosses/getBosses'])
    const currentBoss = reactive({ ...bossSchema })
    const route = useRoute();
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    onMounted(() => {
      if(route.query.id) {
        const editingBoss = JSON.parse(JSON.stringify(bosses.value.find(x => x.id === route.query.id)))
        Object.keys(editingBoss).forEach(key => {
          currentBoss[key] = editingBoss[key]
        })
      }
    })

    watch(() => store.getters['bosses/getBosses'], bosses => {
      if(route.query.id && bosses.length > 0) {
        const editingBoss = JSON.parse(JSON.stringify(bosses.find(x => x.id === route.query.id)))
        Object.keys(editingBoss).forEach(key => {
          currentBoss[key] = editingBoss[key]
        })
        if (!currentBoss.localizables) {
          currentBoss.localizables = { description: {}, name: {} }
        }
        if (!currentBoss.localizables.name){
          currentBoss.localizables.name = {}
        }
        if (!currentBoss.localizables.description)
          currentBoss.localizables.description = {}
      }
    })

    const submit = () => {
      if(route.query.id) {
        store.dispatch('bosses/updateBoss', { id: route.query.id, data: currentBoss })
      } else {
        store.dispatch('bosses/addBoss', { data: currentBoss })
      }
    }

    const exportTo = environment => {
      store.dispatch('bosses/exportBoss', { id: route.query.id, data: currentBoss, environment })
    }

    const env = process.env.ENV;

    return {
      currentBoss,
      submit,
      exportTo,
      env,
      selectedLanguage,
      languages
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

</style>