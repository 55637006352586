<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{'Localization'}}</h1>
    </div>
    <div class="first-section">
        <div class="col">
            <div class="form-field">
                <input type="file" ref="file" accept=".csv" @change="onFileChanged" />
            </div>
            <div class="form-field-row" v-if="state.content != ''">
                <h4>File Status:</h4>
                <div v-if="state.isFileValid" class="status-circle-success"></div>
                <div v-else class="status-circle-fail"></div>
            </div>
            <div class="form-field" v-if="state.content != ''">
                <h4>Import to</h4>
                <select v-model="state.selectedObject">
                    <option v-for="option in localizables" :value="option" v-bind:key="option">{{ option }}</option>
                </select>
            </div>
            <div class="form-field">
                <h4 v-if="state.isFileValid">Languages Found in File</h4>
                <div v-for="(language, index) in state.foundLanguages" v-bind:key="index">
                    <input type="checkbox" :id="language" v-model="state.selectedLanguages[language]">
                    <label :for="language">{{ languageData.find(x => x.code === language)?.name }}</label>
                </div>
            </div>
            <div class="form-field-row" v-if="state.isFileValid">
                <input type="checkbox" id="override" v-model="state.overrideValues">
                <label for="override">{{ "Override existing values" }}</label>
            </div>
            <div class="form-field">
                <button @click="submit" :disabled="state.importStatus === 1 || !state.isFileValid || state.selectedObject === null" class="">{{ "Start Import" }}</button>
            </div>
            <div class="form-field" v-if="state.importStatus !== 0">
                <p v-if="state.importStatus === 1">{{ "Importing objects. Remaining: " + remainingObjects }}</p>
                <p v-if="state.importStatus === 2">{{ "Import completed. " + failedObjects + " objects failed" }}</p>
            </div>
        </div>
        <div class="col">
            <div class="col-title">{{ "ATTENTION" }}</div>
            <ul>
                <li>Make sure you import to correct object in db.</li>
                <li>If you only want to update missing fields, untick 'Override existing values'</li>
                <li>Double check file you select before start import process</li>
                <li><b>IMPORTANT!!</b> Import process may take several seconds. DO NOT close panel during import process, you may corrupt game data.</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import csvUtil from "@/util/csvBuilder"
import langConstants from "@/constants/languagesTemp"

export default {
    name: "Localization",
    methods: {
        async onFileChanged() {
            let file = this.$refs.file.files[0]
            if (!file) return;

            let reader = new FileReader();
            reader.readAsText(file, 'UTF-8')

            reader.onload =  evt => {
                let text = evt.target.result;
                this.state.content = text
                csvUtil.csvToJson(text).then(result => {
                    this.state.importedData = result.result
                    this.state.foundLanguages = result.headers.filter(x => x != 'object ID' && x != 'field' && x != 'default value')
                    this.state.isFileValid = true
                })
            }
            
            reader.onerror = evt => {
                console.error(evt);
            }
        },
    },
    setup() {
        const store = useStore();

        const importStatus = {
            NOT_STARTED: 0,
            IMPORTING: 1,
            IMPORT_COMPLETED: 2
        }

        const state = reactive({
            content: "",
            isFileValid: false,
            foundLanguages: [],
            selectedLanguages: {},
            importedData: null,
            selectedObject: null,
            overrideValues: false,
            importStatus: importStatus.NOT_STARTED
        })

        const remainingObjects = ref(0)
        const failedObjects = ref(0)

        const buildRequests = (dbRef) => {
            
            if (state.selectedObject === 'shop') {
                dbRef = JSON.parse(JSON.stringify(dbRef['arena_packs']))
                dbRef = Object.keys(dbRef).map(x => {
                    return {
                        id: x,
                        ...dbRef[x]
                    }
                })
            }

            let langs = Object.keys(state.selectedLanguages).filter(x => state.selectedLanguages[x])
            const result = {};

            state.importedData.forEach(element => {
                if (!result[element['object id']])
                    result[element['object id']] = JSON.parse(JSON.stringify(dbRef.find(x => x.id === element['object id'])?.localizables || {} )) //eslint-disable-line
            });

            state.importedData.forEach(element => {
                if (!result[element['object id']][element.field])
                    result[element['object id']][element.field] = {}
                langs.forEach(lang => {
                    if (!result[element['object id']][element.field][lang] || result[element['object id']][element.field][lang] == '' || state.overrideValues) {
                        if (element[lang] && element[lang] != '')
                            result[element['object id']][element.field][lang] = element[lang]
                    }
                });
            });

            return result
        }


        const submit = async () => {
            let dispatchTrigger;
            let dispatchGetter;
            let dbObjects;

            switch (state.selectedObject) {
                case "shop": {
                    dispatchGetter = 'shop/getShopContent'
                    dispatchTrigger = 'shop/updateShopContent'
                    dbObjects = store.getters['shop/getShopContent']
                    break
                }
                case "special-offers": {
                    dispatchGetter = 'promotions/getPromotions'
                    dispatchTrigger = 'promotions/updatePromotionNoResync'
                    dbObjects = store.getters['promotions/getPromotions']
                    break
                }
                case "promotions": {
                    dispatchGetter = 'promotions/getPromotions'
                    dispatchTrigger = 'promotions/updatePromotionNoResync'
                    dbObjects = store.getters['promotions/getPromotions']
                    break
                }
                case "units": {
                    dispatchGetter = 'units/getUnits'
                    dispatchTrigger = 'units/updateUnitNoResync'
                    dbObjects = store.getters['units/getUnits']
                    break
                }
                case "bosses": {
                    dispatchGetter = 'bosses/getBosses'
                    dispatchTrigger = 'bosses/updateBossNoResync'
                    dbObjects = store.getters['bosses/getBosses']
                    break
                }
                case "heroes": {
                    dispatchGetter = 'heroes/getHeroes'
                    dispatchTrigger = 'heroes/updateHeroNoResync'
                    dbObjects = store.getters['heroes/getHeroes']
                    break
                }
            }

            const data = buildRequests(dbObjects)
            sendRequests(dbObjects, data, dispatchTrigger, dispatchGetter)
        }
        
        const sendRequests = async (dbData, data, dispatchTrigger, dispatchGetter) => { 
            const allObjects = Object.keys(data)

            state.importStatus = importStatus.IMPORTING

            failedObjects.value = 0
            if (state.selectedObject === 'shop') {
                remainingObjects.value = 1
                let sendData = JSON.parse(JSON.stringify(dbData))
                for (let i = 0; i < allObjects.length; i++) {
                    sendData['arena_packs'][allObjects[i]].localizables = data[allObjects[i]]
                }
                const result = await store.dispatch(dispatchTrigger, sendData)
                remainingObjects.value -= 1

                if (!result)
                    failedObjects.value += 1

                if (remainingObjects.value === 0) {
                    store.dispatch(dispatchGetter)
                    state.importStatus = importStatus.IMPORT_COMPLETED
                }
                return
            }

            remainingObjects.value = allObjects.length
            for (let i = 0; i < allObjects.length; i++) {
                let sendData = dbData.find(x => x.id === allObjects[i])
                if (!sendData) {
                    failedObjects.value += 1
                    remainingObjects.value -= 1
                } else {
                    sendData = JSON.parse(JSON.stringify(sendData))
                    sendData.localizables = data[allObjects[i]]
                    const result = await store.dispatch(dispatchTrigger, { id: allObjects[i], data: sendData })

                    remainingObjects.value -= 1

                    if (!result)
                        failedObjects.value += 1
                }

                if (remainingObjects.value === 0) {
                    store.dispatch(dispatchGetter)
                    state.importStatus = importStatus.IMPORT_COMPLETED
                }
            }
        }

        return {
            state,
            remainingObjects,
            failedObjects,
            languageData: langConstants.languages,
            localizables: langConstants.localizableObjects,
            submit
        }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-container {
  width: 100%;
}

.form-field {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-field-row {
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}


.status-circle-success {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: green;
}

.status-circle-fail {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: red;
}


button, a {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

button:disabled {
    cursor: default;
    background-color: grey;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>