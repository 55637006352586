<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{$t('UNITS')}}</h1>
      <button @click="() => localizationCSV_Export()" class="success">{{ $t('EXPORT CSV') }}</button>
    </div>
    <div class="content-container">
      <div class="col">
        <div class="col-header">{{ $t('SHOP_VALUES.GOLD_PACKS') }}</div>
        <div class="content-row header-row alternate">
          <div>{{ $t('SHOP_VALUES.AMOUNT') }}</div>
          <div>{{ $t('SHOP_VALUES.GEM_PRICE') }}</div>
          <div>{{ $t('ACTIONS') }}</div>
        </div>
        <div class="content-row" v-for="(goldPack, index) in shopObj.exchange.gold_packs" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''">
          <input v-model.number="goldPack.amount" type="number" />
          <input v-model.number="goldPack.price" type="number" />
          <button class="error" @click="shopObj.exchange.gold_packs.splice(index, 1)">{{ $t('REMOVE') }}</button>
        </div>
        <button @click="() => addItem('gold_packs')">{{ $t('SHOP_VALUES.ADD_PACK') }}</button>
      </div>
      <div class="col">
        <div class="col-header">{{ $t('SHOP_VALUES.GEM_PACKS') }}</div>
        <div class="content-row header-row alternate">
          <div>{{ "Product ID" }}</div>
          <div>{{ $t('SHOP_VALUES.AMOUNT') }}</div>
          <div>{{ $t('SHOP_VALUES.DOLLAR_PRICE') }}</div>
          <div>{{ $t('ACTIONS') }}</div>
        </div>
        <div class="content-row" v-for="(gemPack, index) in shopObj.exchange.gem_packs" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''">
          <input v-model="gemPack.product_id" />
          <input v-model.number="gemPack.amount" type="number" />
          <input v-model.number="gemPack.price" type="number" />
          <button class="error" @click="shopObj.exchange.gem_packs.splice(index,1)">{{ $t('REMOVE') }}</button>
        </div>
        <button @click="() => addItem('gem_packs')">{{ $t('SHOP_VALUES.ADD_PACK') }}</button>
      </div>
    </div>
    <div class="col col-full-width">
      <arena-pack-popup :popup-open="arenaPackPopupOpen" @popup-save="savePopupContents" :content="{ ...shopObj.arena_packs[selectedArenaPackIndex], index: selectedArenaPackIndex, packlist: Object.keys(shopObj.arena_packs) }" @popup-close="arenaPackPopupOpen = false"/>
      <div class="col-header">{{ $t('SHOP_VALUES.ARENA_PACKS') }}</div>
        <div class="table">
          <div class="table-row header-row alternate">
            <div class="cell">
              <h3>{{ $t('ARENA') }}</h3>
            </div>
            <div class="cell">
              <h3>{{ $t('PRICE') }}</h3>
            </div>
            <div class="cell action-cell" @click="() => openArenaPackPopup(0)"><button class="success">{{ $t('ADD') }}</button></div>
          </div>
          <div class="table-row" v-for="(arenaPack, index) in shopObj.arena_packs" v-bind:key="index" :class="index % 2 === 0 ? '' : 'alternate'">
            <div class="cell">{{ index }}</div>
            <div class="cell">{{ arenaPack.price }}</div>
            <div class="cell action-cell">
              <button @click="() => openArenaPackPopup(index)" class="info">{{ $t('EDIT') }}</button>
            </div>
          </div>
      </div>
    </div>
    <div class="buttons-section">
      <button class="success" @click="submit">Save</button>
      <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
  </div>

</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";
import shopSchema from "../../schemas/shop";
import arenaPackSchema from '@/schemas/arenaPack'
import csvUtil from "@/util/csvBuilder"
import ArenaPackPopup from "@/components/ArenaPackPopup";

export default {
  name: "Shop",
  components: {
    'arena-pack-popup': ArenaPackPopup
  },
  setup() {
    const store = useStore();
    const activeArenaPack = ref("2");
    const shopObj = ref({ ...shopSchema })

    watch(() => store.getters['shop/getShopContent'], value => {
      shopObj.value = JSON.parse(JSON.stringify(value))
      activeArenaPack.value = Object.keys(value.arena_packs)[0];
    }, { immediate: true })

    const addItem = packType => {
      if(packType === 'gem_packs') {
        shopObj.value.exchange[packType].push({ amount: 0, price: 0, product_id: ""})
      } else {
        shopObj.value.exchange[packType].push({ amount: 0, price: 0})
      }
    }

    const submit = () => {
     shopObj.value.exchange.gold_packs.sort((a,b) => a.amount - b.amount)
      shopObj.value.exchange.gem_packs.sort((a,b) => a.amount - b.amount)
      store.dispatch('shop/updateShopContent', shopObj.value)
    }

    const exportTo = environment => {
      store.dispatch('shop/exportShopContent', { shopContent: shopObj.value, environment })
    }

    const savePopupContents = (content, arena) => {
      if(arena.editMode) {
        shopObj.value.arena_packs[selectedArenaPackIndex.value] = JSON.parse(JSON.stringify(content));
      } else if(!shopObj.value.arena_packs[arena.arenaNumber]) {
        shopObj.value.arena_packs[arena.arenaNumber] = JSON.parse(JSON.stringify(content));
      }
      console.log(shopObj.value);
      arenaPackPopupOpen.value = false;
      selectedArenaPackIndex.value = 0;
    }

    const env = process.env.ENV;

    const arenaPackPopupOpen = ref(false);
    const selectedArenaPackIndex = ref(0);


    const openArenaPackPopup = index => {
      selectedArenaPackIndex.value = index;
      arenaPackPopupOpen.value = true;
    }

    const localizationCSV_Export = () => {
      csvUtil.buildExportCsv("shop", arenaPackSchema().localizables, Object.keys(shopObj.value.arena_packs).map(x => {
        return {
          ...shopObj.value.arena_packs[x],
          id: x
        }
      }))
    }

    return {
      activeArenaPack,
      shopObj,
      addItem,
      submit,
      exportTo,
      env,
      savePopupContents,
      openArenaPackPopup,
      selectedArenaPackIndex,
      arenaPackPopupOpen,
      localizationCSV_Export
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col {
  padding: 25px 10px;
  border-radius: 5px;
  border: 1px solid black;

}

.col-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 2fr;
  grid-column-gap: 10px;
  align-items: center;
}

.content-row.header-row {
  font-weight: bold;
}

.content-row.alternate {
  background-color: lightgray;
}

.row-title {
  width: 80%;
  text-align: start;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.buttons-section button {
  font-size: 1.25rem;
  margin: 5px 0;
}

.col-full-width {
  width: 100%;
  box-sizing: border-box;
}
.table {
  width: 100%;
}

.table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>