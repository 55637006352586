function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

const getRandomString = (length) => {
    return randomString(length, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ')
}

module.exports = {
    getRandomString
}