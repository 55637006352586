<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('MAIL')}}</h1>
    <div class="content-container">
      <div class="col">
        <div class="form-container">
            <p>{{ "Language" }}</p>
            <select v-model="selectedLanguage" @change="languageChanged($event)">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
        <div class="form-container">
          <p>{{ $t(`MAIL_VALUES.TITLE`) }}</p>
          <input v-model="currentMail.title"/>
        </div>
        <div class="form-container">
          <p>{{ "Localized Title" }}</p>
          <input v-model="currentMail.localizables.title[selectedLanguage]"/>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.CREATION_DATE`) }}</p>
          <DatePicker
              v-model="currentMail.creation_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.EXPIRE_DATE`) }}</p>
          <DatePicker
              v-model="currentMail.expiration_date"
              mode="dateTime"
              :min-date="Date.now()"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="expire_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container">
          <p>{{ $t(`MAIL_VALUES.BANNER_ASSET`) }}</p>
          <select v-model="currentMail.banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.MAIL_TYPE`) }}</p>
          <select v-model.number="currentMail.type">
            <option v-for="option in mailConstants.MAIL_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.CONDITION_TYPE`) }}</p>
          <select v-model.number="currentMail.condition.type">
            <option v-for="option in mailConstants.CONDITION_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.CONDITION_OPERATOR`) }}</p>
          <select v-model.number="currentMail.condition.operator" :disabled="[0,1].includes(currentMail.condition.type)">
            <option v-for="option in mailConstants.CONDITION_OPERATORS[currentMail.condition.type]" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
        </div>
        <div class="form-container" v-if="currentMail.type !== 2">
          <p>{{ $t(`MAIL_VALUES.CONDITION_VALUE`) }}</p>
          <input v-model="currentMail.condition.value" :disabled="currentMail.condition.type === 0" />
        </div>
        <p class="form-info" v-if="currentMail.type !== 2">{{ $t('MAIL_VALUES.CONDITION_VALUE_INFO_MESSAGE') }}</p>
      </div>
      <div class="col">
        <div id="#content-editor">

        </div>
      </div>
    </div>
    <div class="col" v-if="currentMail.type === 1">
      <h2>{{ $t(`MAIL_VALUES.REWARDS`) }}</h2>
      <div class="action-buttons-table" v-if="currentMail.rewards.length > 0">
        <div class="row header-row">
          <p>{{ $t(`MAIL_VALUES.TYPE`) }}</p>
          <p>{{ $t(`MAIL_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ $t('MAIL_VALUES.RARITY') }}</p>
          <p>{{ $t('MAIL_VALUES.CARD') }}</p>
          <p>{{ $t('MAIL_VALUES.PROBABILITY') }}</p>
          <p>{{ $t('MAIL_VALUES.CHEST_ID') }}</p>
          <p>{{ $t('MAIL_VALUES.ARENA') }}</p>
          <p>{{ $t('MAIL_VALUES.AMOUNT') }}</p>
          <p>{{ $t('MAIL_VALUES.EMOJI_INDEX') }}</p>
          <p>{{ $t('ACTIONS') }}</p>
        </div>
        <div v-for="(reward, index) in currentMail.rewards" v-bind:key="index" class="row">
          <select v-model.number="currentMail.rewards[index].type" @change="initializeRewardType(index, currentMail.rewards[index].type)">
            <option v-for="option in mailConstants.REWARD_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="currentMail.rewards[index].reward_type" :disabled="![3,4].includes(currentMail.rewards[index].type)">
            <option v-for="option in mailConstants.CARD_REWARD_TYPES" :value="option.value" v-bind:key="option.value"  :disabled="currentMail.rewards.filter((x, i) => i !== index).map(x => x.reward_type).includes(1 - option.value)">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="currentMail.rewards[index].rarity" :disabled="![3,4].includes(currentMail.rewards[index].type) || currentMail.rewards[index].reward_type === 1">
            <option v-for="option in mailConstants.CARD_RARITY" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model="currentMail.rewards[index].card_id" :disabled="![3,4].includes(currentMail.rewards[index].type) || currentMail.rewards[index].reward_type === 0">
            <option v-for="option in (currentMail.rewards[index].type === 3 ? units.filter(x => x.status === 1) : heroes.filter(x => x.status === 1))" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <input v-model.number="currentMail.rewards[index].probability" :disabled="![3,4].includes(currentMail.rewards[index].type)"/>
          <select v-model="currentMail.rewards[index].chest_id" :disabled="currentMail.rewards[index].type !== 2">
            <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <input v-model.number="currentMail.rewards[index].arena" :disabled="currentMail.rewards[index].type !== 2"/>
          <input v-model.number="currentMail.rewards[index].amount" :disabled="currentMail.rewards[index].type === 6" />
          <input v-model.number="currentMail.rewards[index].emoji_index" :disabled="currentMail.rewards[index].type !== 6"/>
          <button @click="currentMail.rewards.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
        </div>
      </div>
      <button @click="addReward" class="success">{{ $t('MAIL_VALUES.ADD_REWARD') }}</button>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
      <template v-if="$route.query.id">
        <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
        <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import mailSchema from "@/schemas/mail";
import { useRoute, useRouter } from "vue-router";
import Quill from "quill";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.js';
import 'quill-emoji/dist/quill-emoji.css';

import '@/lib/quillEmoji/customBlot';
import mailConstants from "@/constants/mailConstants";
import 'v-calendar/dist/style.css';
import validator from '@/util/validator'
import { mailValidationSchema } from "@/schemas/validator/validatorSchemas";
import languageConstants from "@/constants/languagesTemp";

export default {
  name: "MailAdd",
  setup() {
    const store = useStore();
    const mails = computed(() => store.getters['mails/getMails'])
    const units = computed(() => store.getters['units/getUnits'])
    const heroes = computed(() => store.getters['heroes/getHeroes'])
    const chests = computed(() => store.getters['chests/getChests'])

    const currentMail = reactive({...mailSchema()})
    const route = useRoute();
    const router = useRouter();

    const previousLanguage = ref('en')
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    let quill;
    onMounted(() => {
      if (route.query.id && mails.value.length > 0) {
        const editingMail = JSON.parse(JSON.stringify(mails.value.find(x => x.id === route.query.id)))
        Object.keys(editingMail).forEach(key => {
          currentMail[key] = editingMail[key]
          if(key === "condition" && editingMail.condition.value !== undefined) {
            if(Array.isArray(editingMail.condition.value)) {
              currentMail.condition.value = editingMail.condition.value.join(',')
            } else {
              currentMail.condition.value = editingMail.condition.value.toString()
            }
          }
        })
      }
      if (!currentMail.localizables) {
        currentMail.localizables = { title: {}, content: {}, html_content: {} }
      }
      var options = {
        placeholder: 'Compose a mail...',
        theme: 'snow',
        modules: {
          toolbar: [
            [{'color': [] }],
            ['bold',
              'italic',
              'underline',
              'strike',
            ],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'header': [1, 2, false] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],

          ],
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true
        }
      };
      quill = new Quill(document.getElementById('#content-editor'), options)
      quill.setContents(currentMail.content)
    })

    watch(() => store.getters['mails/getMails'], mails => {
      if (route.query.id) {
        const editingMail = JSON.parse(JSON.stringify(mails.find(x => x.id === route.query.id)))
        Object.keys(editingMail).forEach(key => {
          currentMail[key] = editingMail[key]
          if(key === "condition" && editingMail.condition.value !== undefined) {
            if(Array.isArray(editingMail.condition.value)) {
              currentMail.condition.value = editingMail.condition.value.join(',')
            } else {
              currentMail.condition.value = editingMail.condition.value.toString()
            }
          }
        })
        quill.setContents(currentMail.content)
      }
    })

    const addReward = () => {
      currentMail.rewards.push({})
    }

    const getUsedCardType = () => {
      let cardType = 0;
      let i = currentMail.rewards.length;
      while(i > 0) {
        i--;
        if(currentMail.rewards[i].reward_type === 1) {
          cardType = 1;
          break;
        }
        if(currentMail.rewards[i].reward_type === 0) {
          cardType = 0;
          break;
        }
      }
      return cardType;
    }

    const initializeRewardType = (index, type) => {
      if(type === 0 || type === 1) {
        currentMail.rewards[index] = {
          type
        }
      } else if(type === 2) {
        currentMail.rewards[index] = {
          type,
          chest_id: "",
          arena: 1
        }
      } else if(type === 6) {
        currentMail.rewards[index] = {
          type,
          emoji_index: 0
        }
      } else {
        currentMail.rewards[index] = {
          type,
          reward_type: getUsedCardType(),
          probability: 100
        }
      }
    }

    const languageChanged = (event) => {
      currentMail.localizables.content[previousLanguage.value] = quill.getContents();
      currentMail.localizables.html_content[previousLanguage.value] = quill.root.innerHTML;

      previousLanguage.value = event.target.value;

      quill.setContents(currentMail.localizables.content[selectedLanguage.value])
    }

    const prepareCondition = () => {
      if(currentMail.condition.type === 1) {
        currentMail.condition.value = currentMail.condition.value.split(',').map(x => x.trim())
      } else if(currentMail.condition.type !== 0) {
        currentMail.condition.value = parseInt(currentMail.condition.value)
      }
    }

    const checkMailFields = () => {
      const result = validator.validate(currentMail, mailValidationSchema)
      return result.success;
    }

    const submit = () => {
      prepareCondition()
      currentMail.localizables.content[selectedLanguage.value] = JSON.parse(JSON.stringify(quill.getContents()))
      currentMail.localizables.html_content[selectedLanguage.value] = quill.root.innerHTML;
      currentMail.content = currentMail.localizables.content['en']
      currentMail.html_content = currentMail.localizables.html_content['en']
  
      if (!checkMailFields())
        return;
      if(!currentMail.banner_asset) delete currentMail.banner_asset;
      console.log(currentMail);
      if (route.query.id) {
        store.dispatch('mails/updateMail', {id: route.query.id, data: currentMail})
      } else {
        store.dispatch('mails/addMail', {data: currentMail}).then(res => {
          if(res) {
            router.push({ name: 'MailAdd', query: { id: res.id }})
          }
        })
      }
    }

    const exportTo = environment => {
      store.dispatch('mails/exportMail', { id: route.query.id, data: currentMail, environment })
    }


    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      currentMail,
      submit,
      exportTo,
      env,
      mailConstants,
      addReward,
      units,
      heroes,
      chests,
      initializeRewardType,
      assetList,
      selectedLanguage,
      languages,
      languageChanged
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  min-height: 350px;
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expire_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}
</style>