import promotionService from "@/services/promotion"
import store from "@/store"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";

// initial state
const state = () => ({
    promotions: []
})

// getters
const getters = {
    getPromotions: ({ promotions }) => {
        return promotions
    }
}

// actions
const actions = {
    getPromotions({ commit }) {
        promotionService.getPromotions().then(promotions => {
            commit('setPromotions', promotions)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_PROMOTIONS'),
                type: 'error'
            })
        })
    },
    updatePromotion(state, { id, data }) {
        promotionService.setPromotion(id,data).then(() => {
            store.dispatch('promotions/getPromotions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_PROMOTION'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_PROMOTION'),
                type: 'error'
            })
        })
    },
    updatePromotionNoResync(state, { id, data }) {
        return promotionService.setPromotion(id,data).then(() => {
            return true
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_PROMOTION'),
                type: 'error'
            })
            return false
        })
    },
    exportPromotion(state, { id, data, environment }) {
        promotionService.exportPromotion(id,data, environment).then(() => {
            store.dispatch('promotions/getPromotions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_PROMOTION'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_PROMOTION'),
                type: 'error'
            })
        })
    },
    addPromotion(state, { data }) {
        promotionService.addPromotion(data).then(() => {
            store.dispatch('promotions/getPromotions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_PROMOTION'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_PROMOTION'),
                type: 'error'
            })
        })
    },
    deletePromotion(state, { id }) {
        promotionService.deletePromotion(id).then(() => {
            store.dispatch('promotions/getPromotions')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_PROMOTION'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_PROMOTION'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setPromotions(state, promotions) {
        state.promotions = promotions
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}