import getAxios from './axios'


export default {
    addAsset: shopContent => {
        //TODO: Make it work
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/assets/add', shopContent).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_ADD_ASSET'))
            })
        })
    },
    deleteAsset: asset => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/assets/delete', asset).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_DELETE_ASSET'))
            })
        })
    },
    getAssets: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/assets/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_ASSET_LIST'))
            })

        })
    }

}