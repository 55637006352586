<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('SEASONS')}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('NAME') }}</div>
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ $t('SEASON_NUMBER') }}</div>
          <div class="cell">{{ $t('ACTIVATION_DATE') }}</div>
          <div class="cell">{{ $t('EXPIRATION_DATE') }}</div>
          <div class="cell"><button class="success" @click="toggleAddPopup">{{ $t("ADD") }}</button></div>

        </div>
        <div class="table-row" v-for="(season, index) in seasonsArr" v-bind:key="season.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ season.name }}</div>
          <div class="cell">{{ season.id }}</div>
          <div class="cell">{{ season.season_number }}</div>
          <div class="cell">{{ moment(season.activation_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(season.expire_date).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell action-cell">
            <a :href="'/seasons/update?id=' + season.id" class="info">{{ $t('EDIT') }}</a>
          </div>
        </div>
      </div>

    </div>
    <div class="season-popup-wrapper" v-if="addPopupActive">
      <div class="season-popup-overlay" @click="toggleAddPopup" />
      <div class="season-popup-container">
        <div class="season-popup-header">
          {{ $t("NEW_SEASON") }}
        </div>
        <div class="season-popup-content">
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.ACTIVATION_DATE`) }}</p>
            <DatePicker
                v-model="newSeasonObj.activation_date"
                mode="dateTime"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="activation_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.EXPIRE_DATE`) }}</p>
            <DatePicker
                v-model="newSeasonObj.expire_date"
                mode="dateTime"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="expire_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.SEASON_NAME`) }}</p>
            <input v-model="newSeasonObj.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.SEASON_NUMBER`) }}</p>
            <input v-model="newSeasonObj.season_number" type="number" />
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.GRIND_LEVEL_CAP`) }}</p>
            <input v-model="newSeasonObj.level_cap" type="number" />
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.MAIN_MENU_BANNER_ASSET`) }}</p>
            <select v-model="newSeasonObj.main_menu_banner_asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`SEASON_VALUES.INSIDE_BANNER_ASSET`) }}</p>
            <select v-model="newSeasonObj.inside_banner_asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div class="form-container form-container-checkbox">
            <p>{{ $t(`SEASON_VALUES.LEADERBOARD_ACTIVE`) }}</p>
            <input v-model="newSeasonObj.is_leaderboard_active" type="checkbox" />
          </div>
        </div>
        <div class="season-popup-footer">
          <button class="success" @click="submitNewSeason" :disabled="addReqPending">{{ $t("SUBMIT") }}</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import 'v-calendar/dist/style.css';

export default {
  name: "Seasons",
  setup() {
    const store = useStore();
    const seasonsArr = ref([])
    watch(() => store.getters['seasons/getSeasons'], value => {
      seasonsArr.value = [ ...value ].sort((a,b) => b.activation_date - a.activation_date)
    }, { immediate: true })

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    const newSeasonObj = ref({});

    const resetSeasonObj = () => {
      newSeasonObj.value = {
        activation_date: Date.now(),
        expire_date: Date.now(),
        name: "",
        season_number: 0,
        is_leaderboard_active: true
      };
    }

    resetSeasonObj();

    const addPopupActive = ref(false);

    const toggleAddPopup = () => {
      resetSeasonObj();
      addPopupActive.value = !addPopupActive.value;
    }

    const addReqPending = ref(false);

    const submitNewSeason = () => {
      addReqPending.value = true;
      store.dispatch("seasons/addSeason", newSeasonObj.value).then(resetSeasonObj).finally(() => {
        addReqPending.value = false;
      })
      console.log("Submitting new season")
    }

    return {
      seasonsArr,
      moment,
      addPopupActive,
      toggleAddPopup,
      addReqPending,
      submitNewSeason,
      newSeasonObj,
      assetList
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 2fr 0.5fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  display: grid;
  padding: 0 10px;
  align-items: center;
}


button, a {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  align-self: stretch;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.action-cell {
  display: flex;
}
.season-popup-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.season-popup-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.season-popup-container {
  width: 60%;
  padding: 30px;
  border-radius: 15px;
  background-color: white;
  z-index: 2;
}

.season-popup-header {
  border-bottom: 1px solid black;
  padding: 0 0 10px 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.season-popup-footer {
  display: flex;
  justify-content: center;
  padding: 10px 0 0;
}

.season-popup-content {
  margin: 20px 0;
}


.form-container {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid black;
  align-items: stretch;
}

.form-container.form-container-checkbox {
  align-items: center;
}

.form-container p {
  width: 60%;
  text-align: start;
  font-weight: bold;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#activation_date, #expire_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

</style>