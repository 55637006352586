<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('WIKI')}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('NAME') }}</div>
          <div class="cell">{{ $t('INDEX') }}</div>
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell"><a href="/wiki/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(wiki, index) in wikiArr" v-bind:key="wiki.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ wiki.name }}</div>
          <div class="cell">{{ wiki.index }}</div>
          <div class="cell">{{ wiki.id }}</div>
          <div class="cell">
            <a :href="'/wiki/add?id=' + wiki.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deleteWiki(wiki.id, wiki.index)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "Wiki",
  setup() {
    const store = useStore();
    const wikiArr = ref([])
    watch(() => store.getters['wiki/getWiki'], value => {
      wikiArr.value = [ ...value ].sort((a,b) => a.index - b.index)
    }, { immediate: true })

    const deleteWiki = (id, index) => {
      store.dispatch('wiki/deleteWiki', { id, index })
    }

    return {
      wikiArr,
      deleteWiki
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr 3fr 2fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
  align-items: center;
}

button, a {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>