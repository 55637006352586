<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('EVENT')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`NAME`) }}</p>
            <label class="field-info">*Will be displayed in leaderboard reward mail</label>
            <input v-model="currentEvent.name" />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.TYPE`) }}</p>
            <select v-model.number="currentEvent.type">
              <option v-for="type in eventConstants.EVENT_TYPES" v-bind:key="type.value" :value="type.value" :disabled="type.value === 0">{{ $t(type.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ $t(`EVENT_VALUES.MODIFIER_TYPE`) }}</p>
            <select v-model.number="currentEvent.modifier_type">
              <option v-for="type in eventConstants.GAME_MODE_MODIFIER_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
          </div>
          <div class="form-container" v-if="currentEvent.type === 1">
            <p>{{ $t(`EVENT_VALUES.CARD_LEVEL`) }}</p>
            <input type="number" v-model.number="currentEvent.card_level" min="1" />
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.START_DATE`) }}</p>
            <DatePicker
                v-model.number="currentEvent.start_date"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="start_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.END_DATE`) }}</p>
            <DatePicker
                v-model.number="currentEvent.end_date"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    id="end_date"
                    :value="inputValue"
                    v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.EVENT_PAGE_ASSET`) }}</p>
            <select v-model="currentEvent.event_page_asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`EVENT_VALUES.INSIDE_ASSET`) }}</p>
            <select v-model="currentEvent.inside_asset">
              <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="col-title">{{ $t(`EVENT_VALUES.WIN_REWARDS`) }}</div>
          <div v-for="index in Object.keys(currentEvent.rewards)" v-bind:key="index" class="reward-cell">
            <select v-model.number="currentEvent.rewards[index][0].type" @change="() => updateRewardBody(index)">
              <option v-for="type in eventConstants.EVENT_REWARD_TYPES" v-bind:key="type.value" :value="type.value" :disabled="type.value === 0">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,3].includes(currentEvent.rewards[index][0].type)" v-model.number="currentEvent.rewards[index][0].amount" placeholder="Amount" />
            <select v-if="currentEvent.rewards[index][0].type === 3" v-model.number="currentEvent.rewards[index][0].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="currentEvent.rewards[index][0].type === 2" v-model="currentEvent.rewards[index][0].id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
          </div>
          <button class="error" @click="removeLastReward"> {{ $t("REMOVE_REWARD") }}</button>
          <button class="success" @click="addReward"> {{ $t("ADD_REWARD") }}</button>
        </div>
      </div>
      <div class="col">
        <div class="col-title">{{ $t(`EVENT_VALUES.LEADERBOARD_REWARDS`) }}</div>
        <div v-for="(leaderboardReward, index) in currentEvent.leaderboard_rewards" v-bind:key="index" class="cell leaderboard-cell" :class="index % 2 === 1 ? 'alternate' : ''">
          <div class="leaderboard-container">
            <span>{{ $t("EVENT_VALUES.RANKING") }}</span>
            <div>{{ currentEvent.leaderboard_rewards.slice(0, index).reduce((acc, val) => acc + val.rewardCount, 0) + 1  + ( leaderboardReward.rewardCount > 1 ? "-" +  (currentEvent.leaderboard_rewards.slice(0, index).reduce((acc, val) => acc+ val.rewardCount, 0) + leaderboardReward.rewardCount): "") }}</div>
          </div>
          <div class="leaderboard-container">
            <span>{{ $t("EVENT_VALUES.REWARD_COUNT") }}</span>
            <input v-model.number="leaderboardReward.rewardCount" min="1" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[0].type" @change="() => updateLeaderboardRewardBody(index, 0)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[0].type)" v-model.number="leaderboardReward.content[0].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[0].type === 3" v-model.number="leaderboardReward.content[0].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[0].type === 2" v-model="leaderboardReward.content[0].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[0].type === 6" v-model.number="leaderboardReward.content[0].emoji_index" min="1" placeholder="Emoji Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[1].type" @change="() => updateLeaderboardRewardBody(index, 1)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[1].type)" v-model.number="leaderboardReward.content[1].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[1].type === 3" v-model.number="leaderboardReward.content[1].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[1].type === 2" v-model="leaderboardReward.content[1].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[1].type === 6" v-model.number="leaderboardReward.content[1].emoji_index" min="1" placeholder="Emoji Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[2].type" @change="() => updateLeaderboardRewardBody(index, 2)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[2].type)" v-model.number="leaderboardReward.content[2].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[2].type === 3" v-model.number="leaderboardReward.content[2].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[2].type === 2" v-model="leaderboardReward.content[2].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[2].type === 6" v-model.number="leaderboardReward.content[2].emoji_index" min="1" placeholder="Emoji Index" />
          </div>
          <div class="reward-cell">
            <select v-model.number="leaderboardReward.content[3].type" @change="() => updateLeaderboardRewardBody(index, 3)">
              <option v-for="type in eventConstants.EVENT_LEADERBOARD_REWARD_TYPES" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <input v-if="[0,1,2,3].includes(leaderboardReward.content[3].type)" v-model.number="leaderboardReward.content[3].amount" placeholder="Amount" />
            <select v-if="leaderboardReward.content[3].type === 3" v-model.number="leaderboardReward.content[3].rarity">
              <option v-for="type in unitConstants.RARITY" v-bind:key="type.value" :value="type.value">{{ $t(type.text) }}</option>
            </select>
            <select v-if="leaderboardReward.content[3].type === 2" v-model="leaderboardReward.content[3].chest_id">
              <option v-for="type in chests" v-bind:key="type.id" :value="type.id">{{ $t(type.name) }}</option>
            </select>
            <input v-if="leaderboardReward.content[3].type === 6" v-model.number="leaderboardReward.content[3].emoji_index" min="1" placeholder="Emoji Index" />
          </div>

        </div>
        <button class="error" @click="removeLastLeaderboardReward"> {{ $t("REMOVE_REWARD") }}</button>
        <button class="success" @click="addLeaderboardReward"> {{ $t("ADD_REWARD") }}</button>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import eventConstants from "@/constants/eventConstants";
import unitConstants from "@/constants/unitConstants";

import eventSchema from "@/schemas/event"
import 'v-calendar/dist/style.css';


export default {
  name: "Event",
  setup() {
    const store = useStore();
    const events = computed(() => store.getters['events/getEvents'])
    const chests = computed(() => store.getters["chests/getChests"])
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })
    const currentEvent = reactive({ ...eventSchema.getSchema() })
    const route = useRoute();
    onMounted(() => {
      if(route.query.id && events.value.length > 0) {
        const editingEvent = JSON.parse(JSON.stringify(events.value.find(x => x.id === route.query.id)))
        Object.keys(editingEvent).forEach(key => {
          currentEvent[key] = editingEvent[key]
        })
      }
    })

    watch(() => store.getters['events/getEvents'], events => {
      if(route.query.id && events.length > 0) {
        const editingEvent = JSON.parse(JSON.stringify(events.find(x => x.id === route.query.id)))
        Object.keys(editingEvent).forEach(key => {
          currentEvent[key] = editingEvent[key]
        })
      }
    })


    const submit = () => {
      if(route.query.id) {
        store.dispatch('events/updateEvent', { id: route.query.id, updateBody: currentEvent })
      } else {
        store.dispatch('events/addEvent', currentEvent)
      }
    }

    const updateRewardBody = index => {
      currentEvent.rewards[index][0] = {
        type: currentEvent.rewards[index][0].type,
      }
      if(currentEvent.rewards[index][0].type === 2) {
        currentEvent.rewards[index][0].id = ""
        return
      }
      currentEvent.rewards[index][0].amout = 0;
      if(currentEvent.rewards[index][0].type === 3)
        currentEvent.rewards[index][0].rarity = 0
    }

    const addReward = () => {
      currentEvent.rewards[Object.keys(currentEvent.rewards).length + 1 + ""] = [
        {
          type: 0,
          amount: 0
        }
      ]
    }

    const removeLastReward = () => {
      delete currentEvent.rewards[Object.keys(currentEvent.rewards).length + ""]
    }

    const addLeaderboardReward = () => {
      currentEvent.leaderboard_rewards.push({
        rewardCount: 1,
        content: [
          {
            type: 0,
            amount: 0
          },
          {
            type: 1,
            amount: 0
          }
        ]
      })
    }

    const removeLastLeaderboardReward = () => {
      currentEvent.leaderboard_rewards.splice(currentEvent.leaderboard_rewards.length - 1, 1)
    }

    const updateLeaderboardRewardBody = (index, rewardIndex) => {
      currentEvent.leaderboard_rewards[index].content[rewardIndex] = {
        type: currentEvent.leaderboard_rewards[index].content[rewardIndex].type,
      }
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex].type === 2) {
        currentEvent.leaderboard_rewards[index].content[rewardIndex].chest_id = ""
        currentEvent.leaderboard_rewards[index].content[rewardIndex].amount = 1

        return
      }
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex]) {
        currentEvent.leaderboard_rewards[index].content[rewardIndex].emoji_index = 0;
        return
      }
      currentEvent.leaderboard_rewards[index].content[rewardIndex].amout = 0;
      if(currentEvent.leaderboard_rewards[index].content[rewardIndex].type === 3)
        currentEvent.leaderboard_rewards[index].content[rewardIndex].rarity = 0
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      chests,
      eventConstants,
      unitConstants,
      currentEvent,
      submit,
      updateRewardBody,
      updateLeaderboardRewardBody,
      addReward,
      removeLastReward,
      addLeaderboardReward,
      removeLastLeaderboardReward,
      env,
      assetList
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: start;
}
.second-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: start;
}
.third-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  text-align: start;
}

.leaderboard-container {
  display: inline-flex;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  height: auto;

}

.leaderboard-container span {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

.leaderboard-cell.alternate {
  background-color: lightgrey;
}
.leaderboard-cell {
  margin: 10px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

.reward-cell * {
  margin: 5px 10px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.reward-cell {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}
label.field-info {
  font-size: 0.7rem;
}

</style>