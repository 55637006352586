export default () => {
    const creationDate = Date.now()
    return {
        title: 'title',
        content: '',
        localizables: {
            title: {},
            content: {},
            html_content: {}
        },
        creation_date: creationDate,
        expiration_date: creationDate + 28 * 24 * 60 * 60 * 1000,
        type: 0,
        action_buttons: []
    }
}