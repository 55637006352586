<template>
  <div class="main-container">
    <h1 class="main-heading">{{ "Promo Codes" }}</h1>
    <div class="content-container">
      <div class="col">
        <div class="form-container">
            <p>Id</p>
            <button @click="() => generatePromoCode()" :disabled="route.query.id" class="info">{{ "Generate Random" }}</button>
            <input v-model="currentPromoCode.id" :disabled="route.query.id" />
        </div>
        <div class="form-container">
            <p>User Limit</p>
            <input v-model="currentPromoCode.user_limit" type="number" />
        </div>
        <div class="form-container">
            <p>Expire Date</p>
            <DatePicker
              v-model="currentPromoCode.expire_date"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container">
          <input type="checkbox" id="checkbox" v-model="hasUsageLimit" :disabled="route.query.id">
          <label for="checkbox">{{ "Has Limit" }}</label>
        </div>
        <div v-if="hasUsageLimit" class="form-container">
            <p>Total Limit</p>
            <input v-model="currentPromoCode.total_limit" type="number" :min="currentPromoCode.use_count" />
        </div>
      </div>
    </div>
    <div class="col">
      <h2>{{ $t(`MAIL_VALUES.REWARDS`) }}</h2>
      <div class="action-buttons-table" v-if="currentPromoCode.rewards.length > 0">
        <div class="row header-row">
          <p>{{ $t(`MAIL_VALUES.TYPE`) }}</p>
          <p>{{ $t(`MAIL_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ $t('MAIL_VALUES.RARITY') }}</p>
          <p>{{ $t('MAIL_VALUES.CARD') }}</p>
          <p>{{ $t('MAIL_VALUES.CHEST_ID') }}</p>
          <p>{{ $t('MAIL_VALUES.ARENA') }}</p>
          <p>{{ $t('MAIL_VALUES.AMOUNT') }}</p>
          <p>{{ $t('MAIL_VALUES.EMOJI_INDEX') }}</p>
          <p>{{ $t('ACTIONS') }}</p>
        </div>
        <div v-for="(reward, index) in currentPromoCode.rewards" v-bind:key="index" class="row">
          <select v-model.number="currentPromoCode.rewards[index].type" @change="initializeRewardType(index, currentPromoCode.rewards[index].type)">
            <option v-for="option in mailConstants.REWARD_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="currentPromoCode.rewards[index].reward_type" :disabled="![3,4].includes(currentPromoCode.rewards[index].type)">
            <option v-for="option in mailConstants.CARD_REWARD_TYPES" :value="option.value" v-bind:key="option.value"  :disabled="currentPromoCode.rewards.filter((x, i) => i !== index).map(x => x.reward_type).includes(1 - option.value)">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="currentPromoCode.rewards[index].rarity" :disabled="![3,4].includes(currentPromoCode.rewards[index].type) || currentPromoCode.rewards[index].reward_type === 1">
            <option v-for="option in mailConstants.CARD_RARITY" :value="option.value" v-bind:key="option.value">{{ $t('MAIL_VALUES.' + option.text) }}</option>
          </select>
          <select v-model="currentPromoCode.rewards[index].card_id" :disabled="![3,4].includes(currentPromoCode.rewards[index].type) || currentPromoCode.rewards[index].reward_type === 0">
            <option v-for="option in (currentPromoCode.rewards[index].type === 3 ? units.filter(x => x.status === 1) : heroes.filter(x => x.status === 1))" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <select v-model="currentPromoCode.rewards[index].chest_id" :disabled="currentPromoCode.rewards[index].type !== 2">
            <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <input v-model.number="currentPromoCode.rewards[index].arena" :disabled="currentPromoCode.rewards[index].type !== 2"/>
          <input v-model.number="currentPromoCode.rewards[index].amount" :disabled="currentPromoCode.rewards[index].type === 6" />
          <input v-model.number="currentPromoCode.rewards[index].emoji_index" :disabled="currentPromoCode.rewards[index].type !== 6"/>
          <button @click="currentPromoCode.rewards.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
        </div>
      </div>
      <button @click="addReward" class="success">{{ $t('MAIL_VALUES.ADD_REWARD') }}</button>
    </div>
    <div class="buttons-section">
      <button @click="() => submit()" class="info">{{ "Submit" }}</button>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { getRandomString } from '@/util/randomStringGenerator'
import validator from '@/util/validator'
import { promoCodeValidationSchema } from "@/schemas/validator/validatorSchemas";
import mailConstants from "@/constants/mailConstants";
import promoCodeSchema from "@/schemas/promoCode";
import 'v-calendar/dist/style.css';


export default {
  name: "PromoCodesAdd",
  setup() {
    const store = useStore();
    const promoCodes = computed(() => store.getters['promoCodes/getPromoCodes'])
    const units = computed(() => store.getters['units/getUnits'])
    const heroes = computed(() => store.getters['heroes/getHeroes'])
    const chests = computed(() => store.getters['chests/getChests'])
    const currentPromoCode = reactive({...promoCodeSchema()})
    const route = useRoute();
    const router = useRouter();


    const hasUsageLimit = ref(true);

    onMounted(() => {
      if (route.query.id && promoCodes.value.length > 0) {
        const editingPromoCode = JSON.parse(JSON.stringify(promoCodes.value.find(x => x.id === route.query.id)))
        Object.keys(editingPromoCode).forEach(key => {
          currentPromoCode[key] = editingPromoCode[key]
        })
        hasUsageLimit.value = editingPromoCode["total_limit"] !== -1 
      }
    })

    watch(() => store.getters['promoCodes/getPromoCodes'], promoCodes => {
      if (route.query.id && promoCodes.value) {
        const editingPromoCode = JSON.parse(JSON.stringify(promoCodes.find(x => x.id === route.query.id)))
        Object.keys(editingPromoCode).forEach(key => {
          currentPromoCode[key] = editingPromoCode[key]
        })
      }
    })

    const generatePromoCode = () => {
      currentPromoCode.id = getRandomString(Math.floor(Math.random() * (12 - 4) + 4))
    }

    const addReward = () => {
      currentPromoCode.rewards.push({})
    }

    const getUsedCardType = () => {
      let cardType = 0;
      let i = currentPromoCode.rewards.length;
      while(i > 0) {
        i--;
        if(currentPromoCode.rewards[i].reward_type === 1) {
          cardType = 1;
          break;
        }
        if(currentPromoCode.rewards[i].reward_type === 0) {
          cardType = 0;
          break;
        }
      }
      return cardType;
    }

    const initializeRewardType = (index, type) => {
      if(type === 0 || type === 1) {
        currentPromoCode.rewards[index] = {
          type
        }
      } else if(type === 2) {
        currentPromoCode.rewards[index] = {
          type,
          chest_id: "",
          arena: 1
        }
      } else if(type === 6) {
        currentPromoCode.rewards[index] = {
          type,
          emoji_index: 0
        }
      } else {
        currentPromoCode.rewards[index] = {
          type,
          reward_type: getUsedCardType()
        }
      }
    }

    const validatePromoCode = (data) => {
        const result = validator.validate(data, promoCodeValidationSchema)

        return result.success;
    }

    const submit = () => {
        const isValid = validatePromoCode(currentPromoCode);
        if (!isValid)
            return;

        if (!hasUsageLimit.value) {
            currentPromoCode.total_limit = -1;
            currentPromoCode.remaining_total_limit = -1;
        } else {
            if (currentPromoCode.total_limit < currentPromoCode.use_count)
                currentPromoCode.total_limit = currentPromoCode.use_count
            currentPromoCode.remaining_total_limit = currentPromoCode.total_limit - currentPromoCode.use_count
        }

        if (!route.query.id) {
            currentPromoCode.create_date = Date.now()
        }

        store.dispatch('promoCodes/updatePromoCode', currentPromoCode).then(res => {
            if (res) {
                router.push({ name: 'PromoCodes'})
            }
        })
    }

    const env = process.env.ENV;

    return {
      currentPromoCode,
      env,
      hasUsageLimit,
      mailConstants,
      addReward,
      getUsedCardType,
      initializeRewardType,
      units,
      heroes,
      chests,
      submit,
      route,
      generatePromoCode
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}

.filter-sort {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/*#emoji-palette >>> .ap, .ql-emojiblot >>> .ap {*/
/*  background-image: none;*/
/*  text-indent: 0;*/
/*  !* It's also possible to adjust spacing. To make selecting emojis easier *!*/
/*  !* width: 25px; *!*/
/*  !* height: 25px; *!*/
/*}*/
</style>