import roadService from "@/services/road"
import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
// initial state
const state = () => ({
    trophyRoad: {}
})

// getters
const getters = {
    getTrophyRoad: ({ trophyRoad }) => {
        return trophyRoad
    }
}

// actions
const actions = {
    updateTrophyRoad({ commit }, trophyRoad) {
        roadService.setTrophyRoad(trophyRoad).then(() => {
            commit('setTrophyRoad', trophyRoad)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.UPDATE_TROPHY_ROAD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.UPDATE_TROPHY_ROAD'),
                type: 'error'
            })
        })
    },
    exportTrophyRoad({ commit }, { trophyRoad, environment }) {
        roadService.exportTrophyRoad(trophyRoad, environment).then(() => {
            commit('setTrophyRoad', trophyRoad)
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.EXPORT_TROPHY_ROAD'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.EXPORT_TROPHY_ROAD'),
                type: 'error'
            })
        })
    },
    getTrophyRoad({ commit }) {
        roadService.getTrophyRoad().then(trophyRoad => {
            commit('setTrophyRoad', trophyRoad)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_TROPHY_ROAD'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setTrophyRoad(state, trophyRoad) {
        state.trophyRoad = trophyRoad
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}