const REWARD_TYPES = {
    GOLD: 0,
    GEM: 1,
    CHEST: 2,
    UNIT: 3,
    EMOJI: 6
}

const EVENT_TYPES = {
    MIRROR_MATCH: 0,
    CHALLENGE: 1
}

const CHEST_IDS = {
    WOODEN: "VltKoUl2xQJavmKOzvOI",
    SILVER: "qroTswstg3N9raIsUmru",
    GOLD: "TNFw8UrxKBzGUsuwb3Le",
    IRON: "c7IX6YrPw7LDPxhpQ5vr",
    DIAMOND: "Qxi6BVi2nRE1l5IlQq0B",
    LEGENDARY: "Br59Oc9UemJT8aOFiY8B",
    SEASON_PASS_TIER0: "HXOhoMTUhsIBToFY1gjW",
    SEASON_PASS_TIER1: "M00BMH8K1E2rpqk37IPk",
    SEASON_PASS_TIER2: "KFKL2jrHlgXIbXcD41dY",
    SEASON_PASS_TIER3: "16csBloB4KU3s2tKqFkk",
    SEASON_PASS_TIER4: "IwkQEYqnbb8UpIsdSZrW",
    ONE_AD: "gy5iIdK1gkr8zB7s2dHm",
    AD_MEGA: "ZCfc5MF5fKHEAhyQ5Q2L",
    VICTORY: "mdi0fBBIhzWpbclHaM7d",
    SUPPORT: "Zos83NIXQ2fv3RoywyfO",
    SHOP_BIG: "rvULim413LiBIjBK3lVx",
    SHOP_HERO: "f9cjPvL6Nnrm9rD1UpDB",
    SHOP_LARGE: "il6fJ8Bj2etGbcLPrYKQ",
    SHOP_MEGA_EPIC: "6NxPvGFVJx6w31Jh4G2X",
    SHOP_COLOSSAL: "Qhb1vxVj9gKtFfmaENLF"
}

const CHALLENGE_MODIFIERS = {
    REGULAR: 0,
    DOUBLE_MANA: 1,
    SUDDEN_DEATH: 2,
    MONSTER_RUSH: 3
}

export default {
    getSchema: () => {
        return {
            type: EVENT_TYPES.CHALLENGE,
            card_level: 10,
            modifier_type: CHALLENGE_MODIFIERS.DOUBLE_MANA,
            name: "Double Mana Challenge",
            start_date: Date.now(),
            end_date: Date.now() + 24 * 60 * 60 * 1000,
            rewards: {
                "1": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 100
                    }
                ],
                "2": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.SILVER
                    }
                ],
                "3": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 250
                    }
                ],
                "4": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.GOLD
                    }
                ],
                "5": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 500
                    }
                ],
                "6": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.GOLD
                    }
                ],
                "7": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 750
                    }
                ],
                "8": [
                    {
                        type: REWARD_TYPES.UNIT,
                        amount: 3,
                        rarity: 2
                    }
                ],
                "9": [
                    {
                        type: REWARD_TYPES.GOLD,
                        amount: 1000
                    }
                ],
                "10": [
                    {
                        type: REWARD_TYPES.CHEST,
                        id: CHEST_IDS.IRON
                    }
                ]
            },
            leaderboard_rewards: [
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 5000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 500
                        }
                    ]
                },
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 4000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 400
                        }
                    ]
                },
                {
                    rewardCount: 1,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.LEGENDARY,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 3000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 300
                        }
                    ]
                },
                {
                    rewardCount: 7,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.SHOP_LARGE,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 2000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 250
                        }
                    ]
                },
                {
                    rewardCount: 10,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.DIAMOND,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 1500
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 200
                        }
                    ]
                },
                {
                    rewardCount: 30,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.SHOP_BIG,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 1000
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 150
                        }
                    ]
                },
                {
                    rewardCount: 50,
                    content: [
                        {
                            type: REWARD_TYPES.CHEST,
                            chest_id: CHEST_IDS.IRON,
                            amount: 1
                        },
                        {
                            type: REWARD_TYPES.EMOJI,
                            emoji_index: 12
                        },
                        {
                            type: REWARD_TYPES.GOLD,
                            amount: 500
                        },
                        {
                            type: REWARD_TYPES.GEM,
                            amount: 100
                        }
                    ]
                }
            ],
            leaderboard_data: {
                minGameScore: 0,
                leaderboard: []
            }
        }
    }
}