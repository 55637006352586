<template>
    <h3 class="title">{{"Filters"}}</h3>
    <div class="filter-container">
        <div class="filter">
            <div>
                <p>{{"Sort By"}}</p>
                <select v-model="sortField">
                    <option v-for="option in sortOptions" :value="option.field" v-bind:key="option.field">{{ option.text || "No Sort Field" }}</option>
                </select>
            </div>
            <div v-for="filter in filters" v-bind:key="filter.field">
                <div v-if="filter.type =='date'">
                    <p>{{ filter.text }}</p>
                    <DatePicker
                        v-model="filter.value"
                        mode="dateTime"
                        :model-config="{
                        type: 'number',
                        mask: 'DD/MM/YYYY HH:mm'}"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                id="creation_date"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </DatePicker>
                </div>
                <div v-else-if="filter.field =='usd_price'">
                <p>{{ filter.text }}</p>
                <select v-model = "filter.value">
                  <option v-for = "option in priceConstants.PRICE_LEVELS" :value= "option.level"
                          v-bind:key = "option.level" > {{ option.text || "No Field Selected" }}
                  </option>
                </select>
                </div>
                <div v-else>
                    <p>{{ filter.text }}</p>
                    <input v-model="filter.value"/>
                </div>
            </div>
        </div>  
        <div class="filter-sort">
            <input type="checkbox" id="checkbox" v-model="isDesc">
            <label for="checkbox">{{ "Descending Order" }}</label>
        </div>
    </div>
    <div class="row row-header">
      <div v-for="column in columns" v-bind:key="column.field" class="cell cell-header" :style="{ width: (100 / columns.length) + '%', maxWidth: (100 / columns.length) + '%'}">
          {{ $t(column.text) }}
      </div>
    </div>
    <div v-for="row in rows" class="row" v-bind:key="row" @click="$emit('open-popup', row)">
      <div v-for="column in columns" v-bind:key="column.key + row" :class="{ cellred: row['usd_price'] > 20 }" class="cell" :style="{ width: (100 / columns.length) + '%', maxWidth: (100 / columns.length) + '%'}">
        <div v-if="column.type == 'date'">
            {{ moment(row[column.field]).format('DD/MM/YYYY HH:mm') }}
        </div>
        <div v-else-if="column.type == 'number' ">
            {{ '$ ' + row[column.field] }}
        </div>
        <div v-else>
            {{ row[column.field] }}
        </div>
      </div>
    </div>
    <div class="pagination">
      <button class="pagination-navigation-button" @click="prevPage" :disabled="pageIndex === 0">
          {{ "Prev" }}
      </button>
      <div class="pagination-cursor">
        {{ pageIndex + 1 }}
      </div>
      <button class="pagination-navigation-button" @click="nextPage" :disabled="rows.length < pageSize">
          {{ "Next" }}
      </button>
      <button class="pagination-navigation-button" @click="applySearch">
        {{ "Apply Filters" }}
      </button>
      <div v-if="loading" class="">
        {{ "Fetching Data..." }}
      </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import axios from "axios";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useI18n } from 'vue-i18n'
import 'v-calendar/dist/style.css';
import priceConstants from "@/constants/priceConstants";

export default {
  name: "Table",
  props: [
      "columns",
      "serverEndpoint",
      "pageSize",
      "initialSortField"
  ],
  emits: [
      "open-popup"
  ],
  setup(props) {
    const { t } = useI18n();
    const pageIndex = ref(0);
    const rows = ref([]);
    const filters = reactive([]);
    const sortOptions = reactive([]);

    const sortField = ref(props.initialSortField);
    const isDesc = ref(true);

    const loading = ref(false);

    onMounted(() => {
        prepareFilters()
        prepareSortOptions()
        getRows()
    });

    const prepareSortOptions = () => {
        props.columns.filter(x => x.filterable).map(y => {
            return {
                text: y.text,
                field: y.field
            }
        }).forEach(element => sortOptions.push(element));
    }

    const prepareFilters = () => {
        props.columns.filter(y => y.filterable).map(x => {
            return {
                text: x.text,
                field: x.field,
                value: undefined,
                type: x.type
            }
        }).forEach(element => {
            if (element.type === 'date') {
                var elementBegin = JSON.parse(JSON.stringify(element));
                var elementEnd = JSON.parse(JSON.stringify(element));
                elementBegin.field += "_begin";
                elementBegin.text += " Begin"
                elementEnd.field += "_end";
                elementEnd.text += " End"
                filters.push(elementBegin);
                filters.push(elementEnd);

            }  else filters.push(element);
        })
    }

    const getRows = async (page = 0) => {
        loading.value = true;
        console.log(filters);
        const newRows = await axios.post(props.serverEndpoint, {
        order_by: sortField.value,
        is_descending: isDesc.value,
        filters: filters.filter(x => x.value !== undefined && x.value !== ''),
        page: page
      }).then(res => {
          loading.value = false;
        console.log(res)
        return res.data
      })
      if(newRows.length > 0) {
        rows.value = newRows;
        pageIndex.value = page;
      } else if(newRows.length === 0) {
        rows.value = [];
        pageIndex.value = 0;
        notify({
          title: t('ERROR'),
          text: t('NOTIF.ERROR.NO_RESULTS_FOUND'),
          type: 'error'
        })
      }
    }

    const openPopup = (content) => {
        console.log(content);
    }

    const nextPage = () => {
        getRows(pageIndex.value + 1);
    }

    const prevPage = () => {
      getRows(pageIndex.value -1);
    }

    const applySearch = () => {
      getRows(0)
    }

    return {
      pageIndex,
      rows,
      filters,
      nextPage,
      prevPage,
      applySearch,
      sortField,
      isDesc,
      moment,
      openPopup,
      loading,
      sortOptions,
      priceConstants
    }
  }
}
</script>

<style scoped>

.title {
    align-self: start;
    margin-left: 40px;
}

.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 50px;
  overflow: hidden;
}

.filter-container {
    border: 1px solid black;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    padding: 40px;
}

.filter {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter-sort {
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.row:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border: 2px solid black;
}

.cell {
  border: 1px solid black;
  padding: 5px 0;
}

.cellred {
    border: 1px solid black;
    padding: 5px 0;
    background-color: rgba(216, 26, 0, 0.2);
}

.pagination {
  display: flex;
  align-items: center;
  padding: 15px;
}

.pagination-navigation-button {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid gray;
  cursor: pointer;
  margin-right: 5px;
}

.pagination-navigation-button:disabled, .pagination-navigation-button[disabled] {
  cursor: default;
}

.pagination-cursor {
  padding: 5px 10px;
  font-weight: 600;
}

.row.row-search {
  background-color: rgba(0,0,0,0.1);
}

.row input {
  font-size: 0.85rem!important;
  width: 95%;
}
</style>