import getAxios from './axios'


export default {
    setServerConfig: serverConfig => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server-config/set', serverConfig).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SERVER_CONFIG'))
            })
        })
    },
    exportServerConfig: (serverConfig, environment) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server-config/export', { serverConfig, environment }).then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_SET_SERVER_CONFIG'))
            })
        })
    },
    getServerConfig: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get('/api/server-config/get').then(res => resolve(res.data)).catch(() => reject('ERROR_CANNOT_GET_SERVER_CONFIG'))
            })

        })
    }

}