import { createApp } from 'vue'
import firebase from 'firebase/app'

import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import { SetupCalendar, DatePicker } from 'v-calendar';
import Notifications from '@kyvg/vue3-notification';
import panelAdminConstants from "@/constants/panelAdminConstants";
import Multiselect from 'vue-multiselect'

const firebaseConfig = {
    apiKey: "AIzaSyD2rNUmZDd7GW_cO0qOqeup3V_pQmgjxAk",
    authDomain: "random-brawl.firebaseapp.com",
    projectId: "random-brawl",
    storageBucket: "random-brawl.appspot.com",
    messagingSenderId: "272246890080",
    appId: "1:272246890080:web:809963b90357833b0a6d05",
    measurementId: "G-BTLMZKEJ7C"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
    if(user) {
        store.dispatch("auth/setUser", user);
        user.getIdTokenResult(true).then(result => {
            store.dispatch("auth/setClaims", Object.keys(result.claims).filter(x => [...panelAdminConstants.PERMISSION_TYPES, 'panelAdmin'].includes(x) && result.claims[x]))
        })
    } else {
        store.dispatch('auth/removeUser')
    }
});

const app = createApp(App)
app.config.devtools = true
app.use(SetupCalendar, {}).component('DatePicker', DatePicker).use(Notifications).use(store).use(router).use(i18n).mount('#app')
app.component('multiselect', Multiselect)
