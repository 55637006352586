export default () => {
    return {
        title: 'Pack Title',
        localizables: {
            title: {}
        },
        product_id: '',
        expires_in: 0,
        price: 0,
        discount_rate: 0,
        arena: 2,
        reward_claim_type: 0,
        rewards: [],
        rewards_b: [],
        value_multiplier: 0,
        B: {
            title: 'Pack Title B',
            localizables: {
                title: {}
            },
            product_id: '',
            price: 0,
            discount_rate: 0,
            value_multiplier: 0,
            reward_claim_type: 0
        }
    }
}