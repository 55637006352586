export default {
    TROPHY_REWARD_TYPES: [
        {
            value: 0,
            text: "GOLD"
        },
        {
            value: 1,
            text: "GEM"
        },
        {
            value: 8,
            text: "PICK_ONE"
        },
        {
            value: 2,
            text: "CHEST"
        },
        {
            value: -1,
            text: "ARENA_UNLOCK"
        },
        {
            value: 3,
            text: "UNIT"
        },
        {
            value: 4,
            text: "HERO"
        },
    ],
    ARENA_TYPES: [
        {
            value: 0,
            text: 'MAGIC_FOREST'
        },
        {
            value: 1,
            text: 'PIRATE_BEACH'
        },
        {
            value: 2,
            text: 'SNOWY_PEAKS'
        },
        {
            value: 3,
            text: 'LAVA_PIT'
        },
        {
            value: 4,
            text: 'TOXIC_SWAMP'
        },
        {
            value: 5,
            text: 'OCEAN_FLOOR'
        },
        {
            value: 6,
            text: 'CANDY_TOWN'
        },
        {
            value: 7,
            text: 'CRYSTAL_MINE'
        },
        {
            value: 8,
            text: 'ROYAL_CASTLE'
        },
        {
            value: 9,
            text: 'GRAVEYARD'
        },
        {
            value: 10,
            text: 'CIRCUS'
        },
        {
            value: 11,
            text: 'LEAGUE'
        },
    ],
    RARITIES: [
        {
            value: 0,
            text: 'COMMON'
        },
        {
            value: 1,
            text: 'RARE'
        },
        {
            value: 2,
            text: 'EPIC'
        },
        {
            value: 3,
            text: 'LEGENDARY'
        },
    ],
    CARD_TYPES: [
        {
            value: 0,
            text: 'UNIT'
        },
        {
            value: 1,
            text: 'HERO'
        }
    ]
}