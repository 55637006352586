export default {
    NEWS_TYPES: [
        {
            text: 'GENERIC',
            value: 0
        },
        {
            text: 'UPDATE',
            value: 1
        },
        {
            text: 'SEASON_PASS',
            value: 2
        }
    ],
    ACTION_BUTTON_TYPES: [
        {
            text: 'GENERIC',
            value: 0
        },
        {
            text: 'DISCORD',
            value: 1
        },
        {
            text: 'FACEBOOK',
            value: 2
        }
    ]
}