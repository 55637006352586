<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('MIRROR_DECKS')}}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell clickable" @click="changeSortField('name', 0)">{{ $t('NAME') }}</div>
          <div class="cell clickable" @click="changeSortField('status', 1)">{{ $t('MIRROR_DECK_VALUES.STATUS') }}</div>
          <div class="cell clickable" @click="changeSortField('min_win', 1)">{{ $t('MIRROR_DECK_VALUES.MIN_WIN') }}</div>
          <div class="cell clickable" @click="changeSortField('max_win', 1)">{{ $t('MIRROR_DECK_VALUES.MAX_WIN') }}</div>
          <div class="cell">{{ 'Hero' }}</div>
          <div class="cell">{{ 'Unit 1' }}</div>
          <div class="cell">{{ 'Unit 2' }}</div>
          <div class="cell">{{ 'Unit 3' }}</div>
          <div class="cell">{{ 'Unit 4' }}</div>
          <div class="cell">{{ 'Unit 5' }}</div>

          <div class="cell"><a href="/mirror-decks/add" class="success">{{ $t('ADD') }}</a></div>
        </div>
        <div class="table-row" v-for="(mirrorDeck, index) in mirrorDecksArr" v-bind:key="mirrorDeck.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ mirrorDeck.name }}</div>
          <div class="cell">{{ botDeckConstants.STATUS.find(x => x.value === mirrorDeck.status).text }}</div>
          <div class="cell">{{ mirrorDeck.min_win }}</div>
          <div class="cell">{{ mirrorDeck.max_win }}</div>
          <div class="cell">{{ getHeroText(mirrorDeck.hero) }}</div>
          <div class="cell">{{ getUnitText(mirrorDeck.units[0]) }}</div>
          <div class="cell">{{ getUnitText(mirrorDeck.units[1]) }}</div>
          <div class="cell">{{ getUnitText(mirrorDeck.units[2]) }}</div>
          <div class="cell">{{ getUnitText(mirrorDeck.units[3]) }}</div>
          <div class="cell">{{ getUnitText(mirrorDeck.units[4]) }}</div>

          <div class="cell">
            <button @click="() => copyMirrorDeck(mirrorDeck)" class="info"><i class="fas fa-copy"></i></button>
            <a :href="'/mirror-decks/add?id=' + mirrorDeck.id" class="info"><i class="fas fa-edit"></i></a>
            <button @click="() => deleteMirrorDeck(mirrorDeck.id, mirrorDeck.index)" class="error"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";

import botDeckConstants from "@/constants/botDeckConstants";
export default {
  name: "MirrorDecks",
  setup() {
    const store = useStore();
    const units = computed(() => store.getters['units/getUnits']);
    const heroes = computed(() => store.getters['heroes/getHeroes']);

    const mirrorDecksArr = ref([])
    const sortField = ref('name');
    watch(() => store.getters['mirrorDecks/getMirrorDecks'], value => {
      mirrorDecksArr.value = [ ...value ].sort((a,b) => ('' + a[sortField.value]).localeCompare(b[sortField.value]))
    }, { immediate: true })

    const deleteMirrorDeck = (id, index) => {
      // console.log(id, index)
      store.dispatch('mirrorDecks/deleteMirrorDeck', { id, index })
    }

    const numberSort = (a, b) => a[sortField.value] - b[sortField.value]
    const stringSort = (a,b) => ('' + a[sortField.value]).localeCompare(b[sortField.value])

    const changeSortField = (field, type) => {
      sortField.value = field;
      mirrorDecksArr.value = mirrorDecksArr.value.sort(type === 0 ? stringSort : numberSort)
    }

    const copyMirrorDeck = mirrorDeck => {
      store.dispatch('mirrorDecks/addMirrorDeck', { data: { ...mirrorDeck, name: mirrorDeck.name + '_copy', id: undefined} })
    }

    const getHeroText = hero => {
      const selectedHero = heroes.value.find(x => x.id === hero.id);
      return selectedHero.name + '/' + botDeckConstants.RARITY_MAP[selectedHero.rarity] + '/+' + hero.level;
    }

    const getUnitText = unit => {
      const selectedUnit = units.value.find(x => x.id === unit.id);
      if(!selectedUnit) console.log(unit)
      return selectedUnit.name + '/' + botDeckConstants.RARITY_MAP[selectedUnit.rarity] + '/+' + unit.level;
    }

    return {
      mirrorDecksArr,
      deleteMirrorDeck,
      botDeckConstants,
      changeSortField,
      copyMirrorDeck,
      getUnitText,
      getHeroText
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.6fr 0.6fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}

button, a {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  color: black;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.cell.clickable {
  cursor: pointer;
}
</style>