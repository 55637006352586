import assetService from "@/services/asset"

import { notify } from "@kyvg/vue3-notification";
import i18n from "@/i18n";
import store from "@/store";
// initial state
const state = () => ({
    assets: {
        files: [],
        query: ""
    }
})

// getters
const getters = {
    getAssets: ({ assets }) => {
        return assets
    }
}

// actions
const actions = {
    addAsset(state, asset) {
        assetService.addAsset(asset).then(() => {
            store.dispatch('assets/getAssets')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.ADD_ASSET'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.ADD_ASSET'),
                type: 'error'
            })
        })
    },
    deleteAsset(state, asset) {
        assetService.deleteAsset(asset).then(() => {
            store.dispatch('assets/getAssets')
            notify({
                title: i18n.global.t('SUCCESS'),
                text: i18n.global.t('NOTIF.SUCCESS.DELETE_ASSET'),
                type: 'success'
            })
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.DELETE_ASSET'),
                type: 'error'
            })
        })
    },
    getAssets({ commit }) {
        assetService.getAssets().then(assets => {
            commit('setAssets', assets)
        }).catch(() => {
            notify({
                title: i18n.global.t('ERROR'),
                text: i18n.global.t('NOTIF.ERROR.GET_ASSETS'),
                type: 'error'
            })
        })
    }
}

// mutations
const mutations = {
    setAssets(state, assets) {
        state.assets.files = assets.files
        state.assets.query = assets.query
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}