<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('REWARD_VALUES.7_DAY_LOGIN')}}</h1>
    <select v-model="rewardIndex">
      <option v-for="index in constants.DROPDOWN_OPTIONS" v-bind:key="index.value" :value="index.value">{{ index.text }}</option>
    </select>
    <div class="content-container">
      <div class="col">
        <div class="form-container">
          <p>{{ $t(`NAME`) }}</p>
          <input v-model="sevenDaysReward[rewardIndex].name"/>
        </div>
      </div>
    </div>
    <div class="col">
      <h2 style="text-align: start;">{{ $t(`REWARDS`) }}</h2>
      <div class="action-buttons-table" v-if="sevenDaysReward[rewardIndex].rewards.length > 0">
        <div class="row header-row">
          <p>{{ $t(`REWARD_VALUES.REWARD_TYPE`) }}</p>
          <p>{{ "Selection Type" }}</p>
          <p>{{ $t('REWARD_VALUES.RARITY') }}</p>
          <p>{{ $t('REWARD_VALUES.CHEST_ID') }}</p>
          <p>{{ "Unit" }}</p>
          <p>{{ "Hero" }}</p>
          <p>{{ $t('REWARD_VALUES.EMOJI_INDEX') }}</p>
          <p>{{ $t('REWARD_VALUES.AMOUNT') }}</p>
          <p>{{ $t('ACTIONS') }}</p>
        </div>
        <div v-for="(reward, index) in sevenDaysReward[rewardIndex].rewards" v-bind:key="index" class="row">
          <select v-model.number="sevenDaysReward[rewardIndex].rewards[index].type" @change="initializeRewardType(index, sevenDaysReward[rewardIndex].rewards[index].type)">
            <option v-for="option in constants.REWARD_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('REWARD_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="sevenDaysReward[rewardIndex].rewards[index].selection_type" :disabled="![3,4].includes(sevenDaysReward[rewardIndex].rewards[index].type)">
            <option v-for="option in constants.CARD_SELECTION_TYPES" :value="option.value" v-bind:key="option.value">{{ $t('REWARD_VALUES.' + option.text) }}</option>
          </select>
          <select v-model.number="sevenDaysReward[rewardIndex].rewards[index].rarity" :disabled="![3,4].includes(sevenDaysReward[rewardIndex].rewards[index].type)">
            <option v-for="option in constants.CARD_RARITY" :value="option.value" v-bind:key="option.value">{{ $t('REWARD_VALUES.' + option.text) }}</option>
          </select>
          <select v-model="sevenDaysReward[rewardIndex].rewards[index].chest_id" :disabled="sevenDaysReward[rewardIndex].rewards[index].type !== 2">
            <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <select v-model="sevenDaysReward[rewardIndex].rewards[index].id" :disabled="sevenDaysReward[rewardIndex].rewards[index].type !== 3 || sevenDaysReward[rewardIndex].rewards[index].selection_type !== 1">
            <option v-for="option in units" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <select v-model="sevenDaysReward[rewardIndex].rewards[index].id" :disabled="sevenDaysReward[rewardIndex].rewards[index].type !== 4 || sevenDaysReward[rewardIndex].rewards[index].selection_type !== 1">
            <option v-for="option in heroes" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <input type="number" v-model.number="sevenDaysReward[rewardIndex].rewards[index].emoji_index" :disabled="sevenDaysReward[rewardIndex].rewards[index].type !== 6"/>
          <input type="number" v-model.number="sevenDaysReward[rewardIndex].rewards[index].amount" :disabled="sevenDaysReward[rewardIndex].rewards[index].type === 6" />

          <button @click="sevenDaysReward[rewardIndex].rewards.splice(index, 1)" class="error">{{ $t('REMOVE') }}</button>
        </div>
      </div>
      <button @click="addReward" class="success">{{ $t('REWARD_VALUES.ADD_REWARD') }}</button>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
      <button v-if="env === 'dev'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "SevenDaysLoginReward",
  setup() {

    const constants = {
      DROPDOWN_OPTIONS: [
        {
          text: 'Day 1',
          value: 1
        },
        {
          text: 'Day 2',
          value: 2
        },
        {
          text: 'Day 3',
          value: 3
        },
        {
          text: 'Day 4',
          value: 4
        },
        {
          text: 'Day 5',
          value: 5
        },
        {
          text: 'Day 6',
          value: 6
        },
        {
          text: 'Day 7',
          value: 7
        },
      ],
      REWARD_TYPES: [
        {
          text: "GOLD",
          value: 0
        },
        {
          text: "GEM",
          value: 1
        },
        {
          text: "CHEST",
          value: 2
        },
        {
          text: "UNIT",
          value: 3
        },
        {
          text: "HERO",
          value: 4
        },
        {
          text: "EMOJI",
          value: 6
        }
      ],
      CARD_SELECTION_TYPES: [
        {
            text: 'RANDOM',
            value: 0
        },
        {
            text: 'SPECIFIC',
            value: 1
        }
      ],
      CARD_RARITY: [
        {
          text: "COMMON",
          value: 0
        },
        {
          text: "RARE",
          value: 1
        },
        {
          text: "EPIC",
          value: 2
        },
        {
          text: "LEGENDARY",
          value: 3
        }
      ]
    }

    const rewardIndex = ref(1);
    const store = useStore();
    const rewards = computed(() => store.getters['rewards/getRewards'])
    const units = computed(() => store.getters['units/getUnits'])
    const heroes = computed(() => store.getters['heroes/getHeroes'])
    const sevenDaysReward = reactive({
      1: {
        rewards: []
      },
      2: {
        rewards: []
      },
      3: {
        rewards: []
      },
      4: {
        rewards: []
      },
      5: {
        rewards: []
      },
      6: {
        rewards: []
      },
      7: {
        rewards: []
      }
    })

    onMounted(() => {
      if (rewards.value.length > 0) {
        const editingReward = JSON.parse(JSON.stringify(rewards.value.find(x => x.id === 'login')))
        Object.keys(editingReward).forEach(key => {
          sevenDaysReward[key] = editingReward[key]
        })
      }
    })

    watch(() => store.getters['rewards/getRewards'], rewards => {
      if(rewards.length > 0) {
        const editingReward = JSON.parse(JSON.stringify(rewards.find(x => x.id === 'login')))
        Object.keys(editingReward).forEach(key => {
          sevenDaysReward[key] = editingReward[key]
        })
      }
    })



    const submit = () => {
      store.dispatch('rewards/updateReward', {id: 'login', data: sevenDaysReward})
    }

    const exportTo = environment => {
      store.dispatch('rewards/exportReward', {id: 'login', data: sevenDaysReward, environment })
    }

    const initializeRewardType = (index, type) => {
      const reward = {
        type,
        amount: 0
      }
      if([3,4].includes(type))
        reward.rarity = 0
      if(type === 2)
        reward.chest_id = ""
      if(type === 6) {
        reward.emoji_index = 0
        delete reward.amount
      }
      sevenDaysReward[rewardIndex.value].rewards[index] = reward
    }

    const addReward = () => {
      sevenDaysReward[rewardIndex.value].rewards.push({ type: 0, amount: 0})
    }

    const chests = computed(() => store.getters['chests/getChests'])
    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      sevenDaysReward,
      submit,
      exportTo,
      env,
      constants,
      rewardIndex,
      initializeRewardType,
      addReward,
      chests,
      units,
      heroes
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}

.row.condition-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;

}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}
</style>