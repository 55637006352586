<template>
  <div>
      <div class="popup-wrapper" :style="!popupOpen ? { display: 'none'} : {}">
    <div class="popup-container">
      <div class="popup-header">
        <div class="popup-title">
          LOG DETAILS
        </div>
        <a class="popup-button button-close" @click="$emit('popup-close')">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="popup-outer-container">
          <div class="popup-content">
        <div class="form-container">
          <h4>{{ "User ID" }}</h4>
          <p>{{state.user_id}}</p>
        </div>
        <div class="form-container">
          <h4>{{ "Log Type" }}</h4>
          <p>{{state.log_type}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Module ID" }}</h4>
          <p>{{state.module_id}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "App ID" }}</h4>
          <p>{{state.app_id}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Version" }}</h4>
          <p>{{state.version}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Log Time" }}</h4>
          <p>{{moment(state.log_time).format('DD/MM/YYYY HH:mm')}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Event Time" }}</h4>
          <p>{{moment(state.event_time).format('DD/MM/YYYY HH:mm')}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Is Server" }}</h4>
          <p>{{state.is_server}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Is Editor" }}</h4>
          <p>{{state.is_editor}}</p>
        </div>
    
      </div>
      <div class="popup-content">
        
        <div class="form-container-inline form-container">
          <h4>{{ "Exception Message" }}</h4>
          <textarea style="width=80% min-height: 150px" v-model="state.exception_message"/>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Stack Trace" }}</h4>
          <textarea style="width=80% min-height: 150px" v-model="state.exception_stack_trace"/>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Backend Response" }}</h4>
          <textarea style="width=80% min-height: 150px" v-model="state.backend_response"/>
        </div>
      </div>
      </div>
      <div class="popup-footer">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import moment from "moment";

export default {
  name: "LogPopup",
  props: ["content", "popupOpen"],
  emits: ["popup-close"],
  setup(props) {

    const state = reactive({
        app_id: "",
        user_id: "",
        backend_response: "",
        exception_stack_trace: "",
        exception_message: "",
        module_id: "",
        log_level: 0,
        version : "",
        log_time: 0,
        event_time: 0,
        is_editor: false,
        is_server: false,
        log_type: 0
    });

    watch(() => props.content, value => {
        state.app_id = value.app_id;
        state.user_id = value.user_id;
        state.backend_response = value.backend_response;
        state.exception_stack_trace = value.exception_stack_trace;
        state.exception_message = value.exception_message;
        state.module_id = value.module_id;
        state.log_level = value.log_level;
        state.version = value.version;
        state.log_time = value.log_time;
        state.event_time = value.event_time;
        state.is_editor = value.is_editor;
        state.is_server = value.is_server;
        state.log_type = value.log_type;
    });

    return {
      state,
      moment
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container-old {
  width: 70%;
  height: 60%;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-outer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.popup-container {
  width: 70%;
  height: 60%;
  border-radius: 15px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-content {
  width: 100%;
  height: 90%;
  align-self: flex-start;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;

}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.reward-table {
  width: 100%;
}
.row {
  width: 100%;
  display: grid;
}

.chest-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>