<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('SEASON')}}</h1>
    <div class="content-container">
      <div class="col">
        <div class="field-container">
          <h4>{{ $t('SEASON_VALUES.ID') }}</h4>
          <p>{{ currentSeason.id }}</p>
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.ACTIVATION_DATE`) }}</p>
          <DatePicker
              v-model="currentSeason.activation_date"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="activation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.EXPIRE_DATE`) }}</p>
          <DatePicker
              v-model="currentSeason.expire_date"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="expire_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.SEASON_NAME`) }}</p>
          <input v-model="currentSeason.name"/>
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.SEASON_NUMBER`) }}</p>
          <input v-model="currentSeason.season_number" type="number" />
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.GRIND_LEVEL_CAP`) }}</p>
          <input v-model="currentSeason.level_cap" type="number" />
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.MAIN_MENU_BANNER_ASSET`) }}</p>
          <select v-model="currentSeason.main_menu_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container">
          <p>{{ $t(`SEASON_VALUES.INSIDE_BANNER_ASSET`) }}</p>
          <select v-model="currentSeason.inside_banner_asset">
            <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
          </select>
        </div>
        <div class="form-container form-container-checkbox">
          <p>{{ $t(`SEASON_VALUES.LEADERBOARD_ACTIVE`) }}</p>
          <input v-model="currentSeason.is_leaderboard_active" type="checkbox" />
        </div>
      </div>
    </div>
    <div class="buttons-section">
      <button @click="submit" class="info">{{ $t('SEASON_VALUES.UPDATE_SEASON') }}</button>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import 'v-calendar/dist/style.css';

import seasonConstants from "@/constants/seasonConstants";

export default {
  name: "SeasonUpdate",
  setup() {
    const store = useStore();
    const seasons = computed(() => store.getters['seasons/getSeasons'])
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })
    const currentSeason = reactive({})
    const route = useRoute();
    onMounted(() => {
      if (route.query.id && seasons.value.length > 0) {
        const editingSeason = JSON.parse(JSON.stringify(seasons.value.find(x => x.id === route.query.id)))
        seasonConstants.seasonShowFields.forEach(key => {
          currentSeason[key] = editingSeason[key]
        })
      }
    })

    watch(() => store.getters['seasons/getSeasons'], seasons => {
      if (route.query.id && seasons.length > 0) {
        const editingSeason = JSON.parse(JSON.stringify(seasons.find(x => x.id === route.query.id)))
        seasonConstants.seasonShowFields.forEach(key => {
          currentSeason[key] = editingSeason[key]
        })
      }
    })

    const submit = () => {

      if (route.query.id) {
        const updateBody = {}
        seasonConstants.seasonUpdateFields.forEach(key => {
          updateBody[key] = currentSeason[key]
        })
        console.log(updateBody);
        store.dispatch('seasons/updateSeason', {id: route.query.id, updateBody })
      }

    }


    return {
      currentSeason,
      submit,
      moment,
      assetList
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid black;
  align-items: stretch;
}

.form-container.form-container-checkbox {
  align-items: center;
}

.field-container {
  display: flex;
  margin: 15px 0;
  border-bottom: 1px solid black;
}

.field-container h4 {
  width: 60%;
  text-align: start;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expiration_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#activation_date, #expire_date {
  width: 100%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
}

/*#emoji-palette >>> .ap, .ql-emojiblot >>> .ap {*/
/*  background-image: none;*/
/*  text-indent: 0;*/
/*  !* It's also possible to adjust spacing. To make selecting emojis easier *!*/
/*  !* width: 25px; *!*/
/*  !* height: 25px; *!*/
/*}*/
</style>