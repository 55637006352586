<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('TROPHY_LEADERBOARD')}}</h1>
    <div class="content-container">
      <div class="dashboard">
        <div class="dashboard-card">
          <div class="card-title">
            {{ $t('TOTAL_USERS_IN_LEADERBOARD') }}
          </div>
          <div class="card-value">
            {{ trophyLeaderboard.currentLength }}
          </div>
        </div>
        <div class="dashboard-card">
          <div class="card-title">
            {{ $t('MIN_TROPHY_IN_LEADERBOARD') }}
          </div>
          <div class="card-value">
            {{ trophyLeaderboard.minTrophy }}
          </div>
        </div>
      </div>
      <div class="form-container form-container-inline">
        <span>{{ $t(`BANNER_ASSET`) }}</span>
        <select v-model="banner_asset">
          <option v-for="option in assetList" :value="option.doc_ref" v-bind:key="option.doc_ref">{{ option.doc_ref || "DEFAULT BANNER" }}</option>
        </select>
        <button class="success" @click="updateBannerAsset">{{ $t("UPDATE_BANNER_ASSET") }}</button>
      </div>
      <div class="table">
        <div class="table-row header-row">
          <div class="table-cell">
            {{ $t("RANK") }}
          </div>
          <div class="table-cell">
            {{ $t("USERNAME") }}
          </div>
          <div class="table-cell">
            {{ $t("TROPHY") }}
          </div>
        </div>
        <div class="table-row" v-for="(user, index) in trophyLeaderboard.leaderboard" v-bind:key="index" :class="index % 2 === 1 ? 'alternate' : ''">
          <div class="table-cell">
            {{ index + 1 }}
          </div>
          <div class="table-cell">
            {{ user.username }}
          </div>
          <div class="table-cell">
            {{ user.trophy }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "TrophyLeaderboard",
  setup() {
    const store = useStore();

    const trophyLeaderboard = computed(() => store.getters["leaderboard/getTrophyLeaderboard"]);
    const units = computed(() => store.getters["units/getUnits"])
    const heroes = computed(() => store.getters["heroes/getHeroes"])

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined
        },
        ...store.getters['assets/getAssets'].files
      ]
    })

    const banner_asset = ref(undefined);

    const getCardName = (isUnit, id) => {
      const cardPool = isUnit ? units : heroes;
      const card = cardPool.find(x => x.id === id);
      if(!card)
        return "Not Found"
      return card.name;
    }

    const updateBannerAsset = () => {
      store.dispatch('leaderboard/updateBannerAsset', banner_asset.value);
    }


    return {
      trophyLeaderboard,
      getCardName,
      heroes,
      units,
      assetList,
      banner_asset,
      updateBannerAsset
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  margin-bottom: 0;
}

.col {
  padding: 25px 10px;
  border-radius: 5px;
  border: 1px solid black;

}

.col-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 25px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-column-gap: 10px;
  align-items: center;
}

.content-row.header-row {
  font-weight: bold;
}

.content-row.alternate {
  background-color: lightgray;
}

.row-title {
  width: 80%;
  text-align: start;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.buttons-section button {
  font-size: 1.25rem;
  margin: 5px 0;
}

.col-full-width {
  width: 100%;
  box-sizing: border-box;
}
.table {
  margin-top: 5px;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid black;
  height: calc(100vh - 250px);
}

.table-row {
  width: 100%;
  display: grid;
  align-items: center;
  height: 40px;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}

.table-row.alternate, .table-row.header-row {
  background-color: lightgrey;
}

.table-row.header-row {
  font-size: 1.1rem;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;
  border: 1px solid black;
}

.dashboard {
  padding: 10px;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.dashboard-card {
  border-radius: 0 20px;
  border: 3px solid black;
  padding: 10px 20px;
  margin: 5px 0;
  background-color: rgba(0, 0, 255, 0.03);
}

.dashboard-card .card-title {
  font-size: 0.9rem;
  font-weight: 500;
}

.dashboard-card .card-value {
  font-size: 1.2rem;
  font-weight: 900;
}

.user-form {
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
  margin: 5px 0;
  box-sizing: border-box;
}

.form-header {
  font-size: 1.5rem;
  font-weight: 900;
  border-bottom: 2px solid black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.form-container-inline {
  display: flex;
  align-items: center;
}

.form-container-inline span {
  font-weight: bold;
}

.form-container-inline * {
  margin: 10px 20px;
}

.form-content {

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.user-form-segment {
  padding: 10px 0;
  /*border-right: 1px solid black;*/
  /*border-left: 1px solid black;*/
  border: 1px solid black;

}

.form-segment-header {
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-item {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /*border-top: 1px solid black;*/
  vertical-align: center;
}

.form-item-header {
  font-weight: bold;
}
</style>