const { inputType, dateCondition } = require("../../util/validator");

const mailValidationSchema = [
    {
        type: inputType.STRING,
        emptyAllowed: false,
        name: 'title',
        displayName: 'Title'
    },
    {
        type: inputType.NUMBER,
        emptyAllowed: false,
        zeroAllowed: true,
        name: 'type',
        displayName: 'Mail Type'
    },
    {
        type: inputType.DATE,
        emptyAllowed: false,
        condition: dateCondition.ANY,
        name: 'creation_date',
        displayName: 'Creation Date'
    },
    {
        type: inputType.DATE,
        emptyAllowed: false,
        condition: dateCondition.GREATER_THAN_FIELD,
        name: 'expiration_date',
        referenceField: 'creation_date',
        displayName: 'Expiration Date'
    },
    {
        type: inputType.STRING,
        emptyAllowed: false,
        name: 'content',
        displayName: 'Mail Content'
    },
    {
        type: inputType.ARRAY,
        name: 'rewards',
        emptyAllowed: true,
        displayName: 'Rewards'
    }
]

const promotionValidationSchema = [
    {
        name: 'title',
        type: inputType.STRING,
        displayName: 'Promotion Title',
        emptyAllowed: false
    },
    {
        name: 'product_id',
        type: inputType.STRING,
        displayName: 'Product Id',
        emptyAllowed: false
    },
    {
        name: 'discount_rate',
        type: inputType.NUMBER,
        displayName: 'discount_rate',
        zeroAllowed: true,
        emptyAllowed: false
    },
    {
        name: 'value_multiplier',
        type: inputType.NUMBER,
        displayName: 'Value Multiplier',
        zeroAllowed: false,
        emptyAllowed: false
    },
    {
        name: 'price',
        type: inputType.NUMBER,
        displayName: 'Price',
        zeroAllowed: false,
        emptyAllowed: false
    },
    {
        name: 'expires_in',
        type: inputType.NUMBER,
        displayName: 'Expires In',
        zeroAllowed: false,
        emptyAllowed: false
    },
    {
        name: 'active_until',
        type: inputType.DATE,
        condition: dateCondition.GREATER_THAN_NOW,
        displayName: 'Active Until',
        emptyAllowed: false
    },
]

const newsValidationSchema = [
    {
        name: 'title',
        type: inputType.STRING,
        displayName: 'News Title',
        emptyAllowed: false
    },
    {
        type: inputType.DATE,
        emptyAllowed: false,
        condition: dateCondition.ANY,
        name: 'creation_date',
        displayName: 'Creation Date'
    },
    {
        type: inputType.DATE,
        emptyAllowed: false,
        condition: dateCondition.GREATER_THAN_FIELD,
        name: 'expiration_date',
        referenceField: 'creation_date',
        displayName: 'Expiration Date'
    },
]

const promoCodeValidationSchema = [
    {
        name: 'id',
        type: inputType.STRING,
        displayName: 'Id',
        emptyAllowed: false
    },
    {
        type: inputType.ARRAY,
        name: 'rewards',
        emptyAllowed: false,
        displayName: 'Rewards'
    },
    {
        name: 'user_limit',
        displayName: 'User Limit',
        type: inputType.NUMBER,
        zeroAllowed: false,
        emptyAllowed: false
    },
    {
        type: inputType.DATE,
        emptyAllowed: false,
        condition: dateCondition.GREATER_THAN_NOW,
        name: 'expire_date',
        displayName: 'Expire Date'
    },
]

module.exports = {
    mailValidationSchema,
    promotionValidationSchema,
    newsValidationSchema,
    promoCodeValidationSchema
}