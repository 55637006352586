<template>
  <div class="container">
    <div class="search-bar">
      <div class="form-container">
        <input type="radio" id="one" value="name" v-model="pickedKey">
        <label for="one">Username</label>
        <input type="radio" id="two" value="id" v-model="pickedKey">
        <label for="two">User Id</label>
      </div>
      <input v-model="pickedValue" type="text" />
      <button @click="searchUser">{{ $t('SEARCH') }}</button>
      <button v-if="user" class="info" @click="editUserFields">{{ $t('USER_VALUES.UPDATE_USER') }}</button>
    </div>
    <div class="user-editable-content" v-if="user">
      <div class="form-container">
        <span>{{ $t('TROPHY') }}</span>
        <input v-model.number="editableTrophy" type="number" />
      </div>
      <div class="form-container">
        <span>{{ 'Gold' }}</span>
        <input v-model.number="editableGold" type="number" />
      </div>
      <div class="form-container">
        <span>{{ 'Gem' }}</span>
        <input v-model.number="editableGem" type="number" />
      </div>
      <div class="form-container">
        <span>{{ $t('SEASON_PASS_TOKEN') }}</span>
        <input v-model.number="editableSPToken" type="number" />
      </div>
      <div class="form-container">
        <span>{{ $t('BAN') }}</span>
        <input type="checkbox" v-model="editableBan" />
      </div>
    </div>
    <div class="user-editable-content" v-if="user">
      <button class="info" @click="resetUserTrophyRoad">{{ $t('USER_VALUES.RESET_TROPHY_ROAD') }}</button>
      <button class="info" @click="resetUserSeasonPass">{{ $t('USER_VALUES.RESET_SEASON_PASS') }}</button>
      <button class="info" @click="resetTutorialProgress">{{ $t('USER_VALUES.RESET_TUTORIAL_PROGRESS') }}</button>
    </div>
    <div class="user-editable-content" v-if="user">
      <button class="info" @click="enableAllSevenDayRewards">{{ "Enable All Seven Day Login Rewards" }}</button>
      <button class="info" @click="enableAllDailyRewards">{{ "Enable All Daily Login Rewards" }}</button>
      <button class="info" @click="refreshDailyShop">{{ "Reset Daily Shop" }}</button>
      <button class="info" @click="obtainAllEmotes">{{ "Give All Emotes" }}</button>
    </div>
    <div class="user-editable-content" v-if="user">
      <button class="info" @click="restartSeasonWithDifferentQuests">{{ $t('USER_VALUES.RESTART_SEASON_WITH_DIFFERENT_QUESTS') }}</button>
      <button class="info" @click="refreshDailyQuests">{{ "Refresh Daily Quests" }}</button>
      <button class="info" @click="setAllDailyQuestsCompleted">{{ "Complete Daily Quests" }}</button>
      <button class="info" @click="setAllSeasonalQuestsCompleted(false)">{{ "Complete Seasonal Free Quests" }}</button>
      <button class="info" @click="setAllSeasonalQuestsCompleted(true)">{{ "Complete Seasonal Premium Quests" }}</button>
    </div>
    <div class="user-content" v-if="user">
      <div class="row">
        <div style="text-align: start;">{{ $t('USER_VALUES.DETAILED_MATCH_RESULTS') }}</div>
        <div style="text-align: start;"><a target="_blank" :href="'https://codeway.cloud.looker.com/explore/codeway/randombrawl_logs?fields=randombrawl_logs._event_time,randombrawl_logs.match_id,randombrawl_logs.match_result,randombrawl_logs.match_length,randombrawl_logs.bot_game,randombrawl_logs.is_coop&f[randombrawl_logs.app]=events.randombrawl&f[randombrawl_logs.event_name]=match_result&f[randombrawl_account_facts.account_id]=' + user.id + '&sorts=randombrawl_logs._event_time+desc&limit=500'">Looker Link</a></div>
      </div>
      <div class="row" v-for="(property, index) in userConstants.DISPLAY_KEYS" v-bind:key="index">
        <div style="text-align: start;">{{ $t('USER_VALUES.' + property.key) }}</div>
        <div style="text-align: start;">{{ prepareField(property.type, user[property.field]) }}</div>
      </div>
      <div class="row">
        <div style="text-align: start;">{{ 'Win Count' }}</div>
        <input v-model.number="editableWin" type="number" min="0" />
      </div>
      <div class="row">
        <div style="text-align: start;">{{ 'Lose Count' }}</div>
        <input v-model.number="editableLose" type="number" min="0" />
      </div>
      <div class="row">
        <div style="text-align: start;">{{ 'Creation Date' }}</div>
        <DatePicker
              v-model="editableCreationDate"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
      </div>
      <div class="row">
        <div style="text-align: start;">{{ 'Next 7 day login reward date' }}</div>
        <DatePicker
              v-model="nextSevenDayRewardDate"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
      </div>
      <div class="row">
        <div style="text-align: start;">{{ 'Next daily login reward date' }}</div>
        <DatePicker
              v-model="nextDailyRewardDate"
              mode="dateTime"
              :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </DatePicker>
      </div>
    </div>
    <div class="user-units-table" v-if="user">
      <div class="table-header" @click="toggleUnitPanel()">
        <h3>Units</h3>
      </div>
      <div v-if="unitPanelOpen">
        <div class="user-units-row" v-for="unit in data.units" v-bind:key="unit.id">
          <p>{{ unitsArr.find(x => x.id === unit.id)?.name }}</p>
          <input v-model.number="unit.amount" type="number" min="0" />
        </div>
      </div>
    </div>
    <div class="user-units-table" v-if="user">
      <div class="table-header" @click="toggleHeroPanel()">
        <h3>Heroes</h3>
      </div>
      <div v-if="heroPanelOpen">
        <div class="user-units-row" v-for="hero in data.heroes" v-bind:key="hero.id">
          <p>{{ heroesArr.find(x => x.id === hero.id)?.name }}</p>
          <input v-model.number="hero.amount" type="number" min="0" />
        </div>
      </div>
    </div>
    <div class="user-units-table" v-if="user">
      <div class="table-header" @click="toggleChestPanel()">
        <h3>Chests</h3>
      </div>
      <div v-if="chestsPanelOpen" class="user-chest-container">
        <div class="user-chest" v-for="chest in user.chests" v-bind:key="chest.id">
          <select v-model="chest.id">
            <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
          </select>
          <div class="row-noborder">
            <div style="text-align: start;">{{ 'Arena' }}</div>
            <input v-model.number="chest.arena" type="number" min="0" />
          </div>
          <div class="row-noborder">
            <div style="text-align: start;">{{ 'Opening Time' }}</div>
            <DatePicker
                  v-model="chest.opening_time"
                  mode="dateTime"
                  :model-config="{
                  type: 'number',
                  mask: 'DD/MM/YYYY HH:mm'
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      id="creation_date"
                      :value="inputValue"
                      v-on="inputEvents"
                  />
                </template>
              </DatePicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, reactive } from "vue"
import moment from "moment"
import 'v-calendar/dist/style.css';
import store from "@/store"
import userConstants from "@/constants/userConstants";

export default {
  name: "index",
  setup() {
    const pickedKey = ref('name')
    const pickedValue = ref('')
    const user = computed(() => store.getters['user/getUser'])
    const chests = computed(() => store.getters['chests/getChests'])
    const searchUser = () => {
      store.dispatch('user/getUser', { key: pickedKey.value, value: pickedValue.value })
    }

    const unitsArr = ref([])
    watch(() => store.getters['units/getUnits'], value => {
      unitsArr.value = [ ...value ].sort((a,b) => a.index - b.index)
    }, { immediate: true })

    const heroesArr = ref([])
    watch(() => store.getters['heroes/getHeroes'], value => {
      heroesArr.value = [ ...value ].sort((a,b) => a.index - b.index)
    }, { immediate: true })

    const nextDailyRewardDate = ref(0);
    const nextSevenDayRewardDate = ref(0)
    const editableCreationDate = ref(0)
    const editableWin = ref(0)
    const editableLose = ref(0)
    const editableGold = ref(0)
    const editableGem = ref(0)
    const editableTrophy = ref(0)
    const editableSPToken = ref(0)
    const editableBan = ref(undefined)

    const unitPanelOpen = ref(false)
    const heroPanelOpen = ref(false)
    const chestsPanelOpen = ref(false)

    const data = reactive({
      units: [],
      heroes: []
    })

    watch(() => store.getters['user/getUser'], val => {
      nextDailyRewardDate.value = val.next_daily_login_reward_time
      nextSevenDayRewardDate.value = val.next_login_reward_time
      editableCreationDate.value = val.create_date
      editableWin.value = val.profile.victories
      editableLose.value = val.profile.defeats
      editableGold.value = val.gold
      editableGem.value = val.gem
      editableTrophy.value = val.trophy
      editableSPToken.value =val.season_pass_token
      editableBan.value = val.banned
      data.units = Object.keys(val.units).map(id => {
        return {
          id: id,
          amount: val.units[id].count
        }
      })
      data.heroes = Object.keys(val.heroes).map(id => {
        return {
          id: id,
          amount: val.heroes[id].count
        }
      })
    })

    const prepareField = (type, value) => {

      if(!value) {
        return 'None'
      }

      if(type === 'text') {
        return value
      }
      if(type === 'date') {
        return moment(value).format('MMMM Do YYYY, h:mm a');
      }
      if(type === 'season') {
        return Math.floor(value / 100)
      }
      if(type === 'claimed') {
        const unlockedCount = Object.keys(value).reduce((acc, val) => {
          return acc + (value[val].count > 0 ? 1 : 0)
        }, 0)

        const totalCount = Object.keys(value).length
        return unlockedCount + "/" + totalCount
      }
      if(type === 'boolean') {
        return value ? 'True' : 'False'
      }
      if(type === 'publisher_name') {
        return value.split('::')[0]
      }
      if(type === 'campaign_name') {
        return value.split('::')[1] || 'None'
      }
      if(type === 'sub_ad_group') {
        return value.split('::')[2] || 'None'
      }
      if(type === 'creative') {
        return value.split('::')[3] || 'None'
      }

      return value
    }
    
    const toggleUnitPanel = () => {
      unitPanelOpen.value = !unitPanelOpen.value
    }

    const toggleHeroPanel = () => {
      heroPanelOpen.value = !heroPanelOpen.value
    }

    const toggleChestPanel = () => {
      chestsPanelOpen.value = !chestsPanelOpen.value
    }

    const resetTutorialProgress = () => {

      const updateBody = {
        tutorial_progress: {},
        gold: 0,
        units: {
          ...user.value.units,
          "9AuYNvoAxqsJqZ0Uhope": {
            rarity: 0,
            count: 1,
            level: 1
          }
        }
      }


      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const resetUserTrophyRoad = () => {
      const updateBody = {}
      const trophyLength = user.value.trophy_road.length;

      updateBody.trophy_road = new Array(trophyLength).fill(false);

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const resetUserSeasonPass = () => {
      const updateBody = {}

      const spFreeRewardsLength = user.value.season_pass_road.sp_rewards.length;
      const spPremiumRewardsLength = user.value.season_pass_road.spp_rewards.length;

      updateBody.season_pass_road = {
        grind_reward_claimed: 0,
        sp_rewards: new Array(spFreeRewardsLength).fill(false),
        spp_rewards: new Array(spPremiumRewardsLength).fill(false)
      }

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const restartSeasonWithDifferentQuests = () => {
      const updateBody = {}


      updateBody.season_pass_id = null;
      updateBody.season_pass_expire_date = null;
      updateBody.season_pass_start_date = null;
      updateBody.seasonal_quests_update_time = 0;

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const enableAllSevenDayRewards = () => {
      const updateBody = {
        login_rewards: userConstants.FULL_SEVEN_DAY_REWARDS
      }
      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const enableAllDailyRewards = () => {
      const updateBody = {
        daily_login_rewards: JSON.parse(JSON.stringify(user.value.daily_login_rewards))
      }

      updateBody.daily_login_rewards.forEach(reward => {
        reward.is_claimable = true;
        reward.claimed = false;
      });

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const refreshDailyQuests = () => {
      const updateBody = {
        daily_quest_expire_date: Date.now()
      }

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const refreshDailyShop = () => {
      const updateBody = {
        daily_shop_expire_date: Date.now()
      }

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const setAllDailyQuestsCompleted = () => {
      const updateBody = {
        daily_quests: JSON.parse(JSON.stringify(user.value.daily_quests))
      }

      updateBody.daily_quests.forEach(quest => {
        quest.amount = quest.required_amount
      });

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const setAllSeasonalQuestsCompleted = (isPremium) => {
      const updateBody = {
        seasonal_quests: JSON.parse(JSON.stringify(user.value.seasonal_quests))
      }

      const key = isPremium ? 'premium' : 'free'

      updateBody.seasonal_quests[key].forEach(quest => {
        quest.amount = quest.required_amount
      })

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const obtainAllEmotes = () => {
      const updateBody = {
        emojis: [...Array(21).keys()]
      }

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody });
    }

    const editUserFields = () => {
      const updateBody = {}
      if(editableTrophy.value !== user.value.trophy) {
        updateBody.trophy = editableTrophy.value
        if(editableTrophy.value > user.value.highest_trophy) {
          updateBody.highest_trophy = editableTrophy.value
          updateBody.is_trophy_manipulated = true
        }
      }
      if(editableBan.value !== user.value.banned) {
        updateBody.banned = editableBan.value
      }
      if(editableSPToken.value !== user.value.season_pass_token) {
        updateBody.season_pass_token = editableSPToken.value
      }
      if(editableGem.value !== user.value.gem) {
        updateBody.gem = editableGem.value
      }
      if(editableGold.value !== user.value.gold) {
        updateBody.gold = editableGold.value
      }
      if(editableCreationDate.value !== user.value.create_date) {
        updateBody.create_date = editableCreationDate.value
      }
      updateBody.next_login_reward_time = nextSevenDayRewardDate.value;
      updateBody.next_daily_login_reward_time = nextDailyRewardDate.value;
      updateBody.chests = user.value.chests;
      updateBody.profile={
        ...user.value.profile,
        victories: editableWin.value,
        defeats: editableLose.value,
        winrate: Math.floor(editableWin.value/(editableWin.value+editableLose.value)*100) || 0
      }
      
      updateBody.units = JSON.parse(JSON.stringify(user.value.units));
      updateBody.heroes = JSON.parse(JSON.stringify(user.value.heroes));

      data.units.forEach(unit => {
        updateBody.units[unit.id].count = unit.amount;
      });
      data.heroes.forEach(hero => {
        updateBody.heroes[hero.id].count = hero.amount;
      });

      store.dispatch('user/updateUser', { id: user.value.id, body: updateBody })
    }

    return {
      pickedKey,
      pickedValue,
      searchUser,
      user,
      userConstants,
      prepareField,
      editableSPToken,
      editableBan,
      editableTrophy,
      editUserFields,
      resetUserTrophyRoad,
      resetUserSeasonPass,
      resetTutorialProgress,
      restartSeasonWithDifferentQuests,
      editableGold,
      editableGem,
      data,
      unitsArr,
      heroesArr,
      unitPanelOpen,
      heroPanelOpen,
      toggleUnitPanel,
      toggleHeroPanel,
      editableWin,
      editableLose,
      editableCreationDate,
      chestsPanelOpen,
      toggleChestPanel,
      chests,
      nextSevenDayRewardDate,
      enableAllSevenDayRewards,
      enableAllDailyRewards,
      refreshDailyQuests,
      setAllDailyQuestsCompleted,
      setAllSeasonalQuestsCompleted,
      refreshDailyShop,
      obtainAllEmotes,
      nextDailyRewardDate
    }
  }
}
</script>

<style scoped>
.search-bar, .user-editable-content {
  display: flex;
  margin: 15px 30px;
  padding: 15px 30px;
  border-radius: 15px;
  border: 1px gray solid;
  justify-content: space-between;
}

.table-header {
  border: 1px gray solid;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}

.table-header:hover {
  background: gray;
}

.user-units-table {
  margin: 15px 30px;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
}

.user-units-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-direction: row;
  justify-content: left;
  padding: 15px;
}

.user-chest-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-direction: row;
}

.user-chest {
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  min-height: 50px;
  border-radius: 15px;
  border: 1px solid gray;
}

input {
  margin-left: 5px;
}
input[type="text"] {
  width: 33%;
}
label {
  margin-right: 10px;
}

.user-content {
  padding: 10px 30px;
}
.container {
  width: 85vw;
}
.row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid black;
  padding: 10px 20px;
}

.row-noborder {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 20px;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
</style>