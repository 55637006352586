import axios from 'axios';

import getFirebaseIdToken from './helper/httpHelper'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || ''
axios.defaults.withCredentials = true

const getAxios = async () => {
    const token = await getFirebaseIdToken()
    axios.defaults.headers.common = {
        'authorization': token || ''
    }
    return axios
}
export default getAxios;